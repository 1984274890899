import React, { Component } from 'react';
import { HOST, HOST_MONITOR, HOST_WS } from '../../config';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import { css } from '@emotion/core';
import Switch from "react-switch";
import ReactApexCharts from 'react-apexcharts';
import Select from "react-select";
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import Time from 'react-time-format';
import ReactDatePicker from 'react-datepicker';
import { PulseLoader } from 'react-spinners';
var mqtt = require('mqtt')
var client = HOST_WS.includes("ws") && mqtt.connect(HOST_WS)
Highcharts.setOptions({
    colors: ['#008ffb', '#00e396', '#feb019', '#ff4560']
});
var TimeoutSSH;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class DetailDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            optionsCPU: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            shadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: 14,
                                fontSize: '18px'
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#87D4F9"],
                        stops: [0, 100]
                    },
                },
                labels: ['0'],
            },
            optionsMemory: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            shadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: true
                            },
                            value: {
                                offsetY: 14,
                                fontSize: '18px'
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#87D4F9"],
                        stops: [0, 100]
                    },
                },
                labels: ['0'],
            },
            optionsStorage: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            shadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: true
                            },
                            value: {
                                offsetY: 14,
                                fontSize: '18px'
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#87D4F9"],
                        stops: [0, 100]
                    },
                },
                labels: ['0'],
            },
            optionsTemperature: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            shadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: 14,
                                fontSize: '18px',
                                formatter: function (val) {
                                    return val + "°C";
                                }
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#87D4F9"],
                        stops: [0, 100]
                    },
                },
                labels: ['0'],
            },
            seriesCPU: [0],
            handleChangeStartDate: moment().format('YYYY-MM-DD 00:00:00'),
            handleChangeEndDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            seriesMemory: [0],
            seriesStorage: [0],
            seriesTemperature: [0],
            listBoxListById: null,
            paramssh: null,
            engineSST: [],
            statusSSH: null,
            isChecked: false,
            activeSSH: false,
            mqttPort: '',
            isLogin: Store.getState().isLogin.isLogin,
            //CPU
            cpuOption: [0],
            chartOptionsCPU: {
                chart: {
                    height: 200,
                    zoomType: 'x',
                },
                title: {
                    text: 'CPU',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    xDateFormat: '%H:%M:%S',
                    shared: true
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'CPU',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            //Memory
            memoryOption: [0],
            chartOptionsMemory: {
                chart: {
                    height: 200,
                    zoomType: 'x',
                },
                title: {
                    text: 'Memory',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    xDateFormat: '%H:%M:%S',
                    shared: true
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'Memory',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            //storage
            storage_Option: [0],
            chartOptionsStorage: {
                chart: {
                    height: 200,
                    zoomType: 'x',
                },
                title: {
                    text: 'Storage',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    xDateFormat: '%H:%M:%S',
                    shared: true
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'Storage',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            //temperature
            chartOptionsTemperature: {
                chart: {
                    height: 200,
                    zoomType: 'x',
                },
                title: {
                    text: 'Temperature',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    xDateFormat: '%H:%M:%S',
                    shared: true
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'temperature',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            //showtime
            selectedOption: { value: 60, label: '1 Hour' },
            timeReal: [
                { value: 60, label: '1 Hour' },
                { value: 300, label: '5 Hour' },
                { value: 600, label: '10 Hour' },
                { value: 720, label: '12 Hour' },
                { value: 1440, label: '24 Hour' },
                { value: -1, label: 'Select Time' }
            ]
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.Data();
    }

    Data = () => {
        this.getBox();
        this.getSSH();
        this.getCPU(this.state.selectedOption);
    }

    getCPU = (time) => {
        if (time) {
            var todayzz = new Date(moment().subtract(7, "h").subtract(time.value, "m").format("YYYY-MM-DD hh:mm:ss"))
            var today = new Date(todayzz);
            var dd = today.getDate();
            var MM = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            var hh = today.getHours();
            var mm = today.getMinutes();
            var ss = today.getSeconds();
            if (ss < 10) {
                ss = '0' + ss
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            if (hh < 10) {
                hh = '0' + hh
            }
            if (dd < 10) {
                dd = '0' + dd
            }
            if (MM < 10) {
                MM = '0' + MM
            }
        }
        fetch(
            time
                ? `${HOST_MONITOR}/v1/device?box_id=${this.props.match.params.id}&start_date=${yyyy}-${MM}-${dd}T${hh}:${mm}`
                : `${HOST_MONITOR}/v1/device?box_id=${this.props.match.params.id
                }&start_date=${this.state.handleChangeStartDate}&end_date=${this.state.handleChangeEndDate}`
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error_code === 10000) {
                    var cpuOption = [];
                    var memoryOption = [];
                    var storage_Option = [];
                    var temperature_Option = [];
                    data.data.map((e, i) => {
                        var dataDays = e.time;
                        var today = new Date(dataDays);
                        var dd = today.getDate();
                        var MM = today.getMonth() + 1;
                        var yyyy = today.getFullYear();
                        var hh = today.getHours();
                        var mm = today.getMinutes();
                        var ss = today.getSeconds();

                        if (ss < 10) {
                            ss = "0" + ss;
                        }

                        if (mm < 10) {
                            mm = "0" + mm;
                        }

                        if (hh < 10) {
                            hh = "0" + hh;
                        }

                        if (dd < 10) {
                            dd = "0" + dd;
                        }

                        if (MM < 10) {
                            MM = "0" + MM;
                        }

                        var data_cpu = [
                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                            parseInt(e.cpu_usage),
                        ];
                        var data_memory = [
                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                            parseInt(e.memory_usage),
                        ];
                        var data_storage = [
                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                            parseInt(e.storage_usage),
                        ];
                        var data_temperature = [
                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                            parseInt(e.temperature),
                        ];

                        cpuOption.push(data_cpu);
                        memoryOption.push(data_memory);
                        storage_Option.push(data_storage);
                        temperature_Option.push(data_temperature);
                    });
                    var GBMemory;
                    var GBStorage;
                    var seriesCPU = [0];
                    var seriesMemory = [0];
                    var seriesStorage = [0];
                    var seriesTemperature = [0];
                    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
                    if (data.data[0] != undefined) {
                        if (data.data[0].memory == 0) {
                            GBMemory = 0;
                        } else {
                            var i = parseInt(
                                Math.floor(Math.log(data.data[0].memory) / Math.log(1024))
                            );
                            GBMemory =
                                Math.round(data.data[0].memory / Math.pow(1024, i), 2) +
                                " " +
                                sizes[i];
                        }
                        if (data.data[0].storage == 0) {
                            GBStorage = 0;
                        } else {
                            var i = parseInt(
                                Math.floor(Math.log(data.data[0].storage) / Math.log(1024))
                            );
                            GBStorage =
                                Math.round(data.data[0].storage / Math.pow(1024, i), 2) +
                                " " +
                                sizes[i];
                        }
                        seriesCPU = [data.data[0].cpu_usage];
                        seriesMemory = [data.data[0].memory_usage];
                        seriesStorage = [data.data[0].storage_usage];
                        seriesTemperature = [data.data[0].temperature];
                    }
                    this.setState(
                        {
                            loading: false,
                            //CPU
                            seriesCPU: seriesCPU,
                            cpuOption: cpuOption,
                            chartOptionsCPU: {
                                series: [
                                    {
                                        type: "area",
                                        name: "CPU",
                                        data: cpuOption,
                                    },
                                ],
                            },
                            //Memory
                            optionsMemory: {
                                labels: [`${GBMemory}`],
                            },
                            seriesMemory: seriesMemory,
                            memoryOption: memoryOption,
                            chartOptionsMemory: {
                                series: [
                                    {
                                        type: "area",
                                        name: "Memory",
                                        data: memoryOption,
                                    },
                                ],
                            },
                            //Boot_storage
                            optionsStorage: {
                                labels: [`${GBStorage}`],
                            },
                            seriesStorage: seriesStorage,
                            storage_Option: storage_Option,
                            chartOptionsStorage: {
                                series: [
                                    {
                                        type: "area",
                                        name: "Storage",
                                        data: storage_Option,
                                    },
                                ],
                            },
                            //Tem
                            seriesTemperature: seriesTemperature,
                            temperature_Option: temperature_Option,
                            chartOptionsTemperature: {
                                series: [
                                    {
                                        type: "area",
                                        name: "Temperature",
                                        data: temperature_Option,
                                    },
                                ],
                            },
                        },
                        () => {
                            var idbox = this.props.match.params.id;
                            if (HOST_WS.includes("ws")) {
                                client = mqtt.connect(HOST_WS);
                                client.on("connect", function () {
                                    console.log("mqtt broker connected!");
                                    client.subscribe(
                                        `v1/devices/${idbox}/response/+`,
                                        function (err) {
                                            if (!err) {
                                                console.log("Request command subscribed");
                                            }
                                        }
                                    );
                                    client.subscribe(
                                        `v1/devices/${idbox}/telemetry`,
                                        function (err) {
                                            if (!err) {
                                                console.log("Device telemetry subscribed");
                                            }
                                        }
                                    );
                                });
                                let that = this;
                                client.on("message", function (topic, message) {
                                    // message is Buffer
                                    var paramssh = JSON.parse(message);
                                    if (topic == `v1/devices/${idbox}/response/+`) {
                                        console.log(paramssh);
                                        if (paramssh.method == "open_ssh") {
                                            if (paramssh.status == 1) {
                                                fetch(`${HOST}/api/boxSshPort/update`, {
                                                    method: "POST",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type": "application/json",
                                                        Authorization:
                                                            Store.getState().isLogin.access_token,
                                                    },
                                                    body: JSON.stringify({
                                                        box_id: idbox,
                                                        status: 1,
                                                    }),
                                                })
                                                    .then((response) => {
                                                        return response.json();
                                                    })
                                                    .then((data) => {
                                                        if (data.status == 10000) {
                                                            swal(
                                                                "Open SSH Success!",
                                                                "SSH command: ssh -J ec2-user@tunnel.beetai.com root@localhost -p " +
                                                                that.state.mqttPort,
                                                                "success",
                                                                {
                                                                    button: {
                                                                        text: "Close!",
                                                                    },
                                                                }
                                                            );
                                                            clearTimeout(TimeoutSSH);
                                                            that.setState({
                                                                activeSSH: true,
                                                            });
                                                        }
                                                    });
                                            } else if (paramssh.status == 0) {
                                                swal("Open SSH Failed!", "Failed", "error", {
                                                    button: {
                                                        text: "Close!",
                                                    },
                                                });
                                                clearTimeout(TimeoutSSH);
                                            }
                                        } else if (paramssh.method == "close_ssh") {
                                            if (paramssh.status == 1) {
                                                fetch(`${HOST}/api/boxSshPort/delete`, {
                                                    method: "POST",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type": "application/json",
                                                        Authorization:
                                                            Store.getState().isLogin.access_token,
                                                    },
                                                    body: JSON.stringify({
                                                        box_id: idbox,
                                                    }),
                                                })
                                                    .then((response) => {
                                                        return response.json();
                                                    })
                                                    .then((data) => {
                                                        if (data.status == 10000) {
                                                            swal(
                                                                "Success!",
                                                                "Close SSH successfully!",
                                                                "success",
                                                                {
                                                                    buttons: false,
                                                                    timer: 1500,
                                                                }
                                                            );
                                                            clearTimeout(TimeoutSSH);
                                                            that.setState({
                                                                mqttPort: "",
                                                                activeSSH: false,
                                                            });
                                                        }
                                                    });
                                            } else if (paramssh.status == 0) {
                                                swal("Close SSH Failed!", "Failed", "error", {
                                                    button: {
                                                        text: "Close!",
                                                    },
                                                });
                                                clearTimeout(TimeoutSSH);
                                            }
                                        }
                                    } else if (topic == `v1/devices/${idbox}/telemetry`) {
                                        var today = new Date();
                                        var dd = today.getDate();
                                        var MM = today.getMonth() + 1;
                                        var yyyy = today.getFullYear();
                                        var hh = today.getHours();
                                        var mm = today.getMinutes();
                                        var ss = today.getSeconds();

                                        if (ss < 10) {
                                            ss = "0" + ss;
                                        }

                                        if (mm < 10) {
                                            mm = "0" + mm;
                                        }

                                        if (hh < 10) {
                                            hh = "0" + hh;
                                        }

                                        if (dd < 10) {
                                            dd = "0" + dd;
                                        }

                                        if (MM < 10) {
                                            MM = "0" + MM;
                                        }

                                        //CPU
                                        var data_container_CPU = [
                                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                                            parseInt(JSON.parse(message).cpu_usage),
                                        ];
                                        that.state.cpuOption.push(data_container_CPU);
                                        //Memory
                                        var data_container_memory = [
                                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                                            parseInt(JSON.parse(message).memory_usage),
                                        ];
                                        that.state.memoryOption.push(data_container_memory);
                                        //Storage
                                        var data_container_storage = [
                                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                                            parseInt(JSON.parse(message).storage_usage),
                                        ];
                                        that.state.storage_Option.push(data_container_storage);
                                        //temperature
                                        var data_container_temperature = [
                                            Date.UTC(yyyy, MM - 1, dd, hh, mm, ss),
                                            parseInt(JSON.parse(message).temperature),
                                        ];
                                        that.state.temperature_Option.push(
                                            data_container_temperature
                                        );
                                        // console.log(that.state.cpuOption)

                                        var GBMemory;
                                        var GBStorage;
                                        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
                                        if (JSON.parse(message).memory == 0) {
                                            GBMemory = 0;
                                        } else {
                                            var i = parseInt(
                                                Math.floor(
                                                    Math.log(JSON.parse(message).memory) /
                                                    Math.log(1024)
                                                )
                                            );
                                            GBMemory =
                                                Math.round(
                                                    JSON.parse(message).memory / Math.pow(1024, i),
                                                    2
                                                ) +
                                                " " +
                                                sizes[i];
                                        }
                                        if (JSON.parse(message).storage == 0) {
                                            GBStorage = 0;
                                        } else {
                                            var i = parseInt(
                                                Math.floor(
                                                    Math.log(JSON.parse(message).storage) /
                                                    Math.log(1024)
                                                )
                                            );
                                            GBStorage =
                                                Math.round(
                                                    JSON.parse(message).storage / Math.pow(1024, i),
                                                    2
                                                ) +
                                                " " +
                                                sizes[i];
                                        }
                                        var seriesStorage;
                                        if (JSON.parse(message).storage_usage == "") {
                                            seriesStorage = [0];
                                        } else {
                                            seriesStorage = [
                                                parseInt(JSON.parse(message).storage_usage),
                                            ];
                                        }
                                        //CPU
                                        let arrCPU = that.state.cpuOption;
                                        let ansCPU = deduplicateCPU(arrCPU);
                                        function deduplicateCPU(arrCPU) {
                                            let isExist = (arrCPU, x) => arrCPU.indexOf(x) > -1;
                                            let ansCPU = [];
                                            arrCPU.forEach((element) => {
                                                if (!isExist(ansCPU, element)) ansCPU.push(element);
                                            });
                                            ansCPU.splice(0, 1);
                                            return ansCPU;
                                        }
                                        //memory
                                        let arrMemory = that.state.memoryOption;
                                        let ansMemory = deduplicateMemory(arrMemory);
                                        function deduplicateMemory(arrMemory) {
                                            let isExist = (arrMemory, x) =>
                                                arrMemory.indexOf(x) > -1;
                                            let ansMemory = [];
                                            arrMemory.forEach((element) => {
                                                if (!isExist(ansMemory, element))
                                                    ansMemory.push(element);
                                            });
                                            return ansMemory;
                                        }
                                        //Storage
                                        let arrStorage = that.state.storage_Option;
                                        let ansStorage = deduplicateStorage(arrStorage);
                                        function deduplicateStorage(arrStorage) {
                                            let isExist = (arrStorage, x) =>
                                                arrStorage.indexOf(x) > -1;
                                            let ansStorage = [];
                                            arrStorage.forEach((element) => {
                                                if (!isExist(ansStorage, element))
                                                    ansStorage.push(element);
                                            });
                                            return ansStorage;
                                        }
                                        //Temperature
                                        let arrTemperature = that.state.temperature_Option;
                                        let ansTemperature =
                                            deduplicateTemperature(arrTemperature);
                                        function deduplicateTemperature(arrTemperature) {
                                            let isExist = (arrTemperature, x) =>
                                                arrTemperature.indexOf(x) > -1;
                                            let ansTemperature = [];
                                            arrTemperature.forEach((element) => {
                                                if (!isExist(ansTemperature, element))
                                                    ansTemperature.push(element);
                                            });
                                            return ansTemperature;
                                        }
                                        console.log(paramssh);
                                        that.setState({
                                            paramssh: paramssh,
                                            engineSST: paramssh.engines_pub,
                                            chartOptionsCPU: {
                                                series: [
                                                    {
                                                        name: "CPU",
                                                        data: ansCPU,
                                                    },
                                                ],
                                            },
                                            chartOptionsMemory: {
                                                series: [
                                                    {
                                                        name: "Memory",
                                                        data: ansMemory,
                                                    },
                                                ],
                                            },
                                            chartOptionsStorage: {
                                                series: [
                                                    {
                                                        name: "Storage",
                                                        data: ansStorage,
                                                    },
                                                ],
                                            },
                                            chartOptionsTemperature: {
                                                series: [
                                                    {
                                                        name: "Temperature",
                                                        data: ansTemperature,
                                                    },
                                                ],
                                            },
                                            optionsMemory: {
                                                labels: [`${GBMemory}`],
                                            },
                                            optionsStorage: {
                                                labels: [`${GBStorage}`],
                                            },
                                            seriesCPU: [parseInt(JSON.parse(message).cpu_usage)],
                                            seriesTemperature: [
                                                parseInt(JSON.parse(message).temperature),
                                            ],
                                            seriesMemory: [
                                                parseInt(JSON.parse(message).memory_usage),
                                            ],
                                            seriesStorage: seriesStorage,
                                        });
                                    }
                                });
                            }
                        }
                    );
                }
            });
    }

    getBox() {
        fetch(`${HOST}/api/box/get_by_id/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                this.setState({
                    listBoxListById: data.data,
                    loading: false,
                });
            }
        })
    }

    getSSH() {
        fetch(`${HOST}/api/boxSshPort/getByBoxId/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.data.status == 1) {
                    this.setState({
                        mqttPort: data.data.port,
                        activeSSH: true,
                        statusSSH: data.data,
                        loading: false,
                    });
                } else if (data.data.status == 0) {
                    this.setState({
                        mqttPort: data.data.port,
                        activeSSH: false,
                        statusSSH: data.data,
                        loading: false,
                    });
                }

            } else {
                this.setState({
                    mqttPort: '',
                    activeSSH: false,
                    loading: false,
                });
            }
        })
    }

    handleChangeStartDate = (date) => {
        var camera = this.state.handleChangeStartDate;
        camera = moment(date).format("YYYY-MM-DD HH:mm:ss");
        this.setState({
            handleChangeStartDate: camera,
            loading: true,
        }, () => {
            this.getCPU()
        });
    };

    handleChangeEndDate = (date) => {
        console.log('okee', date);
        var camera = this.state.handleChangeEndDate;
        camera = moment(date).format("YYYY-MM-DD HH:mm:ss");
        this.setState(
            {
                handleChangeEndDate: camera,
                loading: true,
            },
            () => {
                this.getCPU();
            }
        );
    };

    componentWillUnmount() {
        if (HOST_WS.includes("ws")) {
            client.end();
            console.log('mqtt broker disconnected!')
        }
    }
    componentDidMount() {
        $('text.highcharts-credits').html('');
    }

    handleChange = (selectedOption) => {
        this.setState({
            selectedOption,
            loading: true,
        }, () => {
            if (this.state.selectedOption.value < 0) {
                this.getCPU()
            } else {
                this.getCPU(selectedOption);
            }
        });
    }

    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        console.log(this.state.listBoxListById)
        const { chartOptionsCPU, chartOptionsMemory, chartOptionsStorage, chartOptionsTemperature, selectedOption } = this.state;
        const dataEngSTT = this.state.engineSST.map((e, i) => {
            if (e.status == 1) {
                if (e.name != '') {
                    return (
                        <div key={i} className="pb-2" >
                            <span style={{ 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--info m-badge--wide">{e.name}</span>
                        </div>
                    )
                } else {
                    return (
                        <div key={i} className="pb-2">
                            <span style={{ 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--default m-badge--wide" key={i}>Engine Inactive</span>
                        </div>
                    )
                }
            } else {
                if (e.name != '') {
                    return (
                        <div key={i} className="pb-2">
                            <span style={{ 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--danger m-badge--wide" key={i}>{e.name}</span>
                        </div>
                    )
                } else {
                    return (
                        <div key={i} className="pb-2">
                            <span style={{ 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--default m-badge--wide" key={i}>Engine Inactive</span>
                        </div>
                    )
                }

            }
        })
        console.log("123123", this.state.listBoxListById)
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="row m-0">
                        <div className="col-xl-12 pl-0 pr-0">
                            <div className="row m-0">
                                <div className="col-xl-12 pr-0 pl-0">
                                    <div className="m-portlet mb-3">
                                        <div className="m-portlet__body  m-portlet__body--no-padding">
                                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                                <div className="col-xl-12">
                                                    {/*begin:: Widgets/Stats2-1 */}
                                                    <div className="m-widget1 p-2">
                                                        <div className="text-nowrap table-responsive mb-3" id="table_devices">
                                                            <table className="table mb-0 table-bordered table-hover table-checkable text-center dataTable no-footer dtr-inline collapsed">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>ID Box</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>Tên Công Ty</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>Địa chỉ Mac</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>View URL Local</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>IP Private</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>IP Public</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>Time</th>
                                                                        <th style={{ 'verticalAlign': 'middle' }}>Engine Status</th>
                                                                        {process.env.REACT_APP_HIDE_SSH === "0" && <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>SSH</th>}
                                                                        {process.env.REACT_APP_HIDE_SSH === "0" && <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>View Port</th>}
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="append_body">
                                                                    <tr>
                                                                        <td>{this.state.listBoxListById === null ? '' : this.state.listBoxListById.id}</td>
                                                                        <td>{this.state.listBoxListById === null ? '' : this.state.listBoxListById !== null && this.state.listBoxListById.company === null ? "" : this.state.listBoxListById !== null && this.state.listBoxListById.company !== undefined ? this.state.listBoxListById.company.name : ""}</td>
                                                                        <td>{this.state.listBoxListById === null ? '' : this.state.listBoxListById.mac_address}</td>
                                                                        <td>{this.state.listBoxListById === null ? '' : this.state.listBoxListById.view_url_local}</td>
                                                                        <td>{this.state.paramssh === null ? localStorage.getItem("ip_private") : this.state.paramssh.ip_private}</td>
                                                                        <td>{this.state.paramssh === null ? localStorage.getItem("ip_public") : this.state.paramssh.ip_public}</td>
                                                                        <td>{localStorage.getItem("time") !== "Invalid Date" && <Time value={localStorage.getItem("time")} format="DD/MM/YYYY hh:mm:ss" />}</td>
                                                                        <td>{dataEngSTT}</td>
                                                                        {process.env.REACT_APP_HIDE_SSH === "0" &&
                                                                            <td>
                                                                                <Switch
                                                                                    onChange={() => {
                                                                                        swal({
                                                                                            title: "Are you sure?",
                                                                                            text: "Are you sure that you want to change SSH",
                                                                                            icon: "warning",
                                                                                            button: {
                                                                                                text: "Oke!",
                                                                                                closeModal: false,
                                                                                            },
                                                                                        })
                                                                                            .then(name => {
                                                                                                if (!name) throw null;

                                                                                                return fetch(`${HOST}/api/boxSshPort/insert`, {
                                                                                                    method: 'POST',
                                                                                                    headers: {
                                                                                                        'Accept': 'application/json',
                                                                                                        'Content-Type': 'application/json',
                                                                                                        'Authorization': Store.getState().isLogin.access_token
                                                                                                    },
                                                                                                    body: JSON.stringify({
                                                                                                        'box_id': this.props.match.params.id
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                            .then((response) => {
                                                                                                return response.json()
                                                                                            }).then((data) => {
                                                                                                if (data.status == 10000) {
                                                                                                    var that = this;
                                                                                                    if (data.data.status == 0) {
                                                                                                        if (HOST_WS.includes("ws")) {
                                                                                                            var payload =
                                                                                                                JSON.stringify({
                                                                                                                    "method": "open_ssh",
                                                                                                                    "params": { "port": `${data.data.port}` }
                                                                                                                })
                                                                                                            client.publish(`v1/devices/${this.props.match.params.id}/request/+`, payload)

                                                                                                            this.setState({
                                                                                                                mqttPort: data.data.port
                                                                                                            });
                                                                                                        }
                                                                                                        TimeoutSSH = setTimeout(function () {
                                                                                                            swal("Error!", "MQTT Open SSH Not Message!", "error");
                                                                                                        }, 10000);
                                                                                                    } else {
                                                                                                        if (HOST_WS.includes("ws")) {
                                                                                                            this.setState({
                                                                                                                mqttPort: ''
                                                                                                            });
                                                                                                            var payload =
                                                                                                                JSON.stringify({
                                                                                                                    "method": "close_ssh"
                                                                                                                })
                                                                                                            client.publish(`v1/devices/${this.props.match.params.id}/request/+`, payload)
                                                                                                            console.log(client)
                                                                                                            console.log(data.data.port)
                                                                                                            console.log(this.props.match.params.id)
                                                                                                        }
                                                                                                        TimeoutSSH = setTimeout(function () {
                                                                                                            swal("Error!", "MQTT Close SSH Not Message!", "error");
                                                                                                        }, 10000);
                                                                                                    }
                                                                                                } else {
                                                                                                    swal("Error!", "Chưa có idBox!", "error", {
                                                                                                        buttons: false,
                                                                                                        timer: 1500,
                                                                                                    });
                                                                                                }
                                                                                            })
                                                                                    }}
                                                                                    checked={this.state.activeSSH}
                                                                                    handleDiameter={18}
                                                                                    offColor="#969696"
                                                                                    onColor="#00c5dc"
                                                                                    offHandleColor="#ffffff"
                                                                                    onHandleColor="#08f"
                                                                                    height={25}
                                                                                    width={55}
                                                                                    className="react-switch"
                                                                                    id="small-radius-switch"
                                                                                />
                                                                            </td>
                                                                        }
                                                                        {process.env.REACT_APP_HIDE_SSH === "0" &&
                                                                            <td>
                                                                                <button className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill" style={{ 'bottom': '3px' }} title="View Port"
                                                                                    onClick={
                                                                                        () => {
                                                                                            if (this.state.mqttPort != '') {
                                                                                                swal("SSH command: ssh -J ec2-user@tunnel.beetai.com root@localhost -p " + this.state.mqttPort, {
                                                                                                    button: {
                                                                                                        text: "Close!",
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ><i className="la la-eye"></i>
                                                                                </button>
                                                                            </td>}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/*end:: Widgets/Stats2-1 */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-portlet">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                <span className="m-nav__link-icon text-danger mr-2">
                                                    <span className="m-nav__link-icon-wrapper">
                                                        <i className="flaticon-diagram" />
                                                    </span>
                                                </span>
                                                Charts Detail
                                            </h3>
                                        </div>
                                    </div>
                                    {
                                        selectedOption.value < 0 && (
                                            <div className="m-portlet__head-tools">
                                                <div>Start date: </div>
                                                <div style={{ 'width': '170px' }} className='mr-1'>
                                                    <ReactDatePicker
                                                        showTimeInput
                                                        style={{ marginRight: 30 }}
                                                        className="form-control m-input m_datetimepicker_4"
                                                        selected={new Date(this.state.handleChangeStartDate)}
                                                        onChange={this.handleChangeStartDate}
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        maxDate={new Date(this.state.handleChangeEndDate)}
                                                    />
                                                </div>
                                                <div>End date: </div>
                                                <div style={{ 'width': '170px' }}>
                                                    <ReactDatePicker
                                                        showTimeInput
                                                        className="form-control m-input m_datetimepicker_4"
                                                        selected={new Date(this.state.handleChangeEndDate)}
                                                        onChange={this.handleChangeEndDate}
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        minDate={new Date(this.state.handleChangeStartDate)}
                                                    // maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="m-portlet__head-tools">
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                        <div style={{ 'width': '170px' }}>
                                            <Select
                                                placeholder={'Select Company'}
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.timeReal}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body m-portlet__body--no-padding">
                                    <div className="row m-row--no-padding m-row--col-separator-xl">
                                        <div className="col-xl-12">
                                            <div className="row m-0">
                                                <div className="col-xl-6 pr-1 pl-0">
                                                    <div className="m-portlet mb-2">
                                                        <div className="m-portlet__body  m-portlet__body--no-padding">
                                                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                                                <div className="col-xl-7">
                                                                    {/*begin:: Widgets/Stats2-1 */}
                                                                    <div className="m-widget1 p-2">
                                                                        <HighchartsReact
                                                                            highcharts={Highcharts}
                                                                            options={chartOptionsCPU}
                                                                        />
                                                                    </div>
                                                                    {/*end:: Widgets/Stats2-1 */}
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    <div className="outer">
                                                                        <div id="chartCpu">
                                                                            <ReactApexCharts options={this.state.optionsCPU} series={this.state.seriesCPU} type="radialBar" height="350" />
                                                                        </div>
                                                                    </div>
                                                                    {/*end:: Widgets/Daily Sales*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 pl-1 pr-0">
                                                    <div className="m-portlet mb-2">
                                                        <div className="m-portlet__body  m-portlet__body--no-padding">
                                                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                                                <div className="col-xl-7">
                                                                    {/*begin:: Widgets/Stats2-1 */}
                                                                    <div className="m-widget1 p-2">
                                                                        <HighchartsReact
                                                                            highcharts={Highcharts}
                                                                            options={chartOptionsTemperature}
                                                                        />
                                                                    </div>
                                                                    {/*end:: Widgets/Stats2-1 */}
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    <div className="outer">
                                                                        <div id="chartMemory">
                                                                            <ReactApexCharts options={this.state.optionsTemperature} series={this.state.seriesTemperature} type="radialBar" height="350" />
                                                                        </div>
                                                                    </div>
                                                                    {/*end:: Widgets/Daily Sales*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-xl-6 pr-1 pl-0">
                                                    <div className="m-portlet mb-0">
                                                        <div className="m-portlet__body  m-portlet__body--no-padding">
                                                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                                                <div className="col-xl-7">
                                                                    {/*begin:: Widgets/Stats2-1 */}
                                                                    <div className="m-widget1 p-2">
                                                                        <HighchartsReact
                                                                            highcharts={Highcharts}
                                                                            options={chartOptionsMemory}
                                                                        />
                                                                    </div>
                                                                    {/*end:: Widgets/Stats2-1 */}
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    <div className="outer">
                                                                        <div id="chartCpu">
                                                                            <ReactApexCharts options={this.state.optionsMemory} series={this.state.seriesMemory} type="radialBar" height="350" />
                                                                        </div>
                                                                    </div>
                                                                    {/*end:: Widgets/Daily Sales*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 pl-1 pr-0">
                                                    <div className="m-portlet mb-0">
                                                        <div className="m-portlet__body  m-portlet__body--no-padding">
                                                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                                                <div className="col-xl-7">
                                                                    {/*begin:: Widgets/Stats2-1 */}
                                                                    <div className="m-widget1 p-2">
                                                                        <HighchartsReact
                                                                            highcharts={Highcharts}
                                                                            options={chartOptionsStorage}
                                                                        />
                                                                    </div>
                                                                    {/*end:: Widgets/Stats2-1 */}
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    <div className="outer">
                                                                        <div id="chartMemory">
                                                                            <ReactApexCharts options={this.state.optionsStorage} series={this.state.seriesStorage} type="radialBar" height="350" />
                                                                        </div>
                                                                    </div>
                                                                    {/*end:: Widgets/Daily Sales*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailDevice;

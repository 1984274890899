import React, { Component } from "react";
import { HOST } from '../../config';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import ModalLicense from "../modal/ModalLicense";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Select from "react-select";
import Switch from "react-switch";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class License extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listLicense: [],
            crrlistLicense: [],
            crrLicense: {
                "id_company": null,
                "id_module": [],
                "active": 1,
                "created_user": null
            },
            listStatus: [
                {
                    label: 'All', value: 2
                },
                {
                    label: 'Active', value: 1
                },
                {
                    label: 'Inactive', value: 0
                }
            ],
            valueStatus: 2,
            activePage: 1,
            offset: 0,
            showFirst: 0,
            showLast: 0,
            ModalLicense: false,
            loading: true,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
    }

    componentDidMount() {
        this.getLicense()
    }

    getLicense = () => {
        fetch(`${HOST}/api/license/get_by_active/${this.state.valueStatus}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listLicense: data.data,
                    loading: false,
                }, () => {
                    this.FilterLicense(this.state.activePage);
                });
            } else {
                this.setState({
                    listLicense: [],
                    loading: false,
                    offset: 0,
                    showFirst: 0,
                    showLast: 0,
                    totalLength: 0
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listLicense: [],
                    loading: false,
                    offset: 0,
                    showFirst: 0,
                    showLast: 0,
                    totalLength: 0
                })
            }
        })
    }

    FilterLicense = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrlistLicense = this.state.listLicense.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrlistLicense,
            offset,
            showFirst: offset + 1,
            showLast: crrlistLicense.length + offset,
            totalLength: this.state.listLicense.length
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterLicense(pageNumber);
    }

    handleChangeStatus = (value) => {
        this.setState({
            valueStatus: value.value,
            crrlistLicense: [],
            listLicense: [],
            loading: true,
            activePage: 1,
            offset: 0,
            showFirst: 0,
            showLast: 0,
        }, () => {
            this.getLicense()
        })
    }

    DeleteLicense = async (data, i) => {
        await fetch(`${HOST}/api/license/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': data.id
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                swal("Deleted!", "You have successfully deleted!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.getLicense();
            }
            else {
                swal("Error!", "Delete Failed!", "error");
            }
        });


    }

    modalClose = () => {
        this.setState({
            ModalLicense: false,
        });
        this.getLicense();
    }

    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedLicense = this.state.crrlistLicense.map((e, i) => {
            var fullNameUpdate = ""
            if (e.user_updated !== null) {
                fullNameUpdate = e.user_updated.first_name + ' ' + e.user_updated.last_name
            }
            var nameCompany = ""
            if (e.company !== null) {
                nameCompany = e.company.name
            }
            var nameModule = ""
            if (e.module !== null) {
                nameModule = e.module.code
            }
            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{nameCompany}</td>
                    <td>{nameModule}</td>
                    <td>{e.user_created.first_name + ' ' + e.user_created.last_name}</td>
                    <td>{fullNameUpdate}</td>
                    <td>
                        {
                            e.active === 0 
                            ?
                            <span className="m-badge  m-badge--secondary m-badge--wide">Inactive</span>
                            :
                            <span className="m-badge  m-badge--accent m-badge--wide">Active</span>
                        }
                        
                    </td>
                    <td>
                        <button onClick={(e) => {
                            var data = this.state.crrlistLicense
                            var dataLicense = {
                                id: data[i].id,
                                id_company: data[i].id_company,
                                id_module: data[i].id_module,
                                active: data[i].active,
                                created_user: data[i].created_user,
                            };
                            this.setState({
                                crrLicense: dataLicense,
                                ModalLicense: true,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                            <i className="la la-edit" />
                        </button>

                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete " + nameCompany,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteLicense(e, i);
                                        }
                                    });

                            }}
                        ><i className="la la-trash"></i></button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="la la-object-ungroup" />
                                        </span>
                                    </span>
                                    License
                                </h3>
                            </div>
                        </div>
                        <div className="row p-3 col-xl-10">
                            <div className="col-xl-2 pl-0 mb-3">
                                <Select
                                    value={
                                        this.state.listStatus.filter((option) => {
                                            return this.state.valueStatus === option.value
                                        })
                                    }
                                    onChange={(value) => this.handleChangeStatus(value)}
                                    options={this.state.listStatus}
                                />
                            </div>
                        </div>
                        <div className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {
                                        this.setState({
                                            ModalLicense: true,
                                            crrLicense: {
                                                "id_company": null,
                                                "id_module": [],
                                                "active": 1,
                                                "created_user": null
                                            }
                                        });
                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>

                            <ModalLicense
                                crrlicense={this.state.crrLicense}
                                show={this.state.ModalLicense}
                                onHide={this.modalClose}
                            />
                        </div>
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Comapny</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Module</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>User Created</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>User Updated</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Status</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedLicense}
                                            </tbody>
                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                        <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                            </span>
                                        <Pagination
                                            prevPageText='Previous'
                                            nextPageText='Next'
                                            firstPageText='First'
                                            lastPageText='Last'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default License;

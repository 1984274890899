import React, { Component } from "react";
import { HOST } from "../../config";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import Store from "../../store";
import Pagination from "react-js-pagination";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

// import XLSX from "xlsx";



class ReportStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusArray: [
        { value: 1, label: "In Stock", checked: true},
        { value: 2, label: "Sold", checked: true},
        { value: 3, label: "POC", checked: true},
        { value: 4, label: "Rented", checked: true},
        { value: 5, label: "Under Warranty", checked: true},
        { value: 6, label: "Out of order", checked: true},
      ],
      soldAll: 0,
      inStockAll: null,
      dataChartHori: [],
      soldInToday: 0,
      allCompany:0,
      allDevices:0,
      amountDevices:[],
      activePage: 1,
      countItem: -1,
      tableExportExcel: 1,
      listExportExcel: [],
      listSerialExportExcel: [],
      allDevicesSelected: true,
      indexSelectedDevices: -1,
      listDevices:[],
      listState:[],
      listConvertFull: [],
      serialTypeOfBoxList:{},
      statusChecked:[1,2,3,4,5,6,7],
      indexCheckbox: -1,
      defaultCheckBox: true,
      defaultCheckBoxRented: true,
      selectedDevices: false,
      indexSelectedAllDevices: '',
      showDropDownStatus: false,
      startDate: moment().subtract(7,"days").format("YYYY-MM-DD"),
      // endDate: moment().endOf('day').format("YYYY-MM-DD"),
      endDate: moment().format('YYYY-MM-DD'),
      // To avoid unnecessary update keep all options in the state.
      listBox: [],
      listNumberForDay: [],
      listDataInStock: [],
      listDataSold: [],
      listDataPOC:[],
      listDataRented: [],
      listDataUnderWarranty: [],
      listDataOutOfOrder: [],
      chartOptions: {
        title: {
          text: 'Variable device changes state by day'
        },
        xAxis: {
          categories: []
        },
        credits: {
          enabled: false
        },
        yAxis:{
          labels: {
            formatter: function () {
              // if(this.value % 1 !=0)
              return this.value % 1 === 0 ? this.value : "" ;
            }
  
          }
        },
        series: [
           
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this),
              },
            },
          },
        },
      },
      hoverData: [],
      chartOptionshorizontal: {
        chart: {
          type: 'bar'
        },
        title: {
            text: 'Number of devices by status'
        },
        xAxis: {
            categories: ["s",'In Stock', 'Sold', 'Rented', 'POC', 'Under Warranty', "Out Of Order", "Fake"],
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: ' pcs'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true
        },
        credits: {
            enabled: false
        },
        series: [{
            // name: "Bi Face",
            data: []
        }]
      }
    };
    // this.chartOptions = {
    //   xAxis: {
    //     categories: this.getDates(this.state.startDate, this.state.endDate),
    //   },
    //   series: [
    //     {
    //       data: [1, 2, 3, 4, 5, 6, 7, 8, 7, 9, 10, 12, 11],
    //     },
    //   ],
    //   plotOptions: {
    //     series: {
    //       point: {
    //         events: {
    //           mouseOver: this.setHoverData.bind(this),
    //         },
    //       },
    //     },
    //   },
    // };
    this.Data();
  }

  Data = () => {
    // this.getApiFilterDate();
    // this.getAllTypeDevice();
  }
  // getNumber = () => {
  //   for (let index = 0; index < this.state.listExportExcel.length; index++) {
  //     index
      
  //   }
  // }

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  toNumericPairs = input => {
    const entries = Object.entries(input);
    entries.forEach(entry => entry[0] = +entry[0]);
    return entries;
  }

    toNumeric = input => {
    // const entries = Object.entries(this.state.listNumberForDay);
    // entries.forEach(entry => entry[0] = +entry[0]);
    var entries = Object.values(Object.values(input))
    
    // var entries = Object.values(Object.values(this.state.listNumberForDay))

    return entries;
  }

  getAllSoldInStock = () => {
    fetch(`${HOST}/api/box_status/get_by_filter?statuses=${this.state.statusChecked.join("")}&start_time=&end_time=&index=0&item_per_page=0&type_device=${this.state.indexSelectedAllDevices}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if(data.status === 10000){
        var array = this.toNumeric(data.box_by_status_qty)
        this.setState({
          soldAll: array[1],
          inStockAll: array[0],
        })
      }
    })
  }

  _exporter;
  exportExcel(){
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD 23:59:59');
    fetch(`${HOST}/api/box_status/get_by_filter?statuses=${this.state.statusChecked.join("")}&start_time=${this.state.startDate}&end_time=${endDate}&index=0&item_per_page=10&type_device=${this.state.indexSelectedAllDevices}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if(data.status === 10000){
        let listExportExcel = this.state.listExportExcel;
        listExportExcel = data.box_status_info;
        var list = listExportExcel.map((item) => {
            // if(item.box_status === 1 ){
            //   return {...item, box_status: "In Stock"}
            // }
            // else if(item.box_status === 2){
            //   return {...item, box_status: "Sold"}
            // }
            // else if(item.box_status === 3){
            //   return {...item, box_status: "POC"}
            // }
            // else if(item.box_status === 4){
            //   return {...item, box_status: "Rented"}
            // }
            // else if(item.box_status === 5){
            //   return {...item, box_status: "Under Warranty"}
            // }
            // else if(item.box_status === 6){
            //   return {...item, box_status: "Out Of Order"}
            // }
            switch (item.box_status)
            {
              case 1 : {
                return{...item, box_status: "In Stock"};
              }
              case 2 : {
                return{...item, box_status: "Sold"};
              }
              case 3 : {
                return{...item, box_status: "POC"};
              }
              case 4 : {
                return{...item, box_status: "Rented"};
              }
              case 5 : {
                return{...item, box_status: "Under Warranty"};
              }
              case 6 : {
                return{...item, box_status: "Out Of Order"};
              }
              default : {
                return{...item, box_status: ""}
              }
            }
        });
        console.log(list);
        this.setState({
          listExportExcel: list,
        })
        this._exporter.save();
      }else{
        this.setState({
          listExportExcel: [],
        })
        swal("Warning!", "Not data to export", "warning");

      }
    })
  }

  handlePageChange = (activePageNumber) => {
    console.log(activePageNumber - 1);
    this.setState({
      activePage: activePageNumber
    }, () => {
      this.getApiFilterDate();
      this.getAllSoldInStock()
    })
    // this.setState({
    //   activePage: activePage,
    // })
  }

  getApiFilterDate() {
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD 23:59:59');
    // this.state.listDevices.map(item => {
    //   this.state.indexSelectedAllDevices.push(item.id)
    // })
    // const indexSelectedAllDevices = this
    
    fetch(`${HOST}/api/box_status/get_by_filter?statuses=${this.state.statusChecked.join("")}&start_time=${this.state.startDate}&end_time=${endDate}&index=${this.state.activePage}&item_per_page=10&type_device=${this.state.indexSelectedAllDevices}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 10000) {
          var soldInToday = data['today_sold'];
          var a = data.box_by_status_qty;
          var dataChartHori = this.state.dataChartHori;
          dataChartHori = this.toNumericPairs(a).slice(0,6);
          this.setState({
            dataChartHori: dataChartHori
          },() => {
            this.setState({
              chartOptionshorizontal: {
                ...this.state.chartOptionshorizontal,
                series: [{
                  name: "",
                  data: this.state.dataChartHori
                }]
              }
            })
          })
          var listNumberForDay = this.state.listNumberForDay;
          listNumberForDay = data.statuses_qty;
          var listState = this.state.listState;
          var listConvertFull = this.state.listConvertFull;
          
          listConvertFull = this.convertFull(Object.values(Object.values(data.statuses_qty)));
            var listDataInStock = [];
            var listDataSold = [];
            var listDataPOC = [];
            var listDataRented = [];
            var listDataUnderWarranty = [];
            var listDataOutOfOrder = [];
            listConvertFull.map((item) => {
              listDataInStock.push(item["1"]);
              listDataSold.push(item["2"]);
              listDataPOC.push(item["3"]);
              listDataRented.push(item["4"]);
              listDataUnderWarranty.push(item["5"]);
              listDataOutOfOrder.push(item["6"]);
            })
          this.setState({
              listNumberForDay: listNumberForDay,
              // listConvertFull: listConvertFull
             
          }, () => {
            this.setState({                                                           //Khi mở màn lên sẽ được setState cho categories
              chartOptions: {
                ...this.state.chartOptions,
                xAxis: {
                  ...this.state.xAxis,
                  categories: Object.keys(listNumberForDay).map(item => 
                    moment(item).format("DD-MM")
                  ),
                },
                series: [
                  {
                    name: "In Stock",
                    data: listDataInStock
                  },
                  {
                    name: "Sold",
                    data: listDataSold
                  },
                  {
                    name: "POC",
                    data: listDataPOC
                  },
                  {
                    name: "Rented",
                    data: listDataRented
                  },
                  {
                    name: "Under Warranty",
                    data: listDataUnderWarranty
                  },
                  {
                    name: "Out Of Order",
                    data: listDataOutOfOrder
                  },
                ]
              },
            });
          }) 

          var listBox = this.state.listBox;
          listBox = data.box_status_info;
          this.setState({
            listBox: listBox,
          });

          var serialTypeOfBoxList = this.state.serialTypeOfBoxList;
          serialTypeOfBoxList = data.box_info;
         
          this.setState({
              serialTypeOfBoxList: serialTypeOfBoxList,
              listBoxLength: data.count,
              soldInToday: soldInToday
          })
        }
      });
  }

  getAllTypeDevice(){
    fetch(`${HOST}/api/type_device/get_all`
    , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    }).then((res) => {
      return res.json();
    }).then((data)=>{
      const indexSelectedAllDevices = [];
      data.data.map(item => {
        indexSelectedAllDevices.push(item.id);
      })
      if(data.status === 10000){
        this.setState({
          indexSelectedAllDevices: indexSelectedAllDevices.join(','),
          listDevices: data.data,
        },() => {
          this.getApiFilterDate();
          this.getAllSoldInStock()
        })
      }
    })
  }

  getApiByAllDevice(){
    fetch(`${HOST}/api/report/getAll`
    , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    }).then((res) => {
      return res.json();
    }).then((data)=>{
      if(data.status === 10000){
        var arrayDevices = this.toNumericPairs(data.box_by_type_device_qty);
        var allCompany = data.company_qty;
        var allDevices = data.box_qty; 
        this.setState({
          allCompany: allCompany,
          allDevices: allDevices,
          amountDevices: arrayDevices,
        })
      }
    })
  }

  getApiById(id) {
    fetch(`${HOST}/api/box/get_by_id/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Store.getState().isLogin.access_token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 10000) {
          this.setState();
        }
      });
  }

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };

  

 componentDidMount = () => {
  this.getApiByAllDevice();
  this.Data();
  // this.getApiFilterDate();
  this.getAllTypeDevice();
  this.getAllSoldInStock();
 }

  updateSeries = () => {
    // The chart is updated only with new options.
    this.setState({
      chartOptions: {
        series: [{ data: [Math.random() * 5, 2, 1] }],
      },
    });
  };

  checkedStatus = () => {
    var statusChecked = this.state.statusArray.filter((item)=>item.checked).map((x)=>x.value);
    this.setState({
        statusChecked: statusChecked
    })
  }

  convertFull = (input) => {
    var a = input;
    for (var obj of a) {
      for (var key of Object.keys(obj)) {
        a = a.map(o => (o[key] = o[key] || 0, o))
      }
    };
    return a;
  }

  data = () =>{

  }



  render() {
    console.log(this.state.endDate);
    if (this.state.isLogin == false) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content p-2">
       
        

          <div className="row m-0">
            <div className="col-xl-12 pl-4 pr-4">
              <div className="row m-0">
                <div className="col-xl-12 pr-0 pl-0">
                  <div className="row m-0">
                 
                    <div className="col-xl-3 pr-4 pl-4">
                      <div className="m-portlet mb-3 border-radius">
                        <div className="m-portlet__body m-portlet__body--no-padding">
                          <div className="row m-row--no-padding m-row--col-separator-xl padding">
                            <div className="col-xl-12 flex justify-content">
                              <h4 className="date-in-card-status">All Boxs</h4>
                              <span><i style={{fontSize: 40, color: "#5ddab4"}} className="la la-inbox"></i></span>
                            </div>
                            <h1 style={{}}>{this.state.allDevices}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 pr-4 pl-0">
                      <div className="m-portlet mb-3 border-radius">
                        <div className="m-portlet__body m-portlet__body--no-padding">
                          <div className="row m-row--no-padding m-row--col-separator-xl padding">
                            <div className="col-xl-12 flex justify-content">
                              <h4 className="date-in-card-status">All Companies</h4>
                              <span><i style={{fontSize: 40, color: "#ab47bc"}} className="la la-building-o"></i></span>
                            </div>
                            <h1 style={{}}>{this.state.allCompany}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 pr-4 pl-0">
                      <div className="m-portlet mb-3 border-radius">
                        <div className="m-portlet__body m-portlet__body--no-padding padding">
                          <div className="row m-row--no-padding m-row--col-separator-xl padding">
                            <div className="col-xl-12 flex justify-content">
                              <h4 className="date-in-card-status">Sold</h4>
                              <span><i style={{fontSize: 40, color: "#03a9f4"}} className="la	la-bookmark"></i></span>
                            </div>
                            <h1 style={{}}>{this.state.soldAll === null || this.state.soldAll === undefined ? "0" : this.state.soldAll}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 pr-4 pl-0">
                      <div className="m-portlet mb-3 border-radius">
                        <div className="m-portlet__body m-portlet__body--no-padding">
                          <div className="row m-row--no-padding m-row--col-separator-xl padding">
                            <div className="col-xl-12 flex justify-content">
                              <h4 className="date-in-card-status">In Stock</h4>
                              <span><i style={{fontSize: 40, color: "#fa9f1b"}} className="la	la-archive"></i></span>
                            </div>
                            <h1 style={{}}>{this.state.inStockAll === null || this.state.inStockAll === undefined ? "0" : this.state.inStockAll}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                   
                  </div>
                </div>
              </div>
              <div className="row m-0">                        
                        <div className="m-portlet mb-3 border-radius col-xl-12">
                          <div className="m-portlet__body  m-portlet__body--no-padding sticky">
                            <div className="list-statitic">                            
                              <div className="col-xl-2 pl-4 pr-5 pt-4 pt-3 pb-4" onClick={() => {
                                var indexSelectedAllDevices = [];
                                this.state.listDevices.map(item => {
                                  indexSelectedAllDevices.push(item.id)
                                })
                                
                                this.setState({
                                  allDevicesSelected: true,
                                  selectedDevices: false,
                                  indexSelectedAllDevices: indexSelectedAllDevices.join(",")
                                }, ()=>{
                                  this.getApiFilterDate();
                                  this.getAllSoldInStock();
                                })
                                  this.getApiFilterDate()
                                }}>
                                <div className={this.state.allDevicesSelected === true ? "item padding-1 select-bottom" : "item padding-1"}>                             
                                  <div className="top-item" style={{borderBottom: "1", borderBottomColor: "#adb3b4",marginTop: 5,padding: 6}}>
                                    <span style={{fontWeight: 500, color: "#adb3b4", fontSize: 15}}>All Devices</span>
                                  </div>
                                  <div className="out-number-item">
                                    <span className="number-item">{this.state.allDevices}</span>
                                  </div>
                                </div>
                              </div>
                              {this.state.listDevices.map((item, index) => {
                                return(
                                <div className="col-xl-2 pl-4 pr-5 pt-4 pt-3 pb-4" onClick={()=>{
                                  this.setState({
                                    indexSelectedDevices: index,
                                    selectedDevices: true,
                                    allDevicesSelected: false,
                                    indexSelectedAllDevices: item.id
                                  },() => {
                                    this.getApiFilterDate();
                                    // this.getAllSoldInStock();
                                  } )
                                }}>
                                  <div className={this.state.selectedDevices===true && this.state.indexSelectedDevices === index ? "item padding-1 select-bottom" : "item padding-1"}>                             
                                    <div className="top-item" style={{borderBottom: "1", borderBottomColor: "#adb3b4",marginTop: 5,padding: 6}}>
                                      <span style={{fontWeight: 500, color: "#adb3b4", fontSize: 15}}>{item.name}</span>
                                    </div>
                                    <div className="out-number-item">
                                      {this.state.amountDevices.map((amountDevices) => {
                                        if(amountDevices[0] === item.id)
                                        return(<span className="number-item">{amountDevices[1]}</span>)
                                      })}
                                      
                                    </div>
                                  </div>
                                </div>
                                )
                              })}
                              
                            </div>
                          </div>
                        </div>    
              </div>
              <div className="row m-0">
                <div className="col-xl-4 pr-4 pl-0">
                  <div className="row m-0">
                 
                    <div className="col-xl-12 pr-0 pl-0">
                      <div className="m-portlet mb-3 border-radius">
                        <div className="m-portlet__body m-portlet__body--no-padding">
                          <div className="row m-row--no-padding m-row--col-separator-xl padding">
                            <div className="col-xl-12 flex justify-content">
                              <h2 style={{color: "black"}}>Sold In Today</h2>
                              <span><i style={{fontSize: 40, color: "#df5645"}} className="flaticon-event-calendar-symbol"></i></span>
                            </div>
                            <h1>{this.state.soldInToday}</h1>
                            <div className="col-xl-12">
                              <h4 className="date-in-card-status">{moment().format("DD-MM-YYYY")}</h4>
                            </div>
                            <div className="col-xl-12 margin-top-2">
                              {/* <h4 className="">Status</h4> */}
                              <HighchartsReact 
                                highcharts={Highcharts}
                                options={this.state.chartOptionshorizontal}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                   
                  </div>
                </div>
                <div className="col-xl-8 pr-0 pl-0">
                  <div className="m-portlet mb-3">
                    <div className="m-portlet__body  m-portlet__body--no-padding">
                      <div className="row m-row--no-padding m-row--col-separator-xl padding">
                        <div className="col-xl-12">
                          <div className="group-input-icon">
                            <div className="form-group m-form__group col-xl-6"></div>
                            <div className="form-group m-form__group col-xl-3 flex">
                              <span style={{padding: 8}}>From</span>
                              <DatePicker
                                selected={this.state.startDate === null ? "" : this.state.startDate === "" ? "" : new Date(this.state.startDate)}
                                className="form-control m-input m_datetimepicker_6"
                                  // customTimeInput={}
                                dateFormat="yyy-MM-dd"
                                onChange={(date) => {
                                  var startTime =
                                  moment(date).format("YYYY-MM-DD");
                                  this.setState({ startDate: startTime },()=>{
                                    this.getApiFilterDate();
                                    this.getAllSoldInStock();
                                    // this.exportExcel();
                                    this.setState({
                                      activePage: 1
                                    }, () => {
                                      this.getApiFilterDate();
                                      this.getAllSoldInStock();
                                    })
                                  })
                                }}
                                placeholderText="Select date"
                                maxDate={new Date(this.state.endDate)}
                              />
                            </div>
                            <div className="form-group m-form__group col-xl-3 flex">
                              <span style={{padding: 8}}>To</span>
                              <DatePicker
                                selected={this.state.endDate === null ? "" : this.state.endDate === "" ? "" : new Date(this.state.endDate)}
                                className="form-control m-input m_datetimepicker_6"
                                dateFormat={"yyy-MM-dd"}

                                onChange={(date) => {
                                  var time = moment(date).format("YYYY-MM-DD");
                                  this.setState({ endDate: time },() => {
                                    this.getApiFilterDate();
                                    this.getAllSoldInStock();
                                    this.setState({
                                      activePage: 1
                                    }, () => {
                                      this.getApiFilterDate();
                                      this.getAllSoldInStock();
                                    })
                                  });
                                }}
                                maxDate={new Date()}
                                minDate={new Date(this.state.startDate)}
                              />
                            </div>
                          </div>
                          <div className="charts">
                            <HighchartsReact
                              styles={{height: 700}}
                              highcharts={Highcharts}
                            
                              options={this.state.chartOptions}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="m-widget1 p-2">
                            <div className="export-excel">
                       
                              {/* <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air"
                                table="table-to-xls"
                                filename="ReportStatus"
                                sheet="tablexls"
                                buttonText="Export Excel"
                                onClick = {() => {
                                  this.exportExcel()
                                }}
                              /> */}
                              <button
                                onClick={() => {
                                  this.exportExcel()
                                }}
                                className="btn btn-success m-btn m-btn--icon">
                                <span>
                                  <i className="la la-download pr-2" />
                                  <span>
                                    Export Excel
                                  </span>
                                </span>
                              </button>
                              <ExcelExport
                                data={this.state.listExportExcel}
                                fileName={"Report_Status.xlsx"}
                                ref={(exporter) => { this._exporter = exporter; }}>
                                <ExcelExportColumn field="time" title="Date" width={150} />
                                <ExcelExportColumn field="box_id" title="Box ID" width={80} />
                                <ExcelExportColumn field="type_device" title="Type" width={80} />
                                <ExcelExportColumn field="company" title="Company" width={100} />
                                <ExcelExportColumn field="box_status" title="Status" width={100} cellOptions={(item) => {
                                  this.state.statusArray.map(id => {
                                    if(id.value == item)
                                      return id.label
                                  })
                                }}  />
                                {/* <ExcelExportColumn field="day_of_week" title={language[this.props.indexLanguage].textTable.day_of_week} width={330} />
                                            <ExcelExportColumn field="status_door" title={language[this.props.indexLanguage].textTable.status_door} width={80} />
                                            <ExcelExportColumn field="users" title={language[this.props.indexLanguage].textTable.staff} /> */}
                              </ExcelExport>
                            </div>
                            <div
                              className="text-nowrap table-responsive mb-3"        
                            >  
                              <table className="table m-table m-table--head-bg-brand">
                                <thead>
                                  <tr>
                                    <th style={{ verticalAlign: "middle" }}>
                                      STT
                                    </th>
                                    <th style={{ verticalAlign: "middle" }}>
                                      Date
                                    </th>
                                    <th style={{ verticalAlign: "middle" }}>
                                      Box ID
                                    </th>
                                    <th style={{ verticalAlign: "middle" }}>
                                      Type
                                    </th>
                                    <th style={{ verticalAlign: "middle" }}>
                                      Company
                                    </th>
                                    <th style={{ verticalAlign: "middle" }}>
                                      Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody id="append_body">
                                  {this.state.listBox.map((item,index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index+1+(this.state.activePage-1)*10}</td>
                                        <td>{moment(item.time).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>
                                          {item.box_id}
                                        </td>
                                        <td>{item.type_device}</td>
                                        <td>{item.company}</td>
                                        <td>
                                          {this.state.statusArray.map(
                                            (option) => {
                                              if (
                                                option.value == item.box_status
                                              ) {
                                                return (
                                                  <div>{option.label}</div>
                                                );
                                              }
                                            }
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              <Pagination
                                prevPageText='Previous'
                                nextPageText='Next'
                                firstPageText='First'
                                lastPageText='Last'
                                activePage={this.state.activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.listBoxLength}
                                pageRangeDisplayed={2}
                                onChange={this.handlePageChange}

                              />
                            </div>
                          </div>
                          {/*end:: Widgets/Stats2-1 */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>             
              </div>
              
                  
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportStatus;
import React, { Component } from "react";
import { HOST } from '../../config';
import ModalCompany from '../modal/company/ModalCompany';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import ReactTooltip from "react-tooltip";
import moment from 'moment'
import Switch from "react-switch";
import Select from "react-select";

const override = css`       
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Company extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            listCompany: [],
            crrCompany: null,
            crrCamera: null,
            crrIdxCom: -1,
            crrIdxCam: -1,
            modalShow: false,
            modalAddShow: false,
            modalAddCamShow: false,
            modalEditCamShow: false,
            isChecked: false,
            isLogin: Store.getState().isLogin.isLogin,
            activePage: 1,
            AllCom: [],
            crrCom: [],
            tempValue: '',
            searchBox: '',
            offset: 0,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            loading: true,
            valueSelected: { value: 0, label: "Active" },
            optionSelect: [{ value: -1, label: "All" },{ value: 0, label: "Active" }, { value: 1, label: "Inactive" }],
            countData: 0
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
        this.getCompany();
    }

    getCompany = () => {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: this.state.valueSelected.value,
                item_per_page: this.itemsPerPage,
                index: this.state.activePage,
                data_search: this.state.tempValue,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var com = {
                    id: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].id,
                    name: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].name,
                    phone: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].phone,
                    email: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].email,
                    description: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].description,
                    address: this.state.crrIdxCom === -1 ? '' : data.data[this.state.crrIdxCom].address
                };
                this.setState({
                    listCompany: [...data.data],
                    AllCom: [...data.data],
                    crrCompany: com,
                    countData: data.count,
                    loading: false
                });

                this.FilterDevice(this.state.activePage)
            }
        })
    }


    reset = (e) => {
        this.setState({
            searchBox: '',
            activePage: 1,
            tempValue: '',
        },() => {
            this.getCompany();
        });
        $('#inputSearch').val('');
        this.state.listCompany = this.state.AllCom;
    }

    DeleteCompany = async (com) => {
        let data = await fetch(`${HOST}/api/company/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'id': com.id,
                'is_delete': 1,
            })
        }).then((response) => {

            return response.json()
        });

        if (data.status === 10000) {
            this._isMounted = true;
            let lstcom = this.state.listCompany;
            let newList = lstcom.filter((item, index) => {
                return item.id !== com.id;
            });
            var { activePage } = this.state
            if (this.state.crrCom.length === 1) {
                activePage = activePage - 1
            }

            this.setState({
                listCompany: newList,
                crrCompany: null,
                crrCamera: null,
                crrIdxCom: -1,
                crrIdxCam: -1,
                activePage
            });
            this.getCompany();
            swal("Deleted!", "You have successfully deleted!", "success", {
                buttons: false,
                timer: 1500,
            });
        }
        else {
            swal("Error!", "Delete Failed!", "error");
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCompany();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    modalClose = () => {
        this.setState({
            modalShow: false,
            modalAddShow: false,
            modalEditCamShow: false,
            modalAddCamShow: false,
        });
        this._isMounted = true;
        this.getCompany();
    }

    btnSearchBox = () => {
        this.setState({
            activePage: 1
        }, () => {
            this.getCompany();
            this.FilterDevice(1);
        });
    }


    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearchBox(event.target.value.trim())
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }

    changeHandleStatus = (e) => {
        this.setState({
            valueSelected: e,
            activePage: 1
        }, () => {
            this.getCompany();
        });
    }



    FilterDevice = (activePage) => {
    
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset,
            showFirst: offset + 1,
            showLast: this.state.listCompany.length + offset,
            totalLength: this.state.countData
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => {
              this.getCompany();
        })
      
    }


    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedCompany = this.state.listCompany.map((e, i) => {
            var checked = true;
            if (e.is_deleted === 0) {
                checked = true
            }
            if (e.is_deleted === 1) {
                checked = false
            }
            let features = JSON.parse(e.features)
            let featuresName = '';
            let expiredDay = ''
            if (features !== null) {
                featuresName = features.limit_staff;
                if (features.expired_day !== undefined && features.expired_day !== "") {
                    expiredDay = moment(features.expired_day).format("YYYY-MM-DD");
                }
            }

            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{e.code}</td>
                    <td>{e.name}</td>
                    <td>{e.phone}</td>
                    <td>{e.email}</td>
                    <td>{e.address}</td>
                    <td>{e.description}</td>
                    <td>{expiredDay}</td>
                    <td>{featuresName}</td>
                    <td>
                        
                    <Switch
                            onChange={() => {
                                if (e.is_deleted === 0) {
                                    swal({
                                        title: "Are you sure?",
                                        text: "Are you sure that you want to change mode" + e.name,
                                        icon: "warning",
                                        buttons: true
                                    }).then((isActive) => {
                                        if (!isActive) throw null;
                                        return fetch(`${HOST}/api/company/delete`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': Store.getState().isLogin.access_token
                                            },
                                            body: JSON.stringify({ 
                                                'id': e.id,
                                                'is_delete': 0,
                                             })
                                        }).then((response) => {
                                            return response.json()
                                        }).then((data) => {
                                            if (data.status === 10000) {
                                                e.is_deleted = 1;
                                                this.getCompany();
                                                swal("Success", "Change mode success!", "success", {
                                                    buttons: false,
                                                    timer: 1500,
                                                });
                                            } else {
                                                e.is_deleted = 0;
                                                swal("Error!", "Change mode failed!", "error");
                                            }
                                        })
                                            .catch((error) => {
                                                e.is_deleted = 0;
                                                swal("Error", error, "error");
                                            })
                                    })

                                } else {
                                    var crrCom = this.state.listCompany;
                                    var features
                                    if (crrCom[i].features === null) {
                                        features = {
                                            limit_staff: '1000',
                                            expired_day: "",
                                        }
                                    } else {
                                        features = JSON.parse(crrCom[i].features)
                                        if (features.expired_day === undefined) {
                                            features.expired_day = ""
                                        }
                                    }

                                    var id_company_parent = ""
                                    var level = []
                                    if (crrCom[i].level !== null) {
                                        level = crrCom[i].level.split('|')
                                    }

                                    if (level.length > 3) {
                                        var crrLv = level[level.length - 3];
                                        id_company_parent = crrLv
                                    } else {
                                        id_company_parent = level[0]
                                    }
                                    swal({
                                        title: "Are you sure?",
                                        text: "Are you sure that you want to change mode " + e.name,
                                        icon: "warning",
                                        buttons: true
                                    }).then((isActive) => {
                                        if (!isActive) throw null;
                                        return fetch(`${HOST}/api/company/update`, {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization': Store.getState().isLogin.access_token
                                            },
                                            body: JSON.stringify({
                                                id: crrCom[i].id,
                                                code: crrCom[i].code,
                                                name: crrCom[i].name,
                                                phone: crrCom[i].phone,
                                                features: features,
                                                email: crrCom[i].email,
                                                level: crrCom[i].level,
                                                levelDefault: crrCom[i].level,
                                                id_company_parent: id_company_parent,
                                                description: crrCom[i].description,
                                                address: crrCom[i].address,
                                                is_deleted: 0
                                            })
                                        }).then((response) => {
                                            return response.json()
                                        }).then((data) => {
                                            if (data.status === 10000) {
                                                e.is_deleted = 0;
                                                this.getCompany();
                                                swal("Success", "Change mode success!", "success", {
                                                    buttons: false,
                                                    timer: 1500,
                                                });
                                            } else if (data.status === 10002) {
                                                e.is_deleted = 1;
                                                swal("Error!", "Change mode failed!", "error");
                                            }
                                            else {
                                                e.is_deleted = 1;
                                                swal("Error!", "Change mode failed!", "error");
                                            }
                                        })
                                            .catch((error) => {
                                                e.is_deleted = 1;
                                                swal("Error!", error, "error");
                                            })
                                    })
                                }
                            }}
                            checked={checked}
                            handleDiameter={17}
                            offColor="#969696"
                            onColor="#00c5dc"
                            offHandleColor="#ffffff"
                            onHandleColor="#08f"
                            height={25}
                            width={55}
                            className="react-switch"
                            id="small-radius-switch"
                        />
                    </td>
                    <td>
                        <button onClick={(e) => {

                            var crrCom = this.state.listCompany;
                            var features
                            if (crrCom[i].features === null) {
                                features = {
                                    limit_staff: '1000',
                                    expired_day: "",
                                }
                            } else {
                                features = JSON.parse(crrCom[i].features)
                                if (features.expired_day === undefined) {
                                    features.expired_day = ""
                                }
                            }

                            var id_company_parent = ""
                            var level = []
                            if (crrCom[i].level !== null) {
                                level = crrCom[i].level.split('|')
                            }

                            if (level.length > 3) {
                                var crrLv = level[level.length - 3];
                                id_company_parent = crrLv
                            } else {
                                id_company_parent = level[0]
                            }

                            var com = {
                                id: crrCom[i].id,
                                code: crrCom[i].code,
                                name: crrCom[i].name,
                                phone: crrCom[i].phone,
                                features: features,
                                email: crrCom[i].email,
                                level: crrCom[i].level,
                                levelDefault: crrCom[i].level,
                                id_company_parent: id_company_parent,
                                description: crrCom[i].description,
                                address: crrCom[i].address
                            };

                            this.setState({
                                modalShow: true,
                                crrCompany: com,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for="Edit">
                            <i className="la la-edit" />
                        </button>
                        <ReactTooltip id='Edit' type='dark' effect='solid'>
                            <span>Edit</span>
                        </ReactTooltip>

                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for="Delete"
                            onClick={f => {
                                f.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete company " + e.name,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteCompany(e);
                                        }
                                    });
                            }}><i className="la la-trash"></i></button>
                        <ReactTooltip id='Delete' type='dark' effect='solid'>
                            <span>Delete</span>
                        </ReactTooltip>

                    </td>
                    
                </tr>
            )
        });

        return (

            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                  <div className="m-portlet" >
                    <div className="m-portlet__head" >
                        <div className="m-portlet__head-caption" id = "caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="fa flaticon-map" />
                                        </span>
                                    </span>
                                    Companies
                                </h3>
                            </div>
                        </div>
                        <div className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {
                                        var com = {
                                            code: "",
                                            name: "",
                                            phone: "",
                                            features: {
                                                limit_staff: '1000',
                                                expired_day: "",
                                            },
                                            email: "",
                                            level: "",
                                            description: "",
                                            address: ""
                                        };
                                        this.setState({
                                            modalShow: true,
                                            crrCompany: com,
                                        });
                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                            <ModalCompany
                                com={this.state.crrCompany}
                                optioncompany={this.state.AllCom}
                                show={this.state.modalShow}
                                onHide={this.modalClose}
                            />
                        </div>
                        </div>
                        <div className="row p-3 col-xl-10" id = "menuu">
                            <div id = "menu1" className="col-xl-2 pl-0 mb-3">
                                <Select
                                    placeholder={'Status'}
                                    value={this.state.valueSelected}
                                    onChange={this.changeHandleStatus}
                                    options={this.state.optionSelect}
                                />
                            </div>
                            <span id = "input5" className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Code/Name/Email/Phone/Address" data-col-index={0} />
                            </span>
                            <span id = "search">
                                <button
                                    onClick={(dataSearch) => {
                                        this.btnSearchBox(this.state.tempValue)
                                    }}
                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                    <span>
                                        <i className="la la-search" />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </span>
                            <span id = "reset" className="col-lg-3">
                                <button
                                    onClick={() => {
                                        this.reset()
                                    }}
                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                    <span>
                                        <i className="la la-remove" />
                                        <span>Reset</span>
                                    </span>
                                </button>
                            </span>
                        </div>
                      </div>
                    
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Code</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Name</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Phone</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Email</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Address</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>Description</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>Expired day</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Limit Person</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Status</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '120px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedCompany}
                                            </tbody>

                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span>
                                    <Pagination
                                        prevPageText='Previous'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.itemsPerPage}
                                        totalItemsCount={this.state.countData}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Company;

import React, { Component } from 'react';
import { logout } from '../../actions/isLogin';
import Store from '../../store';
import { HOST } from '../../config'
import { withRouter } from 'react-router';
import Select from "react-select";
import { setbox } from '../../actions/boxai';
import { Redirect } from 'react-router-dom';




class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            boxai_com: [],
            crrcompany: '0',
            isLogin: Store.getState().isLogin.isLogin,
        };
        this.getData();
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin
            }, () => {
                if (Store.getState().isLogin.isLogin && this.state.isLogin != Store.getState().isLogin.isLogin)
                    this.getData();
            })
        });
    }
    getData = () => {
        this.getUser();
        // this.getBoxAI();
    }

    getUser = () => {
        if (Store.getState().isLogin.isLogin == true) {
            fetch(`${HOST}/api/user/getByToken`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                }
            }).then((response) => {
                if (response.status == 401) {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                }
                return response.json();
            }).then((result) => {
                if (result.status == 10000) {
                    this.setState({ user: result.data })
                }
                else {
                    this.setState({
                        isLogin: false,
                        user: null
                    })
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        isLogin: false
                    })
                    localStorage.clear();
                    Store.dispatch(logout());
                }
            });
        }
    }

    componentWillReceiveProps() {
        var isLogin = Store.getState().isLogin.isLogin;

        if (this.state.isLogin != isLogin) {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                access_token: Store.getState().isLogin.access_token
            }, () => {
                this.getData();
            });
        }
    }

    // componentDidMount() {
    //     setInterval(() => {
    //         this.getData();
    //     }, 10000);
    // }


    render() {

        if (!this.state.isLogin) {
            return (
                <Redirect to={'/login'} />
            )
        }

        var options = [];
        for (var i = 0; i < this.state.boxai_com.length; i++) {
            options.push(
                { label: this.state.boxai_com[i].name, value: this.state.boxai_com[i].id })
        }
        var dislable;
        if (this.state.isLogin == false || this.props.location.pathname == "/login") {
            dislable = 'd-none';
        }

        return (
            <header id="m_header" className={"m-grid__item    m-header " + dislable} m-minimize-offset={200} m-minimize-mobile-offset={200}>
                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop">
                        {/* BEGIN: Brand */}
                        <div className="m-stack__item m-brand  m-brand--skin-dark d-block d-xl-none">
                            <div className="m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                                    {
                                        process.env.REACT_APP_HIDE_AVATAR == '0'
                                        &&
                                        <a href="/dashboard" className="m-brand__logo-wrapper">
                                            <img src="/img/logoai.png" alt="imglogo" className="col-xl-9 pl-0" />
                                        </a>
                                    }
                                </div>
                                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                    {/* BEGIN: Responsive Aside Left Menu Toggler */}
                                    <a href="javascript:;" id="m_aside_left_offcanvas_toggle" className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                                        <span />
                                    </a>
                                    {/* END */}
                                    {/* BEGIN: Topbar Toggler */}
                                    <a id="m_aside_header_topbar_mobile_toggle" href="javascript:;" className="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                                        <i className="flaticon-more" />
                                    </a>
                                    {/* BEGIN: Topbar Toggler */}
                                </div>
                            </div>
                        </div>
                        {/* END: Brand */}
                        <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                            <div id="m_header_menu" className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark ">
                                <ul className="m-menu__nav  m-menu__nav--submenu-arrow ">
                                    <li className="m-menu__item  m-menu__item--submenu m-menu__item--rel pl-2" m-menu-submenu-toggle="click" m-menu-link-redirect="1" aria-haspopup="true">
                                        {
                                            process.env.REACT_APP_HIDE_AVATAR == '0'
                                            &&
                                            <a href="/dashboard" className="m-brand__logo-wrapper">
                                                <img src="/img/BI_Logo.png" alt="imglogo" style={{ 'width': '150px', 'height': '50px' }} className="pl-0" />
                                            </a>
                                        }
                                    </li>
                                </ul>
                            </div>
                            {/* BEGIN: Topbar */}
                            <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid">
                                <div className="m-stack__item m-topbar__nav-wrapper">
                                    <ul className="m-topbar__nav m-nav m-nav--inline">
                                        <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light" m-dropdown-toggle="click">
                                            <a href="#" className="m-nav__link m-dropdown__toggle">

                                                <span className="m-topbar__userpic">
                                                    <img src="/assets/app/media/img/users/user4.jpg" className="m--img-rounded m--marginless m--img-centered" />
                                                </span>
                                            </a>
                                            <div className="m-dropdown__wrapper">
                                                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                                                <div className="m-dropdown__inner">
                                                    <div className="m-dropdown__header m--align-center" style={{ background: 'url(/assets/app/media/img/misc/user_profile_bg.jpg)', backgroundSize: 'cover' }}>
                                                        <div className="m-card-user m-card-user--skin-dark">
                                                            <div className="m-card-user__pic">
                                                                <img src="/assets/app/media/img/users/user4.jpg" className="m--img-rounded m--marginless" />
                                                            </div>
                                                            <div className="m-card-user__details">
                                                                <span className="m-card-user__name m--font-weight-500">{this.state.user == null ? "Guest" : this.state.user.first_name + " " + this.state.user.last_name}</span>
                                                                <a className="m-card-user__email m--font-weight-300 m-link">{this.state.user == null ? "" : this.state.user.email}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-dropdown__body">
                                                        <div className="m-dropdown__content">
                                                            <ul className="m-nav m-nav--skin-light">
                                                                <li className="m-nav__item text-right">
                                                                    <a className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder" onClick={() => {
                                                                        Store.dispatch(logout());
                                                                        localStorage.clear();
                                                                    }}>Logout</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* END: Topbar */}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
import React from 'react';
import './../css/App.css';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import ReactRouter from '../router/ReactRouter';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <ReactRouter />
      <Footer />
    </Router>
  );
}

export default App;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../../config';
import swal from 'sweetalert';
import Store from '../../../store';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Switch from "react-switch";
import $ from 'jquery';
const animatedComponents = makeAnimated();
const dataRule = [
    { value: 1, label: "Admin" },
    { value: 2, label: "User" },
    { value: 3, label: "Level 3" },
    { value: 4, label: "Level 4" },
    { value: 5, label: "Level 5" }
]

class ModalUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEdit: [],
            listCompany: [],
            userCompany: [],
            listCompanyDefault: [],
            user: {
                companies_id: "",
            },
            dataRule: dataRule,
            id_role: 2,
            isChecked: false
        }
    }

    UpdateUser = async (value, event) => {
        //Insert
        var form = $("#formUpdateUser")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (value.id == undefined) {
                let data = await fetch(`${HOST}/api/user/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(value),

                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Add User Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                }
                else if (data.status === 10001) {
                    swal("Error!", "Add User Failed, Email already exists!", "error");
                }
                else {
                    swal("Error!", "Add User Failed !", "error");
                }
            } else {
                let data = await fetch(`${HOST}/api/user/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(value)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit User Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                }
                else if (data.status === 10001) {
                    swal("Error!", "Edit User Failed, Email already exists!", "error");
                }
                else {
                    swal("Error!", "Edit User Failed !", "error");
                }
                //return data.status;
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var datacompany = [];
            for (let index = 0; index < nextProps.datacompany.length; index++) {
                datacompany.push({ 'value': nextProps.datacompany[index].id, 'label': nextProps.datacompany[index].name })
            }
            var dataComdefault = [];
            for (let index = 0; index < nextProps.dataedit.companies.length; index++) {
                dataComdefault.push({ 'value': nextProps.dataedit.companies[index].id, 'label': nextProps.dataedit.companies[index].name })
            }
            var listComval = '|';
            if (dataComdefault.length > 0) {
                for (let index = 0; index < dataComdefault.length; index++) {
                    listComval += dataComdefault[index].value + '|'
                }
            } else {
                listComval = '';
            }

            var datacheck;
            if (nextProps.dataedit.id === undefined) {
                datacheck = nextProps.dataedit
            } else {
                datacheck = {
                    companies_id: listComval,
                    id: nextProps.dataedit.id,
                    first_name: nextProps.dataedit.first_name,
                    last_name: nextProps.dataedit.last_name,
                    phone: nextProps.dataedit.phone,
                    email: nextProps.dataedit.email,
                    address: nextProps.dataedit.address,
                    avatar: nextProps.dataedit.avatar,
                    rule: nextProps.dataedit.rule,
                    active: nextProps.dataedit.active
                };
            }
            this.setState({
                listCompany: datacompany,
                listCompanyDefault: dataComdefault,
                dataEdit: datacheck
            });
        }
    }

    UserHandle(e) {
        var UserHan = this.state.dataEdit;
        if (e.target.name == 'password' || e.target.name == 'email' || e.target.name == "avatar") {
            UserHan[e.target.name] = e.target.value.trim();
        } else {
            UserHan[e.target.name] = e.target.value
        }
        this.setState({ dataEdit: UserHan });
    }

    handleChangeCompany(valueCom) {
        var UserHan = this.state.dataEdit;
        var listComval = '|';
        if (valueCom == null) {
            listComval = ''
        } else {
            for (let index = 0; index < valueCom.length; index++) {
                listComval += valueCom[index].value + '|'
            }
        }
        UserHan['companies_id'] = listComval;
        this.setState({
            dataEdit: UserHan
        })
    }

    handleChange(value) {
        var dataEdit = this.state.dataEdit;
        dataEdit['rule'] = value.value;
        this.setState({
            id_role: value.value,
            dataEdit: dataEdit
        })
    }

    render() {
        let click_handle = (event) => {
            this.UpdateUser(this.state.dataEdit, event);
        }
        
        if (this.state.dataEdit.active === 1) {
            this.state.isChecked = true
        }
        if (this.state.dataEdit.active === 0) {
            this.state.isChecked = false
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateUser">
                        <div className="col-xl-12">
                            <div className="m-widget14 row">

                                <div className="form-group m-form__group col-xl-6 p-0">
                                    <label htmlFor="FirstName">First Name <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="FirstName" name='first_name' onChange={e => this.UserHandle(e)} placeholder="First Name"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.first_name} required />
                                </div>
                                <div className="form-group m-form__group col-xl-6 pr-0 pt-0 pb-0">
                                    <label htmlFor="LastName">Last Name <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="LastName" name='last_name' onChange={e => this.UserHandle(e)} placeholder="Last Name"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.last_name} required />
                                </div>
                                {
                                    this.state.dataEdit.id === undefined
                                        ?
                                        <div className="form-group m-form__group col-xl-12 p-0">
                                            <label htmlFor="Password">Password <span class="text-danger">* </span></label>
                                            <input type="password" minLength={6} className="form-control m-input" id="Password" name='password' onChange={e => this.UserHandle(e)} placeholder="Password"
                                                value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.password} required />
                                        </div>
                                        :
                                        ''
                                }
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Phone">Phone</label>
                                    <input className="form-control m-input" id="Phone" pattern='[0-9]{9,13}' name='phone' onChange={e => this.UserHandle(e)} placeholder="Phone"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.phone} />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Email">Email <span class="text-danger">*</span> </label>
                                    <input className="form-control m-input" id="Email" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}" name='email' onChange={e => this.UserHandle(e)} placeholder="Email"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.email} required />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0"> 
                                    <label htmlFor="Address">Address</label>
                                    <input className="form-control m-input" id="Address" name='address' onChange={e => this.UserHandle(e)} placeholder="Address"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.address} />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Avatar">Avatar</label>
                                    <input className="form-control m-input" id="avatar" name='avatar' onChange={e => this.UserHandle(e)} placeholder="Link Avatar"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.avatar} />
                                </div>
                                {
                                    this.state.dataEdit.id === undefined
                                        ?
                                        <div className="form-group m-form__group col-xl-12 p-0"  >
                                            <label htmlFor="Port_Local">Rule <span class="text-danger">* </span></label>
                                            <Select
                                                onChange={value => this.handleChange(value)}
                                                value={
                                                    this.state.dataRule.filter((option) => {
                                                        return this.state.id_role === option.value
                                                    })
                                                }
                                                options={this.state.dataRule}
                                            />
                                        </div>
                                        :
                                        <div className="form-group m-form__group col-xl-12 p-0"  >
                                            <label htmlFor="Port_Local">Rule <span class="text-danger">* </span></label>
                                            <Select
                                                onChange={value => this.handleChange(value)}
                                                value={
                                                    this.state.dataRule.filter((option) => {
                                                        return +this.state.dataEdit.rule === option.value
                                                    })
                                                }
                                                options={this.state.dataRule}
                                            />
                                        </div>
                                }

                                <div className="form-group m-form__group">
                                    <label htmlFor="Status">Active</label>
                                    <div className="pt-2 pb-2">
                                        <Switch
                                            onChange={() => {
                                                var active;
                                                if (!this.state.isChecked == true) {
                                                    active = 1
                                                } else {
                                                    active = 0
                                                }
                                                var dataEdit = this.state.dataEdit;
                                                dataEdit['active'] = active;
                                                this.setState({
                                                    dataEdit: dataEdit,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>
                                </div>

                                <div className="form-group m-form__group col-xl-12 p-0"  >
                                    <label>Companies</label>
                                    <Select
                                        onChange={valueCom => this.handleChangeCompany(valueCom)}
                                        options={this.state.listCompany}
                                        isMulti
                                        defaultValue={this.state.listCompanyDefault}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalUser.propTypes = {
    datacompany: PropTypes.array,
    dataedit: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}


export default ModalUser;
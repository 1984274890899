import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../../config';
import swal from 'sweetalert';
import Store from '../../../store';
import $ from 'jquery';
import Select from "react-select";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'


class ModalCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            optionCompany: [],
            listOptionModules: [],
            listOptionVersion: [],
            show: this.props.show,
            // dataLicense: {
            //     "id_company": null,
            //     "id_module": null,
            //     "name_module": "",
            //     "active": 1,
            //     "created_user": null
            // },
            dataUser: null,
            id_company: null,
            disabled: false,
            crrLicense: [],
            isChecked: true,
            indexAppend: false,
            sampleModule: '',
            version: null
            // expiredDate: new Date()
        }
    }

    UpdateSampleModule = async (com, event) => {
        var form = $("#formSampleModule")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        }
        else {
            let data = await fetch(`${HOST}/api/license/save_sample_module`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    user_id: this.state.dataUser,
                    id_company: this.state.company.id,
                    version: this.state.version.value,
                })
            }).then((response) => {
                return response.json()
            });
            if (data.status === 10000) {
                swal("Sucess!", "Add Sample Module Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.licenseGetByIdCompany()

                // return this.props.onHide();
            }
            else {
                swal("Error!", "Add Sample Module Failed!", "error");
            }
            return data.status;
        }

    }



    onChangeSampleModule(e) {
        this.setState({
            version: e.target.value
        })

    }

    UpdateCompany = async (com, event) => {
        //Insert
        var form = $("#formUpdateCompany")

        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (com.id == undefined) {
                let data = await fetch(`${HOST}/api/company/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(com)
                }).then((response) => {
                    return response.json()
                });

                if (data.status === 10000) {


                    var lstcom = this.state.company;
                    lstcom['id'] = data.data.id;
                    this.setState({
                        company: lstcom
                    });
                    swal("Sucess!", "Add Company Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.licenseGetByIdCompany()
                    // return this.props.onHide();
                }

                else if (data.status === 10001) {
                    swal("Warning!", "Company code already exists!", "warning");
                }
                else {
                    swal("Error!", "Add Company Failed!", "error");
                }
                return data.status;

            } else {
                let data = await fetch(`${HOST}/api/company/update`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(com)
                }).then((response) => {
                    return (
                        response.json()
                    )
                });
                if (data.status === 10000) {

                    swal("Success!", "Edit Company Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    // return this.props.onHide();
                }
                else if (data.status === 10001) {
                    swal("Warning!", "Company code already exists!", "warning");
                }
                else {
                    swal("Error!", "Edit Company Failed!", "error");
                }

            }
        }
    }

    UpdateLicense = async (paramLicense, event, index) => {
        //Insert
        if (paramLicense.id_module !== null) {
            if (paramLicense.id === undefined) {
                let data = await fetch(`${HOST}/api/license/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramLicense)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Add License Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    var crrLicense = this.state.crrLicense;
                    var company = this.state.company;

                    const labelModule = this.state.listOptionModules.filter((option) => {
                        return crrLicense[index].id_module === option.value
                    })
                    crrLicense[index].edit = false;
                    crrLicense[index]['id'] = data.data.id;
                    crrLicense[index]['id_company'] = data.data.id_company;
                    if (labelModule[0] !== undefined) {
                        crrLicense[index]['module'].code = labelModule[0].label;
                    }
                    company['id'] = data.data.id_company;

                    this.setState({
                        crrLicense: crrLicense,
                        company: company,
                        indexAppend: false
                    }, () => {
                        this.getModules()
                    })
                }
                else if (data.status === 10001) {
                    swal("Warning!", "Module is exist!", "warning");
                } else {
                    swal("Error!", "Add License Failed!", "error");
                }
            } else {
                let data = await fetch(`${HOST}/api/license/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramLicense)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit License Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    var crrLicense = this.state.crrLicense;
                    var company = this.state.company;

                    const labelModule = this.state.listOptionModules.filter((option) => {
                        return crrLicense[index].id_module === option.value
                    })
                    crrLicense[index].edit = false;
                    crrLicense[index]['id'] = data.data.id;
                    crrLicense[index]['id_company'] = data.data.id_company;
                    if (labelModule[0] !== undefined) {
                        crrLicense[index]['module'].code = labelModule[0].label;
                    }
                    company['id'] = data.data.id_company;

                    this.setState({
                        crrLicense: crrLicense,
                        company: company,
                        indexAppend: false
                    }, () => {
                        this.getModules()
                    })
                }
                else if (data.status === 10001) {
                    swal("Warning!", "Module is exist!", "warning");
                }
                else {
                    swal("Error!", "Edit License Failed!", "error");
                }
            }
        } else {
            swal("Warning!", "You have not selected the module!", "warning");
        }
    }

    getModules = () => {
        fetch(`${HOST}/api/module/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption;
                var modulesOption = [];

                if (this.state.crrLicense.length > 0) {
                    for (let index = 0; index < this.state.crrLicense.length; index++) {
                        for (let i = 0; i < data.data.length; i++) {
                            if (this.state.crrLicense[index].module !== null) {
                                if (this.state.crrLicense[index].module.code == data.data[i].code) {
                                    data.data.splice(i, 1)
                                    i--
                                }
                            }
                        }
                    }
                }


                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.code };
                    modulesOption.push(dataOption);
                });
                this.setState({
                    listOptionModules: modulesOption,
                });
            }
        })
    }

    getUser = () => {
        fetch(`${HOST}/api/user/getByToken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    dataUser: data.data.id
                })
            }
        })
    }

    getModules = () => {
        fetch(`${HOST}/api/module/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                let allVersion = data.data.map((e) => e.version)
                let filterVersion = new Set(allVersion)
                let version = [...filterVersion]
                let listVer = []
                version.map(a => {
                    listVer.push({ value: a, label: a })
                })
                this.setState({
                    listOptionVersion: listVer,
                });
            }
        })
    }

    licenseGetByIdCompany = () => {
        if (this.state.company.id === undefined) return
        fetch(`${HOST}/api/license/${this.state.company.id}/get_all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    data.data[index].activeDefault = data.data[index].active
                }
                this.setState({
                    crrLicense: data.data
                }, () => {
                    this.getModules()
                })
            }
        })
    }

    getAllCompany = () => {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: -1,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption = []
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].code == null) {
                        dataOption.push({value: data.data[i].id, label: data.data[i].name, level: data.data[i].level});
                    } else {
                        dataOption.push({value: data.data[i].id, label: data.data[i].name + "  (" + data.data[i].code + ")", level: data.data[i].level});
                    }
                }
                this.setState({
                    optionCompany: dataOption
                });
            }
        })
    }

    appendModel = () => {
        var crrLicense = this.state.crrLicense;
        var data = { id_module: null, active: 1, activeDefault: 1, id_company: this.state.company.id, created_user: this.state.dataUser, module: { code: null }, edit: true }
        crrLicense.push(data);
        this.setState({
            crrLicense: crrLicense,
            indexAppend: true
        })
    }


    companyHandle(e) {
        var companyHan = this.state.company;
        if (e.target.name == 'code' || e.target.name == 'email') {
            companyHan[e.target.name] = e.target.value.trim();
        } else {
            companyHan[e.target.name] = e.target.value
        }

        this.setState({ company: companyHan });
    }


    handleExpiredDate = crrExpiredDday => {
        var timeHandle = this.state.company
        timeHandle.features['expired_day'] = moment(crrExpiredDday).format("YYYY-MM-DD HH:mm:ss Z");
        this.setState({
            company: timeHandle,
            crrExpiredDday: crrExpiredDday
        })

    }

    handleLimitPerson(e) {
        var companyHan = this.state.company;
        companyHan.features.limit_staff = e.target.value

        this.setState({ company: companyHan });

    }

    handleChangeModules(value, index) {
        var crrLicense = this.state.crrLicense;
        crrLicense[index]['id_module'] = value.value;
        this.setState({
            crrLicense: crrLicense,
        })
    }

    handleChangeSampleModules(value, index) {
        this.setState({
            version: value
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            // var companyOption = [];
            // var dataOption
            // nextProps.optioncompany.forEach(e => {
            //     if (e.code == null) {
            //         dataOption = { value: e.id, label: e.name, level: e.level };
            //     } else {
            //         dataOption = { value: e.id, label: e.name + "  (" + e.code + ")", level: e.level };
            //     }
            //     companyOption.push(dataOption);
            // });

            this.setState({
                company: nextProps.com,
                // optionCompany: companyOption,
                indexAppend: false,
                version: null,
                crrLicense: [],
                crrExpiredDday: nextProps.com.features.expired_day,
            }, () => {
                this.getAllCompany()
                this.getUser()
                this.getModules()
                this.licenseGetByIdCompany()
            });
        }
    }

    handleChange(value) {
        var { company } = this.state;
        company['id_company_parent'] = value.value;
        if (company['id'] == value.value) {
            company['level'] = ""
        } else {
            company['level'] = value.level
        }

        this.setState({
            company
        })
    }



    render() {
        let click_handle = (event) => {
            this.UpdateCompany(this.state.company, event);
        }

        let click_handle_module = (e) => {
            this.UpdateSampleModule(this.state.company, e)
        }

        let bulletedLecense = this.state.crrLicense.map((e, i) => {
            var check = true
            if (e.active === 1) {
                check = true
            } else {
                check = false
            }
            return (
                <tr key={(i + 1)}>
                    <td>
                        {
                            e.edit === true
                                ?
                                <Select
                                    options={this.state.listOptionModules}
                                    value={
                                        this.state.listOptionModules.filter((option) => {
                                            return e.id_module === option.value
                                        })
                                    }
                                    onChange={value => this.handleChangeModules(value, i)}
                                    placeholder="Select Module"
                                />
                                :
                                <div>{e.module !== null ? e.module.code : ''}</div>
                        }
                    </td>
                    <td>
                        {
                            e.edit === true
                                ?
                                <Switch
                                    onChange={() => {
                                        var active;
                                        if (e.active === 0) {
                                            active = 1
                                        } else {
                                            active = 0
                                        }

                                        var crrLicense = this.state.crrLicense;
                                        crrLicense[i].active = active

                                        this.setState({
                                            crrLicense: crrLicense,
                                            isChecked: !this.state.isChecked
                                        });
                                    }}
                                    checked={check}
                                    handleDiameter={17}
                                    offColor="#969696"
                                    onColor="#00c5dc"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#08f"
                                    height={25}
                                    width={55}
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                                :
                                e.active === 0
                                    ?
                                    <span className="m-badge  m-badge--secondary m-badge--wide">Inactive</span>
                                    :
                                    <span className="m-badge  m-badge--accent m-badge--wide">Active</span>
                        }

                    </td>
                    <td>
                        {
                            e.edit === true
                                ?
                                <>
                                    <button onClick={(event) => {
                                        event.preventDefault()
                                        this.UpdateLicense(e, event, i);
                                    }} className="m-portlet__nav-link btn m-btn m-btn--hover-success m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                                        <i className="la la-check" />
                                    </button>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            var crrLicense = this.state.crrLicense;
                                            var listOptionModules = this.state.listOptionModules;
                                            crrLicense[i].edit = false
                                            crrLicense[i].active = crrLicense[i].activeDefault
                                            var indexAppend = this.state.indexAppend
                                            if (e.module.code === null) {
                                                crrLicense.splice(i, 1)
                                                indexAppend = false
                                            } else {
                                                for (let index = 0; index < listOptionModules.length; index++) {
                                                    if (listOptionModules[index].value === e.id_module) {
                                                        listOptionModules.splice(index, 1)
                                                    }
                                                }
                                            }


                                            this.setState({
                                                crrLicense: crrLicense,
                                                listOptionModules: listOptionModules,
                                                indexAppend: indexAppend
                                            })
                                        }}
                                        className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="close"><b><i className="la la-remove"></i></b></button>
                                </>
                                :
                                <>
                                    <button onClick={(event) => {
                                        event.preventDefault()
                                        var crrLicense = this.state.crrLicense;
                                        var listOptionModules = this.state.listOptionModules;
                                        crrLicense[i].edit = true
                                        listOptionModules.unshift({ value: e.id_module, label: e.module.code });
                                        this.setState({
                                            crrLicense: crrLicense,
                                            listOptionModules: listOptionModules
                                        })
                                    }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                                        <i className="la la-edit" />
                                    </button>
                                    {/* <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.DeleteOperation(e, event, i)
                                        }}
                                        className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"><b><i className="la la-remove"></i></b></button> */}
                                </>
                        }
                    </td>
                </tr>
            )
        });

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <form id="formUpdateCompany">
                        <div className="col-xl-12">
                            <div className="m-widget14 p-1 row col-md-12 p-0 m-0">
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="CompanyName">Company ID</label>
                                    <input className="form-control m-input" id="CompanyCode" name='code' onChange={e => this.companyHandle(e)}
                                        value={this.state.company == null ? '' : this.state.company.id} disabled />
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="CompanyCode">Company Code <span className="text-danger">* </span></label>
                                    <input className="form-control m-input" id="CompanyCode" name='code' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Code" value={this.state.company == null ? '' : this.state.company.code} required />
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="CompanyName">Company Name <span className="text-danger">* </span></label>
                                    <input className="form-control m-input" id="CompanyName" name='name' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Name" value={this.state.company == null ? '' : this.state.company.name} required />
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="CompanyPhone">Company Phone</label>
                                    <input className="form-control m-input" id="CompanyPhone" pattern='[0-9]{9,13}' name='phone' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Phone" value={this.state.company == null ? '' : this.state.company.phone} />
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="CompanyEmail">Company Email <span className="text-danger">* </span></label>
                                    <input className="form-control m-input" type="text" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}" id="CompanyEmail" name='email' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Email" value={this.state.company == null ? '' : this.state.company.email} required />
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="CompanyAddress">Company Address</label>
                                    <input className="form-control m-input" id="CompanyAddress" name='address' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Address" value={this.state.company == null ? '' : this.state.company.address} />
                                </div>

                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="CompanyDescription">Description</label>
                                    <textarea rows="4" className="form-control m-input" id="CompanyDescription" name='description' onChange={e => this.companyHandle(e)}
                                        placeholder="Company Description" value={this.state.company == null ? '' : this.state.company.description} />
                                </div>

                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="CompanyParent">Company Parent</label>
                                    <Select
                                        options={this.state.optionCompany}
                                        value={
                                            this.state.optionCompany.filter((option) => {
                                                return this.state.company.id_company_parent == option.value
                                            })
                                        }
                                        onChange={value => this.handleChange(value)}
                                    />
                                </div>

                                {/* <p>License</p> */}
                                {/* <h2 className="divider line one-line" contenteditable>license</h2> */}


                                <div className="form-group m-form__group col-md-12 pl-0 pr-0">
                                    
                                    <h2 className="divider line one-line" contenteditable>License</h2>
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="limitPerson">Limit Person</label>
                                    <input type="number" className="form-control m-input" id="limitPerson" name='limit_staff' onChange={e => this.handleLimitPerson(e)}
                                        placeholder="Limit Person" value={this.state.company == null ? '' : this.state.company.features.limit_staff} />
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0"  >
                                    <label>Expired Date</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            onChange={this.handleExpiredDate}
                                            // selected={this.state.company === null ? "" : this.state.company.features.expired_day === "" ? "" : new Date(this.state.company.features.expired_day)}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select Date"
                                            selected={this.state.crrExpiredDday === null ? "" : this.state.crrExpiredDday === "" ? "" : new Date(this.state.crrExpiredDday)}
                                        />

                                    </div>

                                </div>

                                <div className="m-auto pb-3 pt-2">
                                    <Button variant="accent" onClick={click_handle}>Save Company</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {
                        this.state.company !== null && this.state.company.id !== undefined
                            ?
                            <div className="col-md-12 p-0">
                                <form id="formSampleModule">
                                    <div className="col-md-12 p-0 mt-4">
                                    <div className="m-widget14 p-1 row col-md-12 p-0 m-0">
                                    <div className="form-group m-form__group col-md-6 pr-0"></div>
                                    <div className="form-group m-form__group col-md-6 pr-0">
                                        <div className="row d-flex justify-content-between">
                                            <Select
                                                options={this.state.listOptionVersion}
                                                value={this.state.version}
                                                className="w-50"
                                                onChange={value => this.handleChangeSampleModules(value)}
                                                placeholder="Select Module"
                                            />
                                            <Button className="btn btn-accent m-btn m-btn--icon mr-2"  variant="accent" onClick={click_handle_module}>Save Sample Module</Button>
                                        </div>
                                    </div>
                                    </div>
                                  
                                    </div>
                                </form>

                                <br />
                                {/* <div class="input-group mb-3">
                                                <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary" type="button">Button</button>
                                                    </div>
                                            </div> */}

                                <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                    <thead>
                                        <tr>
                                            <th style={{ 'verticalAlign': 'middle' }}>Modules</th>
                                            {/* <th style={{ 'verticalAlign': 'middle' }}>User Created</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>User Updated</th> */}
                                            <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Status</th>
                                            <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>
                                                Action
                                                            <button
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        if (this.state.indexAppend === true) {
                                                            swal("Warning!", "You have not saved the operation!", "warning");
                                                        } else {
                                                            this.appendModel()
                                                        }
                                                    }}
                                                    style={{ 'height': '20px', 'width': '22px', 'marginLeft': '5px' }} className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only pull-right">
                                                    <i className="la la-plus"></i>
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bulletedLecense}
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''

                    }




                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalCompany.propTypes = {
    com: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    //nextProps: PropTypes.array,
}

export default ModalCompany;
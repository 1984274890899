import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';
import Select from "react-select";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Pagination from "react-js-pagination";
import Switch from "react-switch";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ModalTypeDevice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOptionTypeDevice: [],
            dataModules: {
                "id_typedevice": null,
                "code": null,
                "version": null,
                "name_typedevice": ""
            },
            disabled: false,
            crrOperation: [],
            id_module: null,
            valueCode: '',
            valueLevel: '',
            valueActive: '',
            isChecked: true,
            indexAppend: false,
        }
    }

    UpdateModules = async (paramModules, event) => {
        //Insert
        var form = $("#formAddTypeDevice")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (paramModules.id_typedevice !== null) {
                paramModules.code = this.state.dataModules.name_typedevice.toLowerCase() + '/' + paramModules.code
                this.setState({ disabled: true })
                if (paramModules.id === undefined) {
                    let data = await fetch(`${HOST}/api/module/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(paramModules)
                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {
                        swal("Sucess!", "Add Type Device Success!", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        var typeDevice = ""
                        if (data.data.type_device !== null) {
                            typeDevice = data.data.type_device.name
                        }
                        data.data.name_typedevice = typeDevice

                        var code = data.data.code.split('/')

                        data.data.code = code[1]
                        this.setState({
                            disabled: false,
                            dataModules: data.data
                        })
                    }
                    else {
                        this.setState({
                            disabled: false
                        })
                        swal("Error!", "Add Type Device Failed!", "error");
                    }
                } else {
                    let data = await fetch(`${HOST}/api/module/insert_or_update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(paramModules)
                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {
                        swal("Sucess!", "Edit Type Device Success!", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        var typeDevice = ""
                        if (data.data.type_device !== null) {
                            typeDevice = data.data.type_device.name
                        }
                        var code = data.data.code.split('/')

                        data.data.code = code[1]
                        data.data.name_typedevice = typeDevice
                        this.setState({
                            disabled: false,
                            dataModules: data.data
                        })
                    }
                    else {
                        swal("Error!", "Edit Type Device Failed!", "error");
                        this.setState({
                            disabled: false
                        })
                    }
                }
            } else {
                swal("Warning!", "You have not selected the type!", "warning");
            }
        }
    }

    UpdateOperation = async (paramOperation, event, index) => {
        //Insert
        var form = $("#form_update_code_operation")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            paramOperation.code = this.state.dataModules.name_typedevice.toLowerCase() + '/' + this.state.dataModules.code + ':' + paramOperation.code
            if (paramOperation.id === undefined) {
                let data = await fetch(`${HOST}/api/operation/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramOperation)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Add Operation Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    var crrOperation = this.state.crrOperation;
                    crrOperation[index].edit = false;
                    crrOperation[index]['id'] = data.data.id;

                    var code = crrOperation[index]['code'].split(':')
                    crrOperation[index]['code'] = code[1];

                    this.setState({
                        crrOperation: crrOperation,
                        indexAppend: false
                    })
                }
                else {
                    swal("Error!", "Add Operation Failed!", "error");
                }
            } else {
                let data = await fetch(`${HOST}/api/operation/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramOperation)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit Operation Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    var crrOperation = this.state.crrOperation;
                    crrOperation[index].edit = false
                    this.setState({
                        crrOperation: crrOperation,
                        indexAppend: false
                    })
                }
                else {
                    swal("Error!", "Edit Operation Failed!", "error");
                }
            }
        }
    }

    modulesHandle(e) {
        var modulesHandle = this.state.dataModules;
        modulesHandle[e.target.name] = e.target.value.trim();
        this.setState({ dataModules: modulesHandle });
    }

    handleChangeTypeDevice(value) {
        var crrModules = this.state.dataModules;
        crrModules['id_typedevice'] = value.value;
        crrModules['name_typedevice'] = value.label;
        this.setState({
            dataModules: crrModules
        })
    }

    getTypeDevice = () => {
        fetch(`${HOST}/api/type_device/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption;
                var typeDeviceOption = [];
                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.name };
                    typeDeviceOption.push(dataOption);
                });
                this.setState({
                    listOptionTypeDevice: typeDeviceOption,
                });
            }
        })
    }

    getOperation = () => {
        fetch(`${HOST}/api/operation/get_by_id_module/${this.state.dataModules.id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    data.data[index]['edit'] = false;
                    var code = data.data[index]['code'].split(':')
                    data.data[index]['code'] = code[1];
                }


                this.setState({
                    crrOperation: data.data,
                });
            } else {
                this.setState({
                    crrOperation: []
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    crrOperation: []
                })
            }
        })
    }

    DeleteOperation = async (data, i) => {
        await fetch(`${HOST}/api/operation/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': data.id
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                swal("Deleted!", "You have successfully deleted!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.getOperation();
            }
            else {
                swal("Error!", "Delete Failed!", "error");
            }
        });
    }

    appendModel = () => {
        var crrOperation = this.state.crrOperation;
        var data = { code: '', level: 1, active: 1, id_module: this.state.dataModules.id, edit: true }
        crrOperation.push(data);
        this.setState({
            crrOperation: crrOperation,
            indexAppend: true
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {


            var code = nextProps.crrmodules.code.split('/')

            nextProps.crrmodules.code = code[1]


            this.setState({
                dataModules: nextProps.crrmodules,
                indexAppend: false,
                crrOperation: []
            }, () => {
                this.getTypeDevice();
                this.getOperation()
            });
        }
    }

    render() {
        let click_handle = (event) => {
            this.UpdateModules(this.state.dataModules, event);
        }

        console.log(this.state.dataModules)

        let bulletedOperation = this.state.crrOperation.map((e, i) => {
            var check = true
            if (e.active === 1) {
                check = true
            } else {
                check = false
            }
            return (
                <tr key={(i + 1)}>
                    <td>
                        {
                            e.edit === true
                                ?
                                <form className="form-group m-form__group" id="form_update_code_operation">
                                    <div className="input-group">
                                        <div className="input-group-prepend mr-0">
                                            <button className="btn btn-secondary">{this.state.dataModules.name_typedevice.toLowerCase()}/{this.state.dataModules.code}:</button>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter code..." value={e.code}
                                            onChange={(value) => {
                                                var crrOperation = this.state.crrOperation;
                                                crrOperation[i].code = value.target.value.trim()
                                                this.setState({
                                                    crrOperation: crrOperation
                                                })
                                            }} required />
                                    </div>
                                </form>
                                :
                                <div>{this.state.dataModules.name_typedevice.toLowerCase() + '/' + this.state.dataModules.code + ':' + e.code}</div>
                        }
                    </td>
                    {/* <td>
                        {
                            e.edit === true
                                ?
                                <input type="number" className="form-control" placeholder="Enter level..." value={e.level}
                                    onChange={(value) => {
                                        var crrOperation = this.state.crrOperation;
                                        crrOperation[i].level = value.target.value
                                        this.setState({
                                            crrOperation: crrOperation
                                        })
                                    }} required />
                                :
                                e.level
                        }
                    </td> */}
                    <td>
                        {
                            e.edit === true
                                ?
                                <Switch
                                    onChange={() => {
                                        var active;
                                        if (e.active === 0) {
                                            active = 1
                                        } else {
                                            active = 0
                                        }

                                        var crrOperation = this.state.crrOperation;
                                        crrOperation[i].active = active

                                        this.setState({
                                            crrOperation: crrOperation,
                                            isChecked: !this.state.isChecked
                                        });
                                    }}
                                    checked={check}
                                    handleDiameter={17}
                                    offColor="#969696"
                                    onColor="#00c5dc"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#08f"
                                    height={25}
                                    width={55}
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                                :
                                e.active === 0
                                    ?
                                    <span className="m-badge  m-badge--secondary m-badge--wide">Inactive</span>
                                    :
                                    <span className="m-badge  m-badge--accent m-badge--wide">Active</span>
                        }

                    </td>
                    <td>
                        {
                            e.edit === true
                                ?
                                <button onClick={(event) => {
                                    event.preventDefault()
                                    this.UpdateOperation(e, event, i)
                                }} className="m-portlet__nav-link btn m-btn m-btn--hover-success m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                                    <i className="la la-check" />
                                </button>
                                :
                                <>
                                    {/* <button onClick={(event) => {
                                        event.preventDefault()
                                        var crrOperation = this.state.crrOperation;
                                        crrOperation[i].edit = true
                                        this.setState({
                                            crrOperation: crrOperation
                                        })
                                    }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                                        <i className="la la-edit" />
                                    </button> */}
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.DeleteOperation(e, event, i)
                                        }}
                                        className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"><b><i className="la la-remove"></i></b></button>
                                </>
                        }
                    </td>
                </tr>
            )
        });


        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddTypeDevice">
                        <div className="col-xl-12">
                            <div className="m-widget14 col-md-12 row">
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="ID">ID Module</label>
                                    <div>
                                        {this.state.dataModules.id}
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="Type">Type <span class="text-danger">* </span></label>
                                    <Select
                                        options={this.state.listOptionTypeDevice}
                                        value={
                                            this.state.listOptionTypeDevice.filter((option) => {
                                                return this.state.dataModules.id_typedevice === option.value
                                            })
                                        }
                                        onChange={value => this.handleChangeTypeDevice(value)}
                                        placeholder="Select Type"
                                        isDisabled={this.state.dataModules.id === undefined ? false : true}
                                    />
                                </div>
                                <div className="form-group m-form__group col-md-6 pl-0">
                                    <label htmlFor="Code">Code Module <span class="text-danger">* </span></label>
                                    <div className="input-group">
                                        <div className="input-group-prepend mr-0">
                                            <button className="btn btn-secondary">{this.state.dataModules.name_typedevice.toLowerCase()}/</button>
                                        </div>
                                        <input className="form-control m-input" id="Code" name='code' disabled={this.state.dataModules.id === undefined ? false : true} value={this.state.dataModules.code} onChange={e => this.modulesHandle(e)} placeholder="Enter code..." required />
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-md-6 pr-0">
                                    <label htmlFor="Version">Version <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="Version" name='version' value={this.state.dataModules.version} onChange={e => this.modulesHandle(e)} placeholder="Enter version..." required/>
                                </div>
                                <div className="m-auto p-4">
                                    <Button variant="accent" onClick={click_handle} className={this.state.disabled === true ? 'm-loader--light m-loader--right m-loader' : ''} disabled={this.state.disabled}>Save</Button>
                                </div>
                                {
                                    this.state.dataModules.id !== undefined
                                        ?
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Code Operation</th>
                                                        {/* <th style={{ 'verticalAlign': 'middle' }}>Level</th> */}
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Status</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>
                                                            Action
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    if (this.state.indexAppend === true) {
                                                                        swal("Warning!", "You have not saved the operation!", "warning");
                                                                    } else {
                                                                        this.appendModel()
                                                                    }
                                                                }}
                                                                style={{ 'height': '20px', 'width': '22px', 'marginLeft': '5px' }} className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only pull-right">
                                                                <i className="la la-plus"></i>
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedOperation}
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalTypeDevice.propTypes = {
    crrmodules: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalTypeDevice;
import React, { Component } from 'react'
import QRCode from 'qrcode.react';
import { HOST } from '../../config';
import Store from '../../store';
import ReactToPrint from 'react-to-print';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import SelectAllAutocomplete from "./SelectAllOption";

const animatedComponents = makeAnimated();


class ComponentToPrint extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const listQRcode = this.props.listQRcode.map((value, index) => {
            return (
                <div style={{ width: 200 }} key={index}>
                    <QRCode
                        id='qrcode'
                        value={value}
                        size={200}
                        level={'M'}
                        includeMargin={true}
                    />
                    <div className="text-center"><b>{value}</b></div>
                </div>
            )
        })
        return (
            <div className="row col-md-12">
                {listQRcode}
            </div>
        )
    }
}
class Qrcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBox: [],
            crrBox: [],
            listTypeDevice: [],
            selectedOptions: [],
        }
    }

    componentDidMount() {
        this.getTypeDevice()
    }

    handleChangeTypeDevice = (value) => {
        fetch(`${HOST}/api/box/getByDevice/${value.value}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                const dataCode = data.data.filter((option) => {
                    for (let index = 0; index < option.box_engines.length; index++) {
                        return option.box_engines[index].box.mac_address !== null
                    }
                })
                var optionBox = [];
                for (let index = 0; index < dataCode.length; index++) {
                    for (let j = 0; j < dataCode[index].box_engines.length; j++) {
                        const newSerial = dataCode[index].box_engines[j].box.mac_address.replaceAll(":", "")
                        optionBox.push({ 'value': dataCode[index].box_engines[j].id, 'label': newSerial+ '_' + dataCode[index].box_engines[j].id})
                    }
                }

                this.setState({
                    listBox: optionBox,
                });
            }
        })
    }

    changeHandleBox(e) {
        console.log(this.state.listBox)
        // var crrBox = this.state.crrBox;
        var dataBox = []
        if (e == null) {
            dataBox = []
        } else {
            for (let index = 0; index < e.length; index++) {
                dataBox.push(e[index].label)
            }
        }
        this.setState({
            crrBox: dataBox
        })
    }

    // handleSelectAll = (isSelected) => {
    //     console.log(isSelected)
    //     if (isSelected) {
    //         this.setState({
    //             selectedOptions: this.state.listBox
    //         })
    //     } else {
    //         this.handleClearOptions()
    //     }
    // };


    // handleToggleSelectAll = () => {
    //     this.handleSelectAll && this.handleSelectAll(!this.state.listBox.length);
    // };

    // handleClearOptions = () => this.setSelectedOptions([]);


    // handleToggleOption = (selectedOptions) =>
    //     this.setSelectedOptions(selectedOptions);

    // handleChange = (event, selectedOptions, reason) => {
    //     if (reason === "select-option" || reason === "remove-option") {
    //         if (selectedOptions.find((option) => option.value === "select-all")) {
    //             this.handleToggleSelectAll();
    //             let result = [];
    //             result = this.state.listBox.filter((el) => el.value !== "select-all");
    //             return this.onChange(result);
    //         } else {
    //             this.handleToggleOption && this.handleToggleOption(selectedOptions);
    //             return this.onChange(selectedOptions);
    //         }
    //     } else if (reason === "clear") {
    //         this.handleClearOptions && this.handleClearOptions();
    //     }
    // };



    getTypeDevice = () => {
        fetch(`${HOST}/api/type_device/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var optionTypeDevice = []
                for (let index = 0; index < data.data.length; index++) {
                    optionTypeDevice.push({ 'value': data.data[index].id, "label": data.data[index].name })
                }
                this.setState({
                    listTypeDevice: optionTypeDevice,
                });
            }
        })
    }

    render() {
        console.log(this.state.crrBox)
        // const getOptionSelected = (option, anotherOption) =>
        //     option.value === anotherOption.value;
        // const filter = createFilterOptions();

        // const allSelected = this.state.listBox.length === this.state.selectedOptions.length;
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="la la-qrcode" />
                                        </span>
                                    </span>
                                    QR Code
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-2 p-3">
                            <Select
                                onChange={value => this.handleChangeTypeDevice(value)}
                                options={this.state.listTypeDevice}
                                placeholder="Select Type Device"
                            />
                        </div>
                        <div className="row p-3 col-xl-9" id="select_qrcode">
                            <div className="m--margin-bottom-10-tablet-and-mobile selectbox-qr">
                                {/* <Autocomplete

                                    getOptionSelected={getOptionSelected}

                                    onChange={(e, label) => this.changeHandleBox(e, label)}
                                    size='small'
                                    multiple
                                    limitTags={2}
                                    options={this.state.listBox}
                                    getOptionLabel={(option) => option.label}
                                    // defaultValue={this.state.crrBox}
                                    disableCloseOnSelect
                                    
                                    
                                    renderOption ={(option, { selected }) => {
                                        const selectAllProps =
                                        this.state.listBox.length > 0 && option.value === "select-all" // To control the state of 'select-all' checkbox
                                            ? { checked: allSelected }
                                            : {};
                                        return (
                                          <>
                                            <Checkbox
                                              color="primary"
                                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                              {...selectAllProps}
                                            />
                                            {option.label}
                                          </>
                                        );
                                      }}
                                    
                                    filterOptions={(options, params) => {
                                        if (this.state.listBox.length > 0) {
                                            const filtered = filter(options, params);
                                            return [{ label: "Chọn tất cả", value: "select-all" }, ...filtered];
                                        } else if (this.state.listBox.length === 0) {
                                            const filtered = filter(options, params);
                                            return [...filtered];
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Select QR Code" />
                                    )}
                                /> */}
                                <SelectAllAutocomplete
                                    items={this.state.listBox}
                                    label="Select QR"
                                    placeholder="Select QR"
                                    selectAllLabel="Select all"
                                    onChange={(value) => this.changeHandleBox(value)}
                                />
                            </div>
                            <div className="col-md-5 col-lg-4 m--margin-bottom-10-tablet-and-mobile select_camera">
                                <ReactToPrint
                                    trigger={() => <button type="button" className="btn btn-accent">Print QRCode</button>}
                                    content={() => this.componentRef}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table   -responsive">
                                        <ComponentToPrint ref={el => (this.componentRef = el)} listQRcode={this.state.crrBox} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Qrcode
import React, { Component } from "react";
import { HOST } from '../../config';
import ModalTypeDevice from '../modal/typeDevice/ModalTypeDevice';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';

const override = css`       
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class typeDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listTypeDevice: [],
            modalShow: false,
            isLogin: Store.getState().isLogin.isLogin,
            activePage: 1,
            crrType: [],
            crrTypeDevice: {
                name: "",
                description: "",
            },
            offset: 0,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            loading: true,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
    }

    componentDidMount() {
        this.getTypeDevice();
    }

    getTypeDevice = () => {
        fetch(`${HOST}/api/type_device/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listTypeDevice: data.data,
                    loading: false,
                });
                this.FilterDevice(this.state.activePage)
            }
        })
    }

    DeleteTypeDevice = async (type) => {
        let data = await fetch(`${HOST}/api/type_device/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({ 'id': type.id })
        }).then((response) => {

            return response.json()
        });

        if (data.status === 10000) {
            this.getTypeDevice();
            swal("Deleted!", "You have successfully deleted!", "success", {
                buttons: false,
                timer: 1500,
            });
        }
        else {
            swal("Error!", "Delete Failed!", "error");
        }
    }


    modalClose = () => {
        this.setState({
            modalShow: false,
        });
        this.getTypeDevice();
    }

    FilterDevice = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrType = this.state.listTypeDevice.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrType,
            offset,
            showFirst: offset + 1,
            showLast: crrType.length + offset,
            totalLength: this.state.listTypeDevice.length
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterDevice(pageNumber);
    }


    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedTypeDevice = this.state.crrType.map((e, i) => {
            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{e.name}</td>
                    <td>{e.description}</td>
                    <td>
                        <button onClick={(e) => {

                            var a = this.state.crrType;
                            var type = {
                                id: a[i].id,
                                name: a[i].name,
                                description: a[i].description,
                            };

                            this.setState({
                                crrTypeDevice: type,
                                modalShow: true,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                            <i className="la la-edit" />
                        </button>



                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"
                            onClick={f => {
                                f.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete device " + e.name,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteTypeDevice(e);
                                        }
                                    });

                            }}><i className="la la-trash"></i></button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="fa flaticon-map" />
                                        </span>
                                    </span>
                                    Type Device
                                </h3>
                            </div>
                        </div>
                        <div className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {
                                        var type = {
                                            name: "",
                                            description: "",
                                        };
                                        this.setState({
                                            modalShow: true,
                                            crrTypeDevice: type,
                                        });
                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                            <ModalTypeDevice
                                show={this.state.modalShow}
                                onHide={this.modalClose}
                                type={this.state.crrTypeDevice}
                            />
                        </div>
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Name</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Description</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px'  }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedTypeDevice}
                                            </tbody>

                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span>
                                    <Pagination
                                        prevPageText='Previous'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.itemsPerPage}
                                        totalItemsCount={this.state.totalLength}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default typeDevice;

var _HOST_CMS = ""
var _HOST_MONITOR = ""
var _HOST_BENCHMARK = ""
var _HOST_WS = ""
var _HIDE_REPORT = ""
var _HOST_SERVER = ""

_HOST_CMS = process.env.REACT_APP_HOST_CMS
_HOST_MONITOR = process.env.REACT_APP_HOST_MONITOR
_HOST_BENCHMARK = process.env.REACT_APP_HOST_BENCHMARK
_HOST_WS = process.env.REACT_APP_HOST_WS
_HOST_SERVER = process.env.REACT_APP_HOST_SERVER

export const HOST = _HOST_CMS
export const HOST_MONITOR = _HOST_MONITOR
export const HOST_BENCHMARK = _HOST_BENCHMARK
export const HOST_CLOUD = process.env.REACT_APP_HOST_CLOUD
export const HOST_WS = process.env.REACT_APP_HOST_WS
export const HOST_SERVER = _HOST_SERVER



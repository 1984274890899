import React, { Component } from 'react';
import { HOST_SERVER, HOST_WS } from '../../config';
import { css } from '@emotion/core';
import { ProgressBar } from 'react-bootstrap';
// First way to import
import { PulseLoader } from 'react-spinners';
import Time from 'react-time-format';
import { Redirect } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Store from '../../store';
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import moment from 'moment';
import Select from "react-select";
var mqtt = require('mqtt')
var client = HOST_WS.includes("ws") && mqtt.connect(HOST_WS)
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var Interval;

class Server extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            listServer: [],
            AllServer: [],
            crrServer: [],
            loading: true,
            isChecked: false,
            loadNone: false,
            tempValue: '',
            searchBox: '',
            offset: 0,
            isLogin: Store.getState().isLogin.isLogin,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            valueSearch: { value: "1", label: "IDserver" },
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
            this.getServerList();
        });
        this.itemsPerPage = 10;
        // this.listSearch = [
        //     { value: "1", label: "ems" },
        //     { value: "2", label: "richy_mb" },
        //     { value: "3", label: "server_test" },
        //     { value: "4", label: "fpoly" },
        // ]
        this.getServerList();
    }

    getServerList = () => {
        fetch(`${HOST_SERVER}/v1/server/list`).then((response) => {
            
            return response.json()
        }).then((data) => {
            console.log(data)
            if (data.error_code === 10000) {
                data.data.sort(function (a, b) {
                    if(a.status > b.status){
                        return 1
                    }
                    else{
                        if(a.status < b.status)
                        {
                            return -1;
                        }
                        else{
                            if (a.time > b.time)
                            {
                                return 1;
                            }
                            else{
                                return -1;
                            }
                        }   
                    }
                });
                // data.data.sort((a, b) => a['engines_pub_status'] > b['engines_pub_status'] ? 1 : a['engines_pub_status'] < b['engines_pub_status'] ? -1 : new moment(a['time']).format('YYYY-MM-DD HH:mm:ss') > new moment(b['time']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    AllServer: data.data,
                    listServer: data.data,
                    loading: false
                }, () => {
                    // if (HOST_WS.includes("ws")) {
                    // client = mqtt.connect(HOST_WS)
                    // client.on('connect', function () {
                    //     console.log('mqtt broker connected!')
                    //     client.subscribe(`v1/devices/+/telemetry`, function (err) {
                    //         if (!err) {
                    //             console.log('Device telemetry subscribed');
                    //         }
                    //     })
                    // })
                    // console.log(client.connected)
                    // console.log(client)
                    // let that = this;
                    // client.on('message', function (topic, message) {
                    //     // message is Buffer
                    //     var param = [JSON.parse(message)];
                    //     param[0]['time'] = new Date();
                    //     param[0]['status'] = 1;

                    //     var res = that.state.listDevice.map(obj => param.find(o => o.box_id == obj.box_id) || obj);
                    //     that.setState({
                    //         listDevice: res
                    //     })
                    //     that.FilterDevice(that.state.activePage)
                    // })
                    // }
                });
                
                this.FilterServer(this.state.activePage);
                this.btnSearchBox(this.state.searchBox , this.state.activePage)
            }
        })
    }

    handleChangeSearch = (value) => {
        this.setState({
            valueSearch: value,
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearchBox(event.target.value.trim(), 1)
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }

    reset = () => {
        // this.Interval()
        this.setState({
            searchBox: '',
            activePage: 1,
            listServer: this.state.AllServer
        },()=> {
            this.getServerList()
        });
        $('#inputSearch').val('');
    }

    btnSearchBox = (dataSearch, page) => {
        // clearInterval(Interval)
        var searchRes = []
        if (dataSearch === '') {
            searchRes = this.state.AllServer
        } else {
            this.state.AllServer.forEach((item) => {
                if (this.state.valueSearch.value === '1' && item.server_id.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } else if (this.state.valueSearch.value === "2" && item.server_id.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } else if (this.state.valueSearch.value === "3" && item.server_id.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } else if (this.state.valueSearch.value === "4" && item.server_id.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } 
            })
        }
        this.setState({
            searchBox: dataSearch,
            activePage: page,
            listServer: searchRes
        },()=>{    
            this.FilterServer(page);
        });
    }

    FilterServer = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrServer = this.state.listServer.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrServer,
            offset,
            showFirst: offset + 1,
            showLast: crrServer.length + offset,
            totalLength: this.state.listServer.length
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterServer(pageNumber);
    }

    DeleteServer = async (idbox, i) => {
        let data = await fetch(`${HOST_SERVER}/v1/server/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'server_id': idbox.server_id
            })
        }).then((response) => {
            return response.json()
        });

        if (data.error_code === 10000) {
            this.getServerList();
                swal("Deleted!", "Delete Server Success!", "success", {
                    buttons: false,
                    timer: 2000,
                });
        }
        else {
            swal("Error!", "Delete Server Failed!", "error");
        }
    }

    componentDidMount() {
        this.Interval()
    }

    Interval = () => {
        Interval = setInterval(() => {
            this.getServerList();
        }, 600000);
    }

    componentWillUnmount(){
        clearInterval(Interval)
        // if (HOST_WS.includes("ws")) {
        //     client.end();
        //     console.log('mqtt broker disconnected!')
        // }
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let dataServers = this.state.crrServer.map((value, index) => {
            // var a = moment();
            // var b = moment(value.time);
            if (value.status) {
                var cpu_usage;
                if (value.cpu_usage <= 70) {
                    cpu_usage = <ProgressBar className="animation" striped variant="success" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                else if (value.cpu_usage > 70 && value.cpu_usage <= 90) {
                    cpu_usage = <ProgressBar className="animation" striped variant="warning" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                else {
                    cpu_usage = <ProgressBar className="animation" striped variant="danger" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                var memory_usage;
                if (value.memory_usage <= 70) {
                    memory_usage = <ProgressBar className="animation" striped variant="success" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }
                else if (value.memory_usage > 70 && value.memory_usage <= 90) {
                    memory_usage = <ProgressBar className="animation" striped variant="warning" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }
                else {
                    memory_usage = <ProgressBar className="animation" striped variant="danger" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }
                // var boot_storage_usage;
                // var boot_storage_INT = Number(value.boot_storage_usage);
                // if (boot_storage_INT <= 70) {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="success" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }
                // else if (boot_storage_INT > 70 && boot_storage_INT <= 90) {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="warning" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }
                // else {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="danger" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }

                // var storage_usage;
                // var storage_INT = Number(value.storage_usage);
                // if (storage_INT <= 70) {
                //     storage_usage = <ProgressBar className="animation" striped variant="success" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }
                // else if (storage_INT > 70 && storage_INT <= 90) {
                //     storage_usage = <ProgressBar className="animation" striped variant="warning" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }
                // else {
                //     storage_usage = <ProgressBar className="animation" striped variant="danger" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }

                var temperature;
                
                if (value.temperature != undefined) {
                    if (value.temperature <= 65) {
                        temperature = <ProgressBar className="animation" striped variant="success" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                    else if (value.temperature > 65 && value.temperature <= 80) {
                        temperature = <ProgressBar className="animation" striped variant="warning" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                    else {
                        temperature = <ProgressBar className="animation" striped variant="danger" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                }
            
                let celebrateDay = new Date(value.time);
                
                let serviceState = value.service_state.split('\,');
                let serviceSplit = serviceState.map((childService) => {
                     return (
                        <div id={'service_'}>{childService}</div>
                     )
                })
                return (
                    <tr key={index}>
                        <td>{index + this.state.offset + 1}</td>
                        <td><Link to={"/infor/" + value.server_id}
                            onClick={() => {
                                localStorage.setItem("time", celebrateDay);
                                localStorage.setItem("ip_private", value.ip_private);
                                localStorage.setItem("ip_public", value.ip_public);
                                localStorage.setItem("server_id", value.server_id);
                                localStorage.setItem("mac_address", value.mac_address);

                            }}>
                            {value.server_id}</Link></td>
                        <td>{value.mac_address}</td>
                        <td>{cpu_usage}</td>
                        <td>{memory_usage}</td>
                        {/* <td>{boot_storage_usage}</td> */}
                        {/* <td>{storage_usage}</td> */}
                        <td>{temperature}</td>
                        <td>{value.ip_private}</td>
                        <td>{value.ip_public}</td>
                        <td><span className="m-badge m-badge--info m-badge--wide">Running</span></td>
                        <td>{serviceSplit}</td>
                        <td><Time value={celebrateDay} format="DD/MM/YYYY hh:mm:ss" /></td>
                        <td>
                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete" onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete server idBox " + value.server_id,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteServer(value);
                                        }
                                    });

                            }}><b><i className="la la-trash"></i></b></button>
                        </td>
                        
                    </tr>
                )
            } else {
                var cpu_usage;
                if (value.cpu_usage <= 70) {
                    cpu_usage = <ProgressBar className="animation" striped variant="success" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                else if (value.cpu_usage > 70 && value.cpu_usage <= 90) {
                    cpu_usage = <ProgressBar className="animation" striped variant="warning" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                else {
                    cpu_usage = <ProgressBar className="animation" striped variant="danger" label={value.cpu_usage + '%'} min={0} max={100} now={value.cpu_usage} />
                }
                var memory_usage;
                if (value.memory_usage <= 70) {
                    memory_usage = <ProgressBar className="animation" striped variant="success" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }
                else if (value.memory_usage > 70 && value.memory_usage <= 90) {
                    memory_usage = <ProgressBar className="animation" striped variant="warning" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }
                else {
                    memory_usage = <ProgressBar className="animation" striped variant="danger" label={value.memory_usage + '%'} min={0} max={100} now={value.memory_usage} />
                }

                var temperature;
                if (value.temperature != undefined) {
                    if (value.temperature <= 65) {
                        temperature = <ProgressBar className="animation" striped variant="success" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                    else if (value.temperature > 65 && value.temperature <= 80) {
                        temperature = <ProgressBar className="animation" striped variant="warning" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                    else {
                        temperature = <ProgressBar className="animation" striped variant="danger" label={value.temperature + '°C'} min={0} max={100} now={value.temperature} />
                    }
                }
                
                let celebrateDay = new Date(value.time);
                
                let serviceState = value.service_state.split('\,');
                let serviceSplit = serviceState.map((childService) => {
                     return (
                        <div id={'service_'}>{childService}</div>
                     )
                })
                return (
                    <tr key={index}>
                        <td>{index + this.state.offset + 1}</td>
                        <td><Link to={"/infor/" + value.server_id}
                            onClick={() => {
                                localStorage.setItem("time", celebrateDay);
                                localStorage.setItem("ip_private", value.ip_private);
                                localStorage.setItem("ip_public", value.ip_public);
                                localStorage.setItem("server_id", value.server_id);
                                localStorage.setItem("mac_address", value.mac_address);

                            }}>
                            {value.server_id}</Link></td>
                        <td>{value.mac_address}</td>
                        <td>{cpu_usage}</td>
                        <td>{memory_usage}</td>
                        <td>{temperature}</td>
                        <td>{value.ip_public}</td>
                        {/* <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td> */}
                        {/* <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td> */}
                        <td>{value.ip_private}</td>
                        <td><span className="m-badge m-badge--danger m-badge--wide">Stop</span></td>
                        <td>{serviceSplit}</td>
                        <td><Time value={celebrateDay} format="DD/MM/YYYY hh:mm:ss" /></td>
                        <td>
                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete" onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete this Server?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteServer(value, index);
                                        }
                                    });

                            }}><b><i className="la la-trash"></i></b></button>
                        </td>
                    </tr>
                )
            }
        })
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head" >
                        <div className="m-portlet__head-caption" id = "caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="flaticon-pie-chart" />
                                        </span>
                                    </span>
                                    Server Monitoring
                                </h3>
                            </div>
                        </div>
                        </div>
                        <div id = "menu" className="row p-3 col-xl-11">
                                {/* <div className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select Search'}
                                        value={this.state.valueSearch}
                                        onChange={this.handleChangeSearch}
                                        options={this.listSearch}
                                    />
                                </div> */}
                                <span id = "input4" className="col-lg-2 m--margin-bottom-10-tablet-and-mobile">
                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Name of server" data-col-index={0} />
                            </span>
                            <div id = "search">
                                <button
                                    onClick={(dataSearch) => {
                                        this.btnSearchBox(this.state.tempValue, 1);
                                    }}
                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                    <span>
                                        <i className="la la-search" />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </div>
                            <div id = "reset" className="col-lg-3">
                                <button
                                    onClick={() => {
                                        this.reset()
                                    }}
                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                    <span>
                                        <i className="la la-remove" />
                                        <span>Reset</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">

                                {/*begin:: Widgets/Stats2-1 */}
                                <div className="m-widget1">
                                    <div className="text-nowrap table-responsive mb-3" id="table_servers">
                                        <table className="table mb-0 table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>ID Server</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Mac Address</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>CPU</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Memory</th>
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Boot Storage</th> */}
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Storage</th> */}
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Temperature</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>IP Private</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>IP Public</th>
                                                    
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Server Status</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Server State</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Time</th>
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'width': '50px'  }}>SSH</th> */}
                                                    
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="append_body">
                                                {dataServers}
                                            </tbody>
                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span>
                                    <Pagination
                                        prevPageText='Previous'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.itemsPerPage}
                                        totalItemsCount={this.state.listServer.length}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                                {/*end:: Widgets/Stats2-1 */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Server;


				   
				   
				   
				   
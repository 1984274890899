import React, { Component } from 'react';
import { HOST } from '../../config';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Switch from "react-switch";
import ModalUser from '../modal/user/ModalUser'
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2'
import ModalChangePassword from '../modal/ModalChangePassword';
import ReactTooltip from "react-tooltip";
import Select from "react-select";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listUser: [],
            crrEditUser: null,
            modalEditShow: false,
            modalAddShow: false,
            isChecked: false,
            isLogin: Store.getState().isLogin.isLogin,
            activePage: 1,
            AllUser: [],
            crrListUser: [],
            tempValue: '',
            searchBox: '',
            offset: 0,
            showFirst: 0,
            showLast: 0,
            idUser: 0,
            totalLength: 0,
            listCompany: [],
            loading: true,
            countData: 0,
            valueSelected: { value: "", label: "All Rule" },
            optionSelect: [
                { value: "", label: "All Rule" },
                { value: "1", label: "Admin" }, 
                { value: "2", label: "User" },
                { value: "3", label: "Level 3" },
                { value: "4", label: "Level 4" },
                { value: "5", label: "Level 5" },
            ],
            valueStatus: { value: 1, label: "Active" },
            optionStatus: [
                { value: -1, label: "All" },
                { value: 1, label: "Active" }, 
                { value: 0, label: "Inactive" },
            
            ],
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
        this.getUser();
        this.getAllCompany();
    }

    getUser = () => {
        fetch(`${HOST}/api/user/getAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                item_per_page: this.itemsPerPage,
                index: this.state.activePage,
                data_search: this.state.tempValue,
                rule: this.state.valueSelected.value,
                is_deleted: 0,
                active: this.state.valueStatus.value,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                
                for (let index = 0; index < data.data.length; index++) {
                    data.data[index].full_name = data.data[index].first_name + ' ' + data.data[index].last_name
                }
                this.setState({
                    listUser: [...data.data],
                    AllUser: [...data.data],
                    loading: false,
                    countData: data.count
                });
                this.FilterDevice(this.state.activePage)
            }
        })
    }

    reset = (e) => {
        this.setState({
            searchBox: '',
            activePage: 1,
            tempValue: '',
        }, () => {
            this.getUser()
        });
        $('#inputSearch').val('');
        this.state.listUser = this.state.AllUser;
    }

    DeleteUser = async (user) => {
        let data = await fetch(`${HOST}/api/user/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({ 'email': user.email })
        }).then((response) => {

            return response.json()
        });

        if (data.status === 10000) {
            var {activePage} = this.state
            if (this.state.listUser.length === 1) {
                activePage = activePage -1 
            }
            this.setState({
                activePage
            });
            this.getUser();
            swal("Deleted!", "You have successfully deleted!", "success", {
                buttons: false,
                timer: 1500,
            });
        }
        else {
            swal("Error!", "Delete Failed!", "error");
        }
    }

    componentDidMount() {
        this.getUser();
    }

    modalClose = () => {
        this.setState({
            modalEditShow: false,
            modalAddShow: false,
            modalChangePassword: false,
        });

        this.getUser();
    }

    btnSearchBox = () => {
        this.setState({
            activePage: 1
        }, () => {
            this.getUser()
            this.FilterDevice(1);
        });
    }


    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearchBox(event.target.value.trim())
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }


    FilterDevice = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset,
            showFirst: offset + 1,
            showLast: this.state.listUser.length + offset,
            totalLength: this.state.countData
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => {
            this.getUser()
            this.FilterDevice(pageNumber);
        })
    }

    handleChecked = async (e, i) => {
        var listComval = '|';
        if (e.companies.length > 0) {
            for (let index = 0; index < e.companies.length; index++) {
                listComval += e.companies[index].id + '|'
            }
        } else {
            listComval = '';
        }
        var datacheck = {
            companies_id: listComval,
            id: e.id,
            first_name: e.first_name,
            last_name: e.last_name,
            phone: e.phone,
            email: e.email,
            address: e.address,
            avatar: e.avatar,
            rule: e.rule,
            active: e.active
        };
        if (e.active === 0) {
            datacheck.active = 1;
            let data = await fetch(`${HOST}/api/user/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(datacheck)
            }).then((response) => {
                return response.json()
            });

            if (data.status === 10000) {
                this.getUser();
                swal("Sucess!", "Active User Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            }
            else {
                swal("Error!", "Active User Failed!", "error");
            }
        } else {
            datacheck.active = 0;
            let data = await fetch(`${HOST}/api/user/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(datacheck)
            }).then((response) => {
                return response.json()
            });
            if (data.status === 10000) {
                this.getUser();
                swal("Sucess!", "Inactive User Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            }
            else {
                swal("Error!", "Inactive User Failed!", "error");
            }
        }
    }

    handleChange = async (event , e) => {
        var listComval = '|';
        if (e.companies.length > 0) {
            for (let index = 0; index < e.companies.length; index++) {
                listComval += e.companies[index].id + '|'
            }
        } else {
            listComval = '';
        }
        var datacheck = {
            companies_id: listComval,
            id: e.id,
            first_name: e.first_name,
            last_name: e.last_name,
            phone: e.phone,
            email: e.email,
            address: e.address,
            avatar: e.avatar,
            rule: event.target.value,
            active: e.active
        };
            let data = await fetch(`${HOST}/api/user/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(datacheck)
            }).then((response) => {
                return response.json()
            });

            if (data.status === 10000) {
                this.getUser();
                swal("Sucess!", "Change Rule Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            }
            else {
                swal("Error!", "Change Rule Failed!", "error");
            }
    }

    getAllCompany() {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: 0,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listCompany: [...data.data],
                });
            }
        })
    }

    changeHandleFilter = (e) => {
        this.setState({
            valueSelected: e,
            activePage: 1
        }, () => {
            this.getUser();
        });
    }
    changeHandleStatus = (e) => {
        this.setState({
            valueStatus: e,
            activePage: 1
        }, () => {
            this.getUser();
        });
    }



    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedCompany = this.state.listUser.map((e, i) => {

            if (e.active === 1) {
                this.state.isChecked = true
            }
            if (e.active === 0) {
                this.state.isChecked = false
            }
            this.state.valueRule = e.rule;
            var companies = [];
            for (let index = 0; index < e.companies.length; index++) {
                companies.push(<div key={index}>{e.companies[index].name}</div>);
            }
            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{(e.first_name)+ ' ' + (e.last_name)}</td>
                    <td>{e.phone}</td>
                    <td >{e.email}</td>
                    <td style={{ 'whiteSpace': 'normal', 'width': '300px' }}>{e.address}</td>
                    {/* <td style={{ 'whiteSpace': 'normal', 'maxWidth': '300px' }}>{e.avatar}</td> */}
                    <td>{companies}</td>
                    <td>
                        <select className="form-control m-input" value={this.state.valueRule} onChange={(event) => this.handleChange(event, e)}>
                            <option value="1">Admin</option>
                            <option value="2">User</option>
                            <option value="3">Level 3</option>
                            <option value="4">Level 4</option>
                            <option value="5">Level 5</option>
                        </select>
                    </td>   
                    <td>
                        <Switch
                            onChange={() => { this.handleChecked(e, i) }}
                            checked={this.state.isChecked}
                            handleDiameter={17}
                            offColor="#969696"
                            onColor="#00c5dc"
                            offHandleColor="#ffffff"
                            onHandleColor="#08f"
                            height={25}
                            width={55}
                            className="react-switch"
                            id="small-radius-switch"
                        />
                    </td>
                    <td>
                        <button onClick={() => {

                            this.setState({
                                modalChangePassword: true,
                                idUser: e.id
                            });
                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-success m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for="Change Password" >
                            <i className="la la-refresh" />
                            <ReactTooltip id='Change Password' type='dark' effect='solid'>
                                                <span>Change Password</span>
                                </ReactTooltip>
                        </button>
                        <button onClick={(e) => {
                            var a = this.state.listUser;
                            var userEdit = {
                                companies: a[i].companies,
                                id: a[i].id,
                                first_name: a[i].first_name,
                                last_name: a[i].last_name,
                                phone: a[i].phone,
                                email: a[i].email,
                                address: a[i].address,
                                avatar: a[i].avatar,
                                rule: a[i].rule,
                                active: a[i].active,
                            };

                            this.setState({
                                modalEditShow: true,
                                crrEditUser: userEdit,
                            });

                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for="Edit" >
                            <i className="la la-edit" />
                            <ReactTooltip id='Edit' type='dark' effect='solid'>
                                                <span>Edit</span>
                                </ReactTooltip>
                        </button>



                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for="Delete"
                            onClick={f => {
                                f.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete " + e.email,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteUser(e);
                                        }
                                    });
                            }}><i className="la la-trash"></i></button>
                                <ReactTooltip id='Delete' type='dark' effect='solid'>
                                                <span>Delete</span>
                                </ReactTooltip>
                    </td>
                </tr>
            )
        });

        return (

            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                  <div className="m-portlet" >
                    <div className="m-portlet__head" >
                        <div className="m-portlet__head-caption" id = "caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="fa flaticon-users" />
                                        </span>
                                    </span>
                                    User
                                </h3>
                            </div>
                        </div>
                        <div id = "add1" className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {

                                        var userEdit = {
                                            companies: "",
                                            first_name: "",
                                            last_name: "",
                                            password: "",
                                            phone: "",
                                            email: "",
                                            address: "",
                                            avatar: "",
                                            rule: 2,
                                            active: 1,
                                        };

                                        this.setState({
                                            modalEditShow: true,
                                            crrEditUser: userEdit,
                                        });


                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                            <ModalUser
                                datacompany={this.state.listCompany}
                                show={this.state.modalEditShow}
                                onHide={this.modalClose}
                                dataedit={this.state.crrEditUser}
                            />
                        </div>
                     </div>
                        <div id = "menuu" className="row p-3 col-xl-10">
                            <span id = "menu2" className="col-xl-2 pl-0 mb-3">
                                <Select
                                    placeholder={'All Rule'}
                                    value={this.state.valueSelected}
                                    onChange={this.changeHandleFilter}
                                    options={this.state.optionSelect}
                                />
                            </span>
                            <span id = "menu2" className="col-xl-2 pl-0 mb-3">
                                <Select
                                    placeholder={'All Status'}
                                    value={this.state.valueStatus}
                                    onChange={this.changeHandleStatus}
                                    options={this.state.optionStatus}
                                />
                            </span>
                            <span id = "input" className="col-lg-2 m--margin-bottom-10-tablet-and-mobile">
                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Name or Email " data-col-index={0} />
                            </span>
                            <span id = "search">
                                <button
                                    onClick={(dataSearch) => {
                                        this.btnSearchBox(this.state.tempValue)
                                    }}
                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                    <span>
                                        <i className="la la-search" />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </span>
                            <span id = "reset" className="col-lg-3">
                                <button
                                    onClick={() => {
                                        this.reset()
                                    }}
                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                    <span>
                                        <i className="la la-remove" />
                                        <span>Reset</span>
                                    </span>
                                </button>
                            </span>
                        </div>
                        
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive text-nowrap">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Name</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Phone</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Email</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Address</th>
                                                    {/* <th style={{ 'verticalAlign': 'middle' }}>Avatar</th> */}
                                                    <th style={{ 'verticalAlign': 'middle' }}>Company</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Rule</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Status</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedCompany}
                                            </tbody>

                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                        {/* // <ModalEditUser
                                        //     datacompany={this.state.listCompany}
                                        //     dataedit={this.state.crrEditUser}
                                        //     show={this.state.modalEditShow}
                                        //     onHide={this.modalClose}
                                        // /> */}
                                        <ModalChangePassword
                                            iduser={this.state.idUser}
                                            show={this.state.modalChangePassword}
                                            onHide={this.modalClose}
                                        />
                                    </div>
                                    <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span>
                                    <Pagination
                                        prevPageText='Previous'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.itemsPerPage}
                                        totalItemsCount={this.state.totalLength}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default User;
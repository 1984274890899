import React from "react";
import "antd/dist/antd.css";
import { Upload, Modal, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from 'axios'
import Store from '../../../store';
import swal from 'sweetalert';
import swalx from '@sweetalert/with-react';
import { HOST } from '../../../config';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
var fileDownload = require('js-file-download');

const token = Store.getState().isLogin.access_token;
class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
    uid: '',
    name: '',
    listFileRender: [],
    newFileUpload: [],
    fileFail: [],
    fileOk: []
  };

  handleCancel = () => {
    this.setState({
      fileFail: [],
      fileOk: []
    });
    console.log();
  }


  handleChange = (file) => {
    var { newFileUpload } = this.state
    if (file.file.status !== "removed") {
      newFileUpload.push(file.file)
    }
    this.setState({
      listFileRender: file.fileList,
      newFileUpload
    })
  };

  handleSubmit = async (item) => {
    //item.preventDefault();
    var forI = 0
    if (this.state.newFileUpload.length > 0) {
      // var fileFail = []
      // var fileOk = []
      for (let i = 0; i < this.state.newFileUpload.length; i++) {

        var newFileUpload
        newFileUpload = new File([this.state.newFileUpload[i]], [this.state.newFileUpload[i].name], ['image/jpeg/pdf/docx/xlsx'])
        let formData = new FormData();

        if (this.state.newFileUpload[i].name.length < 25) {
          formData.append("box_id", this.props.box_id);
          formData.append("file", newFileUpload);

        }

        let axiosConfig = {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
          }
        };

        axios.post(`${HOST}/api/box/upload/box_evidence`, formData, axiosConfig)
          .then(res => {
            if (res.data.status == 10000) {
              forI++
              var { listFileRender } = this.state;
              var Idx = listFileRender.findIndex((value) => value.uid === this.state.newFileUpload[i].uid)
              if (Idx > 0) {
                listFileRender[Idx].uid = res.data.data.id
              }
              if (forI === this.state.newFileUpload.length) {
                swal("Sucess!", "Add File Sucess!", "success", {
                  buttons: false,
                  timer: 1500,
                });
                this.setState({
                  listFileRender,
                  newFileUpload: []
                })
                
              }
            } else if (res.data.status == 10002) {
              swal("Error!", "Please choose file have with under length of name is 25 character ", "error", {
                buttons: false,
                timer: 2500,
              }).then(() => {
                for (let i = 0; i < this.state.newFileUpload.length; i++) {
                  var { fileFail } = this.state;

                  var av = this.state.newFileUpload[i].name
                  if (av.length > 25) {
                    fileFail.push(av)
                  }

                  this.setState({
                    fileFail: fileFail,
                  })
                  swalx({
                    title: "Lists file error",
                    content: <div>
                      {
                        this.state.fileFail.map((value) => {
                          return (
                            <div>
                              {value}
                            </div>
                          )
                        })
                      }
                    </div>
                  });
                  this.setState({ 
                    newFileUpload: []
                  })
                }
                var { fileOk } = this.state;

                this.state.listFileRender.filter((val) => {
                  if (val.name.length < 25) {
                    fileOk.push(val)
                  }
                })
                this.setState({
                  listFileRender: fileOk
                })
                
              })
            }
          })
      }
    } else {
      swal('Warning', 'Please choose some image', 'warning')
    }
  };

  handleRemove = (item) => {
    var { newFileUpload } = this.state
    this.setState({
      newFileUpload: newFileUpload.filter((option) => option.uid !== item.uid)
    })

    const boxId = { id: item.uid };
    //console.log(newsId);
    let axiosConfig = {
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json'
      }
    };
    axios.post(`${HOST}/api/box/delete/box_evidence`, boxId, axiosConfig)
      .then(res => {
        this.setState(
          prevState => ({
            fileList: prevState.fileList.filter(elm => elm.id_box !== item)
          })
        );
      })
      .catch(error => console.log(error));
  }

  componentWillReceiveProps(nextProps) {
    var file = []
    if (nextProps.file !== undefined) {
        for (let index = 0; index < nextProps.file.length; index++) {
            file.push({
              uid: nextProps.file[index].id,
              name: nextProps.file[index].path,
            },
            )
          }
    }
    this.setState({
      listFileRender: file,
    })
  }
  handleDownload = async (file) => {

    const khaiculi = window.confirm('Are you sure you wish to download this item?');
    if (khaiculi == true) {
      await fetch(`${HOST}/api/box/download/${file.name}`, {
        method: 'GET',
        headers: {
          'Authorization': Store.getState().isLogin.access_token,
        }
      }).then(function (resp) {
        console.log(resp);
        return resp.blob();
      }).then(function (blob) {
        console.log(blob);
        fileDownload(blob, file.name);
      });
    } else {
      console.log("close");
    }
  }

  render() {
    const { listFileRender } = this.state;
    const uploadButton = (
      <div>
        <Button>
          <UploadOutlined /> Upload
      </Button>
      </div>
    );
    return (
      <div className="clearfix">

        <Upload {...this.props}
          listType="picture"
          onPreview={this.handleDownload}

          onChange={this.handleChange}
          onRemove={this.handleRemove}
          fileList={listFileRender}
          beforeUpload={() => false}
          multiple={true}
        >
          {uploadButton}
        </Upload>
        {/* //<a href='/somefile.txt' download>Click to download</a> */}
        <Button onClick={this.handleSubmit} >
          Submit
        </Button>
      </div >
    );
  }
}

export default PicturesWall
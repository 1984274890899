import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';
import Switch from "react-switch";
import Select from "react-select";

class ModalLicense extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOptionCompany: [],
            listOptionModules: [],
            listOptionUser: [],
            dataLicense: {
                "id_company": null,
                "id_module": null,
                "active": 1,
                "created_user": null
            },
            disabled: false,
            isChecked: false
        }
    }

    UpdateLicense = async (paramLicense, event) => {
        //Insert
        var form = $("#formAddTypeDevice")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            this.setState({ disabled: true })
            if (paramLicense.id === undefined) {
                let data = await fetch(`${HOST}/api/license/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramLicense)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Add License Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                    return this.props.onHide();

                }
                else {
                    this.setState({
                        disabled: false
                    })
                    swal("Error!", "Add License Failed!", "error");

                }
            } else {
                let data = await fetch(`${HOST}/api/license/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(paramLicense)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit License Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                    return this.props.onHide();
                }
                else {
                    swal("Error!", "Edit License Failed!", "error");
                    this.setState({
                        disabled: false
                    })
                }
            }
        }
    }

    getCompany = () => {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: 0,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption;
                var companyOption = [];
                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.name };
                    companyOption.push(dataOption);
                });
                this.setState({
                    listOptionCompany: companyOption,
                });
            }
        })
    }

    getModules = () => {
        fetch(`${HOST}/api/module/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption;
                var modulesOption = [];
                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.code };
                    modulesOption.push(dataOption);
                });
                this.setState({
                    listOptionModules: modulesOption,
                });
            }
        })
    }

    getUser = () => {
        fetch(`${HOST}/api/user/getByToken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var typeLicense = this.state.dataLicense;
                typeLicense['created_user'] = data.data.id;
            }
        })
    }

    handleChangeCompany(value) {
        var typeLicense = this.state.dataLicense;
        typeLicense['id_company'] = value.value;
        this.setState({
            dataLicense: typeLicense
        })
    }

    handleChangeModules(value) {
        var typeLicense = this.state.dataLicense;
        typeLicense['id_module'] = value.value;
        this.setState({
            dataLicense: typeLicense
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                dataLicense: nextProps.crrlicense,
            }, () => {
                this.getCompany()
                this.getModules()
                this.getUser()
            });
        }
    }

    render() {
        let click_handle = (event) => {
            this.UpdateLicense(this.state.dataLicense, event);
        }

        if (this.state.dataLicense.active === 1) {
            this.state.isChecked = true
        }
        if (this.state.dataLicense.active === 0) {
            this.state.isChecked = false
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddTypeDevice">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Company">Company</label>
                                    <Select
                                        options={this.state.listOptionCompany}
                                        value={
                                            this.state.listOptionCompany.filter((option) => {
                                                return this.state.dataLicense.id_company === option.value
                                            })
                                        }
                                        onChange={value => this.handleChangeCompany(value)}
                                        placeholder="Select Company"
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Modules">Module</label>
                                    <Select
                                        options={this.state.listOptionModules}
                                        value={
                                            this.state.listOptionModules.filter((option) => {
                                                return this.state.dataLicense.id_module === option.value
                                            })
                                        }
                                        onChange={value => this.handleChangeModules(value)}
                                        placeholder="Select Module"
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Active">Active</label>
                                    <div className="pt-2 pb-2">
                                        <Switch
                                            onChange={() => {
                                                var active;
                                                if (!this.state.isChecked == true) {
                                                    active = 1
                                                } else {
                                                    active = 0
                                                }
                                                var license = this.state.dataLicense;
                                                license['active'] = active;
                                                this.setState({
                                                    dataLicense: license,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle} className={this.state.disabled === true ? 'm-loader--light m-loader--right m-loader' : ''} disabled={this.state.disabled}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalLicense.propTypes = {
    crrlicense: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalLicense;
import { SETBOX } from '../../actions/boxai';

const initialState = {
    box_engine_hm: '',
    box_engine_cf: ''
};
export default function boxai(state = initialState, action) {
    switch(action.type){
        case SETBOX:
            return{
                box_engine_hm: action.payload.box_engine_hm,
                box_engine_cf: action.payload.box_engine_cf
            };
        default:
            return state;
    }
}

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import { login } from '../actions/isLogin';
import { HOST_CLOUD } from '../config';
import Store from '../store';
import './css/login.css';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
        }
        this.email = '';
        this.password = '';
        localStorage.clear();
    }
    CheckLogin = async (e) => {
        let result = await fetch(`${HOST_CLOUD}/api/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.email,
                password: this.password,
                login_type: 1,
            })
        });
        let data = await result.json();
        if (data.status === 10000) {
            var data1;
            var dataUser = [];
            data1 = {
                first_name: data.user_info.first_name,
                last_name: data.user_info.last_name,
                email: data.user_info.email
            }
            dataUser.push(data1)
            localStorage.setItem("dataUser", JSON.stringify(dataUser))
            if (data.user_info.rule === '1') {
                this.setState({ 'error': 0 })
                localStorage.setItem("access_token", 'Bearer ' + data.access_token);
                Store.dispatch(login("Bearer " + data.access_token));
                this.props.history.push('/');
            } else {
                swal("Error!", "Login Failed!", "error");
            }
        } else if(data.status === 10005) {
            swal("Cảnh báo", "Tài khoản của bạn đã bị khóa, vui lòng chờ 30 phút rồi đăng nhập lại hoặc liên hệ admin", "warning");
            return;
        } else {
            this.setState({ 'error': 1 })
            return;
        }
    }

    onEnterPress = (e) => {

        if (e.keyCode == 13 && e.shiftKey == false) {
            if (e.target.name == 'email') {
                this.refs.password.focus();
            }
            else {
                e.preventDefault();
                this.CheckLogin();
            }
        }

    }

    render() {
        return (
            <section className="login-block" style={{ 'width': '100%' }}>
                <div className="container">
                    <Row>
                        <Col md={4} className="login-sec">
                            <h2 className="text-center">Login Now</h2>
                            <div className="login-form" id="formLogin">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1" className="text-uppercase">Email</label>
                                    <input type="text" name="email" className="form-control" placeholder='email' onKeyDown={this.onEnterPress} onChange={(e) => {
                                        this.email = e.target.value;
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1" className="text-uppercase">Password</label>
                                    <input type="password" ref='password' name="password" className="form-control" placeholder='password' onKeyDown={this.onEnterPress} onChange={(e) => {
                                        this.password = e.target.value;
                                    }} />
                                </div>
                                <div className="form-check pl-0">
                                    <label id="label_check" className={"form-check-label" + (this.state.error == 1 ? "" : " d-none")}>
                                        <small className="text-danger">* wrong account name or password</small>
                                    </label>
                                    <button type="submit" className="btn btn-login float-right" id="btnLogin" onClick={() => {
                                        this.CheckLogin();
                                    }}>Login</button>
                                </div>
                            </div>
                            <div className="copy-text">Created with <i className="fa fa-heart" /> by BeetMember</div>
                        </Col>
                        <Col md={8} className="banner-sec">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                                    <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <img className="d-block img-fluid" src="img/pexels-photo.jpg" alt="First slide" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text b-0">
                                                <h2>This is BeetAI</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="img/people-coffee-tea-meeting.jpg" alt="First slide" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>This is Heaven</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}
export default Login;

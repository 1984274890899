import React, { Component } from 'react';
import { HOST_MONITOR, HOST_WS, HOST } from '../../config';
import { css } from '@emotion/core';
import { ProgressBar } from 'react-bootstrap';
// First way to import
import { PulseLoader } from 'react-spinners';
import Time from 'react-time-format';
import { Redirect } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Store from '../../store';
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import moment from 'moment';
import Select from "react-select";
import { UndoRounded } from '@material-ui/icons';
var mqtt = require('mqtt')
var client = HOST_WS.includes("ws") && mqtt.connect(HOST_WS)
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var Interval;

class Device extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            listDevice: [],
            AllDevice: [],
            crrDevice: [],
            listCompany: [],
            crrCompany: [],
            loading: true,
            isChecked: false,
            loadNone: false,
            tempValue: '',
            searchBox: '',
            offset: 0,
            isLogin: Store.getState().isLogin.isLogin,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            valueSearch: { value: "1", label: "Mac" },
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
            this.getDeviceList();
            this.getCompany()
        });
        this.itemsPerPage = 10;
        this.listSearch = [
            { value: "1", label: "Mac" },
            { value: "2", label: "BoxID" },
            { value: "3", label: "EngineID" },
        ]
        this.getDeviceList();
        this.getCompany()
    }

    getDeviceList = () => {
        fetch(`${HOST_MONITOR}/v1/devices`).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.error_code === 10000) {
                data.data.sort(function (a, b) {
                    if (a.status > b.status) {
                        return 1
                    }
                    else {
                        if (a.status < b.status) {
                            return -1;
                        }
                        else {
                            if (a.engines_pub_status > b.engines_pub_status) {
                                return 1;
                            }
                            else {
                                if (a.engines_pub_status < b.engines_pub_status) {
                                    return -1;
                                }
                                else {
                                    if (a.time > b.time) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }

                                }
                            }
                        }
                    }
                });
                // data.data.sort((a, b) => a['engines_pub_status'] > b['engines_pub_status'] ? 1 : a['engines_pub_status'] < b['engines_pub_status'] ? -1 : new moment(a['time']).format('YYYY-MM-DD HH:mm:ss') > new moment(b['time']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    AllDevice: data.data,
                    listDevice: data.data,
                    loading: false
                }, () => {
                    // if (HOST_WS.includes("ws")) {
                    // client = mqtt.connect(HOST_WS)
                    // client.on('connect', function () {
                    //     console.log('mqtt broker connected!')
                    //     client.subscribe(`v1/devices/+/telemetry`, function (err) {
                    //         if (!err) {
                    //             console.log('Device telemetry subscribed');
                    //         }
                    //     })
                    // })
                    // console.log(client.connected)
                    // console.log(client)
                    // let that = this;
                    // client.on('message', function (topic, message) {
                    //     // message is Buffer
                    //     var param = [JSON.parse(message)];
                    //     param[0]['time'] = new Date();
                    //     param[0]['status'] = 1;

                    //     var res = that.state.listDevice.map(obj => param.find(o => o.box_id == obj.box_id) || obj);
                    //     that.setState({
                    //         listDevice: res
                    //     })
                    //     that.FilterDevice(that.state.activePage)
                    // })
                    // }
                });

                this.FilterDevice(this.state.activePage);
                this.btnSearchBox(this.state.searchBox, this.state.activePage)
            }
        })
    }

    getCompany = () => {
        fetch(`${HOST}/api/box/getAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                item_per_page: 100000000000,
                index: this.state.activePage,
                data_search: this.state.tempValue,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            
            if (data.status === 10000) {
                // let array = [];
                // for (let i = 0; i < data.data.length; i++) {
                //     array.push({
                //         boxId: data.data[i].id,
                //         name: data.data[i].company?.name,
                //     })
                // }
            //     let dicttt = array.reduce((dictt, obj) =>{
            //         dictt[obj.boxId] = obj;
            //         return dictt;
            //     }, {})
                
            //   let dic = Object.keys(dicttt).reduce((acc, key) => {
            //         acc[key] = dicttt[key].name;
            //         return acc;
            //     }, {})
            let dictBoxes = {}
            for (let i = 0; i < data.data.length; i++) {
               dictBoxes[data.data[i].id] = data.data[i].company?.name
            }
            
            this.setState({ listCompany: dictBoxes });   
              
            }
        })
    }

    handleChangeSearch = (value) => {
        this.setState({
            valueSearch: value,
        })
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearchBox(event.target.value.trim(), 1)
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }

    reset = () => {
        // this.Interval()
        this.setState({
            searchBox: '',
            activePage: 1,
            listDevice: this.state.AllDevice
        }, () => {
            this.getDeviceList()
        });
        $('#inputSearch').val('');
    }

    btnSearchBox = (dataSearch, page) => {
        // clearInterval(Interval)
        var searchRes = []
        if (dataSearch === '') {
            searchRes = this.state.AllDevice
        } else {
            this.state.AllDevice.forEach((item) => {
                if (this.state.valueSearch.value === '1' && item.mac_address.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } else if (this.state.valueSearch.value === "2" && item.box_id.indexOf(dataSearch) != -1) {
                    searchRes.push(item);
                } else if (this.state.valueSearch.value === "3") {
                    item.engines_pub.find((tag) => {
                        if (tag.name.includes(dataSearch)) {
                            searchRes.push(item);
                        }
                    })
                }
            })
        }
        this.setState({
            searchBox: dataSearch,
            activePage: page,
            listDevice: searchRes
        }, () => {
            this.FilterDevice(page);
        });
    }

    FilterDevice = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrDevice = this.state.listDevice.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrDevice,
            offset,
            showFirst: offset + 1,
            showLast: crrDevice.length + offset,
            totalLength: this.state.listDevice.length,
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterDevice(pageNumber);
    }

    DeleteDevice = async (idbox, i) => {
        let data = await fetch(`${HOST_MONITOR}/v1/delete_box`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'box_id': idbox.box_id
            })
        }).then((response) => {
            return response.json()
        });

        if (data.error_code === 10000) {
            this.getDeviceList();
            swal("Deleted!", "Delete Device Success!", "success", {
                buttons: false,
                timer: 2000,
            });
        }
        else {
            swal("Error!", "Delete Device Failed!", "error");
        }
    }

    componentDidMount() {
        this.Interval()
    }

    Interval = () => {
        Interval = setInterval(() => {
            this.getDeviceList();

        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(Interval)
        // if (HOST_WS.includes("ws")) {
        //     client.end();
        console.log('mqtt broker disconnected!')
        // }
    }


    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        console.log(this.state.listCompany)
        let dataDevices = this.state.crrDevice.map((value, index) => {
            // var a = moment();
            // var b = moment(value.time);
            if (value.status) {
                var cpu_usage;
                var cpu_INT = Number(value.cpu_usage);
                if (cpu_INT <= 70) {
                    cpu_usage = <ProgressBar className="animation" striped variant="success" label={cpu_INT + '%'} min={0} max={100} now={cpu_INT} />
                }
                else if (cpu_INT > 70 && cpu_INT <= 90) {
                    cpu_usage = <ProgressBar className="animation" striped variant="warning" label={cpu_INT + '%'} min={0} max={100} now={cpu_INT} />
                }
                else {
                    cpu_usage = <ProgressBar className="animation" striped variant="danger" label={cpu_INT + '%'} min={0} max={100} now={cpu_INT} />
                }
                var memory_usage;
                var memory_INT = Number(value.memory_usage);
                if (memory_INT <= 70) {
                    memory_usage = <ProgressBar className="animation" striped variant="success" label={memory_INT + '%'} min={0} max={100} now={memory_INT} />
                }
                else if (memory_INT > 70 && memory_INT <= 90) {
                    memory_usage = <ProgressBar className="animation" striped variant="warning" label={memory_INT + '%'} min={0} max={100} now={memory_INT} />
                }
                else {
                    memory_usage = <ProgressBar className="animation" striped variant="danger" label={memory_INT + '%'} min={0} max={100} now={memory_INT} />
                }
                // var boot_storage_usage;
                // var boot_storage_INT = Number(value.boot_storage_usage);
                // if (boot_storage_INT <= 70) {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="success" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }
                // else if (boot_storage_INT > 70 && boot_storage_INT <= 90) {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="warning" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }
                // else {
                //     boot_storage_usage = <ProgressBar className="animation" striped variant="danger" label={boot_storage_INT + '%'} min={0} max={100} now={boot_storage_INT} />
                // }

                // var storage_usage;
                // var storage_INT = Number(value.storage_usage);
                // if (storage_INT <= 70) {
                //     storage_usage = <ProgressBar className="animation" striped variant="success" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }
                // else if (storage_INT > 70 && storage_INT <= 90) {
                //     storage_usage = <ProgressBar className="animation" striped variant="warning" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }
                // else {
                //     storage_usage = <ProgressBar className="animation" striped variant="danger" label={storage_INT + '%'} min={0} max={100} now={storage_INT} />
                // }

                var temperature;
                var temperature_INT = Number(value.temperature);
                if (temperature_INT <= 65) {
                    temperature = <ProgressBar className="animation" striped variant="success" label={temperature_INT + '°C'} min={0} max={100} now={temperature_INT} />
                }
                else if (temperature_INT > 65 && temperature_INT <= 80) {
                    temperature = <ProgressBar className="animation" striped variant="warning" label={temperature_INT + '°C'} min={0} max={100} now={temperature_INT} />
                }
                else {
                    temperature = <ProgressBar className="animation" striped variant="danger" label={temperature_INT + '°C'} min={0} max={100} now={temperature_INT} />
                }

                let dataEng = value.engines_pub.map((e, i) => {
                    if (e.name !== "") {
                        if (e.status === 1) {
                            return (
                                <div key={i} className="pb-2" >
                                    <span style={{ 'width': '125px', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--info m-badge--wide">{e.name}</span>
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} className="pb-2">
                                    <span style={{ 'width': '125px', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--danger m-badge--wide" key={i}>{e.name}</span>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div key={i} className="pb-2">
                                <span style={{ 'width': '125px', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--default m-badge--wide" key={i}>Engine Inactive</span>
                            </div>
                        )
                    }
                })

                let celebrateDay = new Date(value.time);
                
                let nameCompany = this.state.listCompany[value.box_id];
               
                return (
                    <tr key={index}>
                        <td>{index + this.state.offset + 1}</td>
                        <td><Link to={"/detail/" + value.box_id}
                            onClick={() => {
                                localStorage.setItem("time", celebrateDay);
                                localStorage.setItem("ip_private", value.ip_private);
                                localStorage.setItem("ip_public", value.ip_public);
                            }}>
                            {value.box_id}</Link></td>
                        <td>{value.mac_address}</td>
                        <td>{cpu_usage}</td>
                        <td>{memory_usage}</td>
                        {/* <td>{boot_storage_usage}</td> */}
                        {/* <td>{storage_usage}</td> */}
                        <td>{temperature}</td>
                        <td>{value.ip_private}</td>
                        <td>{value.ip_public}</td>
                        <td>{dataEng}</td>
                        <td><span className="m-badge m-badge--info m-badge--wide">Running</span></td>
                        <td><Time value={celebrateDay} format="DD/MM/YYYY hh:mm:ss" /></td>
                        <td>{nameCompany}</td>
                        <td>
                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete" onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete device idBox " + value.box_id,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteDevice(value);
                                        }
                                    });

                            }}><b><i className="la la-trash"></i></b></button>
                        </td>
                    </tr>
                )
            } else {
                let celebrateDay = new Date(value.time);
                let dataEng = value.engines_pub.map((e, i) => {
                    if (e.name !== "") {
                        return (
                            <div key={i} className="pb-2" >
                                <span style={{ 'width': '125px', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--danger m-badge--wide">{e.name}</span>
                            </div>
                        )
                    } else {
                        return (
                            <div key={i} className="pb-2">
                                <span style={{ 'width': '125px', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }} className="m-badge m-badge--default m-badge--wide">Engine Inactive</span>
                            </div>
                        )
                    }
                })
               
                let nameCompany = this.state.listCompany[value.box_id];
               
                
                return (
                    <tr key={index}>
                        <td>{index + this.state.offset + 1}</td>
                        <td><Link to={"/detail/" + value.box_id}
                            onClick={() => {
                                localStorage.setItem("time", celebrateDay);
                                localStorage.setItem("ip_private", value.ip_private);
                                localStorage.setItem("ip_public", value.ip_public);
                            }}
                        >
                            {value.box_id}</Link></td>
                        <td>{value.mac_address}</td>
                        <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td>
                        <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td>
                        {/* <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td> */}
                        {/* <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '%'} min={0} max={100} now={0} /></td> */}
                        <td><ProgressBar className="animation bg-dang" striped variant="danger" label={0 + '°C'} min={0} max={100} now={0} /></td>
                        <td>{value.ip_private}</td>
                        <td>{value.ip_public}</td>
                        <td><span>{dataEng}</span></td>
                        <td><span className="m-badge m-badge--danger m-badge--wide">Stop</span></td>
                        <td><Time value={celebrateDay} format="DD/MM/YYYY hh:mm:ss" /></td>
                        <td>{nameCompany}</td>
                        <td>
                            <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete" onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete this Device?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteDevice(value, index);
                                        }
                                    });

                            }}><b><i className="la la-trash"></i></b></button>
                        </td>
                    </tr>
                )
            }
        })

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                  <div className="m-portlet__head" >
                        <div className="m-portlet__head-caption" id = "caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="flaticon-pie-chart" />
                                        </span>
                                    </span>
                                    Monitoring
                                </h3>
                            </div>
                        </div>
                        </div>
                        <div id = "menu" className="row p-3 col-xl-11">
                                <div id = "menu1" className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select Search'}
                                        value={this.state.valueSearch}
                                        onChange={this.handleChangeSearch}
                                        options={this.listSearch}
                                    />
                                </div>
                            <span id = "input2" className="col-lg-2 m--margin-bottom-10-tablet-and-mobile">
                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder={
                                    this.state.valueSearch.value == 1
                                        ?
                                        'Mac Address'
                                        :
                                        this.state.valueSearch.value == 2
                                            ?
                                            'ID Box'
                                            :
                                            'EngineID'
                                }
                                    data-col-index={0} />
                            </span>
                            <span id = "search">
                                <button
                                    onClick={(dataSearch) => {
                                        this.btnSearchBox(this.state.tempValue, 1);
                                    }}
                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                    <span>
                                        <i className="la la-search" />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </span>
                            <span id = "reset" className="col-lg-3">
                                <button
                                    onClick={() => {
                                        this.reset()
                                    }}
                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                    <span>
                                        <i className="la la-remove" />
                                        <span>Reset</span>
                                    </span>
                                </button>
                            </span>
                        </div>
                        
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">

                                {/*begin:: Widgets/Stats2-1 */}
                                <div className="m-widget1">
                                    <div className="text-nowrap table-responsive mb-3" id="table_devices">
                                        <table className="table mb-0 table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>ID Box</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Mac Address</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>CPU</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Memory</th>
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Boot Storage</th> */}
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Storage</th> */}
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Temperature</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>IP Private</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>IP Public</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Engine Status</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>Box Status</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Time</th>
                                                    {/* <th style={{ 'verticalAlign': 'middle', 'width': '50px'  }}>SSH</th> */}
                                                    <th style={{ 'verticalAlign': 'middle', 'minWidth': '90px', 'maxWidth': '90px' }}>Company</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="append_body">
                                                {dataDevices}
                                            </tbody>
                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span>
                                    <Pagination
                                        prevPageText='Previous'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.itemsPerPage}
                                        totalItemsCount={this.state.listDevice.length}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                                {/*end:: Widgets/Stats2-1 */}
                            </div>
                        </div>
                    </div>
                
            </div>
          </div>
        );
    }
}
export default Device;
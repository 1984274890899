import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { HOST, HOST_WS } from '../../../config';
import swal from 'sweetalert';
import Select from "react-select";
import Store from '../../../store';
import Switch from "react-switch";
import $ from 'jquery';
import { PulseLoader } from 'react-spinners';
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import ReactFileReader from 'react-file-reader';
import axios from 'axios';
import PicturesWall from './uploadFile';
import { format } from 'highcharts';
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
var mqtt = require('mqtt')
var client = HOST_WS.includes("ws") && mqtt.connect(HOST_WS)
const dataMain = [
    { 'value': 0, 'label': 'Not Is Main' },
    { 'value': 1, 'label': 'Is Main' }
]
var timeoutUpdateEngine;
var timeoutUpdateModel;
class ModalUpdateBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusArray: [
              {value: 1, label: "In Stock", backgroundColor: "orange"},
              {value: 2, label: "Sold", backgroundColor: "#40bd67"},
              {value: 3, label: "POC", backgroundColor: "#2cc1d8"},
              {value: 4, label: "Rented", backgroundColor: "#e878b4"},
              {value: 5, label: "Under Warranty", backgroundColor: "red"},
              {value: 6, label: "Out of order", backgroundColor: "#8a8a93"},
              {value: 7, label: "Fake", backgroundColor: "black"},
            ],
            boxStatusAdd: {
                box_id: null,
                time: moment().format("YYYY-MM-DD:mm:ss"),
                status: "",
                description: "",
            },
            boxStatusEditAdd: {},
            indexEdit: -1,
            editStatus: false,
            boxStatus: [],

            crrBox: { 'active': 0 },
            value_show_engine: '',
            dataEngine: [],
            listOptionTypeDevice: [],
            dataOptionEngine: [],
            crrEngine: {
                box_engine_id: '',
                api_partner: null,
                box_id: '',
                engine_id: '',
                version_id: '',
                active: 1,
                rtsp: '',
                alias: '',
                engine_models: [
                    { engine_model_id: '', name_engine_model: '', threshold: 0.9, is_main: 1 }
                ]
            },
            model: [],
            idmodelEdit: 0,
            listCompany: [],
            listEngine: [],
            listCompanySearch: [],
            listEngine: [],
            listEngineModel: [],
            listMain: dataMain,
            listEngineVersion: [],
            dataShowEngine: [],
            value: {},
            valueShowEngine: {},
            valueShowEngineVersion: {},
            valueShowRTSP: '',
            valueShowAlias: '',
            valueShowApiPartner: '',
            valueShowEngineModel: [],
            valueModel: {},
            valueModelDefault: {},
            valueThress: '',
            valueis_main: {},
            isChecked: true,
            isCheckedEngine: false,
            display_none: true,
            changeEdit_add: true,
            display_delete: true,
            loading: false,
            loadingEngine: false,
            loadingDeleteEngine: false,
            loadingComplete: false,
            loadingCompleteModel: false,
            file: ""
        }
    }
    //add phai co company serial
    //edit phai co company serial


    conectMQTT = (id_box) => {
        this.setState({
            loadingComplete: true
        })
        $('#complete').addClass('d-none')
        if (HOST_WS.includes("ws")) {
            var payload =
            JSON.stringify({
                "method": "update_engine"
            })
        client.publish(`v1/devices/${id_box}/request/+`, payload)
        } 
        timeoutUpdateEngine = setTimeout(() => {
            $('#complete').removeClass('d-none')
            this.setState({
                loadingComplete: false
            })
            swal("Warning!", "Timeout Call!", "warning");
        }, 10000);
    }

    conectMQTTModel = (id_box) => {
        this.setState({
            loadingCompleteModel: true
        })
        $('#completeModel').addClass('d-none')
        if (HOST_WS.includes("ws")) {
            var payload =
                JSON.stringify({
                    "method": "update_model"
                })
            client.publish(`v1/devices/${id_box}/request/+`, payload)
        } 
        timeoutUpdateModel = setTimeout(() => {
            $('#completeModel').removeClass('d-none')
            this.setState({
                loadingCompleteModel: false
            })
            swal("Warning!", "Timeout Call!", "warning");
        }, 10000);
    }

    checkMQTT = (id_box) => {
        client.end();
        client = mqtt.connect(HOST_WS)
        client.on('connect', function () {
            console.log('mqtt broker connected!')
            client.subscribe(`v1/devices/${id_box}/response/+`, function (err) {
                if (!err) {
                    console.log('Request command subscribed');
                }
            })
        })
        let that = this;
        client.on('message', function (topic, message) {
            var paramssh = JSON.parse(message)
            if (paramssh.method == "update_engine") {
                that.setState({
                    loadingComplete: false
                })
                clearTimeout(timeoutUpdateEngine)
                $('#complete').removeClass('d-none')
                if (paramssh.status == 1) {
                    swal("Sucess!", "Update Engine Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                } else {
                    swal("Error!", "Update Engine Fail!", "error", {
                        buttons: false,
                        timer: 1500,
                    });
                }
            }
            if (paramssh.method == "update_model") {
                that.setState({
                    loadingCompleteModel: false
                })
                clearTimeout(timeoutUpdateModel)
                $('#completeModel').removeClass('d-none')
                if (paramssh.status == 1) {
                    swal("Sucess!", "Update Model Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                } else {
                    swal("Error!", "Update Model Fail!", "error", {
                        buttons: false,
                        timer: 1500,
                    });
                }
            }
        })
    }

    UpdateCamera = async (cam, event) => {
        var form = $("#formUpdateBox")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (this.state.crrBox.company_id == null) {
                swal("Error!", "Please choose your company", "error")
            } else {
                $('#save').addClass('d-none')
                this.setState({
                    loading: true,
                });
                if (cam.id === undefined) {
                    let data = await fetch(`${HOST}/api/box/insert`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(cam)

                    }).then((response) => {
                        return response.json()
                    });
                    if (data.status === 10000) {

                        swal("Sucess!", "Add Box Sucess!", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        $('#save').removeClass('d-none');
                        var crrBox = this.state.crrBox
                        this.changeBoxId(data.data.id)
                        crrBox['id'] = data.data.id
                        this.setState({
                            loading: false,
                            display: false,
                            crrBox: crrBox
                        });
                        HOST_WS.includes("ws") && this.checkMQTT(data.data.id)
                        // return this.props.onHide();
                    } else if (data.status === 10005) {
                        swal('Warning!', "Công ty đã đạt số lượng box cho phép, không thể tạo thêm box mới. Vui lòng liên hệ với BeetInnovators để nâng cấp gói dữ liệu. Email: sales@beetinnovators.com. Hotline: +84 868 646 386", "warning");
                    }
                    else {
                        $('#save').removeClass('d-none');
                        this.setState({
                            loading: false,
                        })
                        swal("Error!", "Add Box Failed!", "error");
                    }
                } else {
                    let data = await fetch(`${HOST}/api/box/update`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': Store.getState().isLogin.access_token
                        },
                        body: JSON.stringify(cam)
                    }).then((response) => {
                        return response.json()
                    });

                    if (data.status === 10000) {
                        swal("Sucess!", "Edit Camera Success!", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        $('#save').removeClass('d-none');
                        this.setState({
                            loading: false,
                        });
                        // return this.props.onHide();
                    } else if (data.status === 10005) {
                        swal('Warning!', "Công ty đã đạt số lượng box cho phép, không thể tạo thêm box mới. Vui lòng liên hệ với BeetInnovators để nâng cấp gói dữ liệu. Email: sales@beetinnovators.com. Hotline: +84 868 646 386", "warning");
                    }
                    else {
                        $('#save').removeClass('d-none');
                        this.setState({
                            loading: false,
                        });
                        swal("Error!", "Edit Camera Failed!", "error");
                    }
                }
            }
        }
    }

    UpdateEngine = async (engine) => {
        var EngineModel = [];
        engine.engine_models.forEach((item, index) => {
            if (engine.engine_models[index].name_engine_model != "") {
                var dataOptionEngine = { engine_model_id: item.engine_model_id, name_engine_model: item.name_engine_model, threshold: item.threshold, is_main: item.is_main };
                EngineModel.push(dataOptionEngine);
            }
        });
        engine['engine_models'] = EngineModel;
        $('#saveEngine').addClass('d-none')
        this.setState({
            loadingEngine: true,
        });
        if (this.state.crrEngine.id === undefined) {
            let data = await fetch(`${HOST}/api/boxEngine/insert`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(engine)

            }).then((response) => {
                return response.json()
            });
            console.log(engine)
            if (data.status === 10000) {
                swal("Sucess!", "Add Engine Sucess!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                $('#saveEngine').removeClass('d-none')
                this.setState({
                    loadingEngine: false,
                    display_delete: false
                });
                this.getEngineInsert(this.state.crrBox.id);
            } else {
                $('#saveEngine').removeClass('d-none')
                this.setState({
                    loadingEngine: false
                });
                swal("Error!", "Add Engine Failed!", "error");
            }
        } else {
            let data = await fetch(`${HOST}/api/boxEngine/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(engine)

            }).then((response) => {
                return response.json()
            });
            if (data.status === 10000) {
                swal("Sucess!", "Edit Engine Sucess!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                $('#saveEngine').removeClass('d-none')
                this.setState({
                    loadingEngine: false
                })
                this.getEngineUpdate(engine);
            } else {
                $('#saveEngine').removeClass('d-none')
                this.setState({
                    loadingEngine: false
                });
                swal("Error!", "Edit Engine Failed!", "error");
            }
        }
    }

    getEngine = (id) => {
        fetch(`${HOST}/api/boxEngine/getByBox/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOptionEngine;
                var engineOption = [];
                data.data.forEach(e => {
                    dataOptionEngine = { value: e.id, label: e.engine.name + ' - ' + e.id, level: e.engine.level };
                    engineOption.push(dataOptionEngine);
                });
                this.setState({
                    dataOptionEngine: engineOption,
                    dataEngine: data.data,
                });
            }
        })
    }

    getEngineInsert = (id) => {
        fetch(`${HOST}/api/boxEngine/getByBox/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOptionEngine;
                var engineOption = [];
                data.data.forEach(e => {
                    dataOptionEngine = { value: e.id, label: e.engine.name + ' - ' + e.id, level: e.engine.level };
                    engineOption.push(dataOptionEngine);
                });
                var last_element = data.data[data.data.length - 1];
                var engine = this.state.crrEngine;
                engine['id'] = last_element.id;
                engine['box_engine_id'] = last_element.id;
                this.setState({
                    value_show_engine: { value: last_element.engine_id, label: last_element.engine.name + ' - ' + last_element.id, level: last_element.engine.level },
                    dataOptionEngine: engineOption,
                });
            }
        })
    }


    getEngineUpdate = (engine) => {
        fetch(`${HOST}/api/boxEngine/getByBox/${engine.box_id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOptionEngine;
                var engineOption = [];
                data.data.forEach(e => {
                    dataOptionEngine = { value: e.id, label: e.engine.name + ' - ' + e.id, level: e.engine.level };
                    engineOption.push(dataOptionEngine);
                });
                this.setState({
                    value_show_engine: { value: engine.box_engine_id, label: engine.engine_name + ' - ' + engine.box_engine_id, level: engine.engine_level },
                    dataOptionEngine: engineOption,
                });
            }
        })
    }


    AddEngine = () => {
        var crrBox = this.state.crrBox;
        this.setState({
            listEngineModel: [],
            listEngineVersion: [],
            valueShowEngine: '',
            valueShowEngineVersion: '',
            valueShowEngineModel: '',
            valueShowRTSP: '',
            valueShowAlias: '',
            valueShowApiPartner: '',
            value_show_engine: '',
            crrEngine: {
                box_engine_id: '',
                box_id: crrBox.id,
                engine_id: '',
                version_id: '',
                active: 1,
                rtsp: '',
                alias: '',
                engine_models: [
                    { engine_model_id: '', name_engine_model: '', threshold: 0.9, is_main: 1 }
                ]
            },
            changeEdit_add: false,
            display_none: false,
            display_delete: true
        })
    }


    handleChangeEngine(value) {
        var engine = this.state.crrEngine;
        engine['engine_id'] = value.value;
        engine['engine_name'] = value.label;
        engine['engine_level'] = value.level;
        engine['engine_models'] = [
            { engine_model_id: '', name_engine_model: '', threshold: 0.9, is_main: 1 }
        ];
        this.showEngineModels(value.level)
        fetch(`${HOST}/api/engineVersion/getByEngine/${value.value}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {


                var dataOptionEngineVersion;
                var engineVersionOption = [];
                data.data.forEach(e => {
                    dataOptionEngineVersion = { value: e.id, label: e.version };
                    engineVersionOption.push(dataOptionEngineVersion);
                });
                var value_Version;
                if (data.data[data.data.length - 1] == undefined) {
                    value_Version = ''
                    engine['version_id'] = '';
                } else {
                    value_Version = { value: data.data[data.data.length - 1].id, label: data.data[data.data.length - 1].version };
                    engine['version_id'] = data.data[data.data.length - 1].id;
                }

                this.setState({
                    valueShowEngineVersion: value_Version,
                    valueShowEngine: { value: value.value, label: value.label },
                    listEngineVersion: engineVersionOption,
                });
            }
        })
    }

    showEngineVersion = (id) => {
        fetch(`${HOST}/api/engineVersion/getByEngine/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOptionEngineVersion;
                var engineVersionOption = [];
                data.data.forEach(e => {
                    dataOptionEngineVersion = { value: e.id, label: e.version };
                    engineVersionOption.push(dataOptionEngineVersion);
                });
                this.setState({
                    listEngineVersion: engineVersionOption,
                });
            }
        })
    }

    showEngineModels = (level) => {
        fetch(`${HOST}/api/engineModel/getByEngineLevel/${level}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataModelOption;
                var engineModelOption = [];
                data.data.forEach(e => {
                    dataModelOption = { value: e.id, label: e.name };
                    engineModelOption.push(dataModelOption);
                });
                var engine = this.state.crrEngine;
                console.log(this.state.crrEngine )
                if (data.data.length > 0) {
                    engine['engine_models'] = [
                        { engine_model_id: data.data[0].id, name_engine_model: data.data[0].name, threshold: 0.9, is_main: 1 }
                    ];
                } else {
                    swal("Warning!", "Engine Model chưa được tạo", "warning")
                }
               
                this.setState({
                    crrEngine: engine,
                    listEngineModel: engineModelOption,
                });
                
               
            }
        })
    }

    showEngineModelsAll = (level) => {
        fetch(`${HOST}/api/engineModel/getByEngineLevel/${level}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataModelOption;
                var engineModelOption = [];
                data.data.forEach(e => {
                    dataModelOption = { value: e.id, label: e.name };
                    engineModelOption.push(dataModelOption);
                });
                this.setState({
                    listEngineModel: engineModelOption,
                });
            }
        })
    }

    handleChangeShowEngine(value) {
        fetch(`${HOST}/api/boxEngine/getById/${value.value}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.showEngineModelsAll(value.level)
                var dataOptionEngine;
                if (data.data.engine.level.length > 4) {
                    data.data.engine.name = '---- ' + data.data.engine.name;
                }
                dataOptionEngine = { value: data.data.engine.id, label: data.data.engine.name };

                var dataOptionEngineVersion;
                dataOptionEngineVersion = { value: data.data.engine_version.id, label: data.data.engine_version.version };
                this.showEngineVersion(data.data.engine.id)
                var engine = this.state.crrEngine;
                engine['engine_name'] = data.data.engine.name;
                engine['box_engine_id'] = value.value;
                engine['engine_id'] = data.data.engine_id;
                engine['active'] = data.data.active;
                engine['rtsp'] = data.data.rtsp;
                engine['alias'] = data.data.alias;
                engine['api_partner'] = data.data.api_partner;
                engine['id'] = value.value;
                engine['version_id'] = data.data.version_id;

                var dataEngineModel;
                var EngineModel = [];
                data.data.box_engine_models.forEach(e => {
                    dataEngineModel = { engine_model_id: e.model_id, name_engine_model: e.model.name, threshold: e.threshold, is_main: e.is_main };
                    EngineModel.push(dataEngineModel);
                });
                engine['engine_models'] = EngineModel;
                this.setState({
                    value_show_engine: { value: value.value, label: value.label },
                    changeEdit_add: true,
                    display_delete: false,
                    crrEngine: engine,
                    display_none: false,
                    valueShowEngineVersion: dataOptionEngineVersion,
                    valueShowEngine: dataOptionEngine,
                    valueShowRTSP: data.data.rtsp,
                    valueShowAlias: data.data.alias,
                    valueShowApiPartner: data.data.api_partner,
                    valueShowEngineModel: data.data.valueShowEngineModel,
                    dataShowEngine: data.data.box_engine_models,
                });
            }
        })
    }

    DeleteEngine = async (engine) => {
        $('#deleteEngine').addClass('d-none')
        this.setState({
            loadingDeleteEngine: true,
        });
        if (engine.box_engine_id !== undefined) {
            let data = await fetch(`${HOST}/api/boxEngine/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    'id': engine.box_engine_id
                })

            }).then((response) => {
                return response.json()
            });
            if (data.status === 10000) {
                swal("Sucess!", "Delete Engine Sucess!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                var crrBox = this.state.crrBox;
                $('#deleteEngine').removeClass('d-none')
                this.setState({
                    loadingDeleteEngine: false,
                    listEngineModel: [],
                    listEngineVersion: [],
                    valueShowEngine: '',
                    valueShowEngineVersion: '',
                    valueShowEngineModel: '',
                    valueShowRTSP: '',
                    valueShowAlias: '',
                    valueShowApiPartner: '',
                    value_show_engine: '',
                    crrEngine: {
                        box_engine_id: '',
                        box_id: crrBox.id,
                        engine_id: '',
                        version_id: '',
                        active: 1,
                        rtsp: '',
                        alias: '',
                        api_partner: '',
                        engine_models: [
                            { engine_model_id: '', name_engine_model: '', threshold: 0.9, is_main: 1 }
                        ]
                    },
                    display_delete: true,
                })
                this.getEngine(this.state.crrBox.id);
            } else {
                $('#deleteEngine').removeClass('d-none')
                this.setState({
                    loadingDeleteEngine: false,
                })
                swal("Error!", "Delete Engine Failed!", "error");
            }
        }
    }

    BoxHandle(e) {
        var camera = this.state.crrBox;
        camera[e.target.name] = e.target.value;
        this.setState({ crrBox: camera });
    }

    handleChange(value) {
        var camera = this.state.crrBox;
        camera['company_id'] = value.value;
        this.setState({
            value: value,
            crrBox: camera
        })
    }

    appendModel = () => {
        var dataShowEngine = this.state.dataShowEngine;
        var engine_model = { model_id: '', model: { id: 0, name: 'Select' }, threshold: 0.9, is_main: 1 }
        dataShowEngine.push(engine_model);

        var crrEngine = this.state.crrEngine;
        var engine_model = { engine_model_id: '', name_engine_model: '', threshold: 0.9, is_main: 1 }
        crrEngine.engine_models.push(engine_model);
        this.setState({
            dataShowEngine: dataShowEngine,
            crrEngine: crrEngine

        })
    }

    handleChangeEngineVersion(value) {
        var engine = this.state.crrEngine;
        engine['version_id'] = value.value;
        this.setState({
            crrEngine: engine,
            valueShowEngineVersion: value
        });
    }

    getTypeDevice = () => {
        fetch(`${HOST}/api/type_device/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var camera = this.state.crrBox;
                console.log(camera)
                if (camera.id_typedevice === null) {
                    camera['id_typedevice'] = data.data[0].id
                }
                var dataOption;
                var typeDeviceOption = [];
                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.name };
                    typeDeviceOption.push(dataOption);
                });
                this.setState({
                    listOptionTypeDevice: typeDeviceOption,
                    crrBox: camera
                });
            }
        })
    }

    handleChangeTypeDevice(value) {
        var camera = this.state.crrBox;
        camera['id_typedevice'] = value.value;
        this.setState({
            crrBox: camera
        })
    }

    handleChangeStartDateIn = crrDateIn => {
        var camera = this.state.crrBox;
        camera['date_in'] = moment(crrDateIn).format("YYYY-MM-DD");
        this.setState({
            crrDateIn: crrDateIn,
            crrBox: camera
        })
    }
    handleChangeStartDateOut = crrDateOut => {
        var camera = this.state.crrBox;
        camera['date_out'] = moment(crrDateOut).format("YYYY-MM-DD");
        this.setState({
            crrDateOut: crrDateOut,
            crrBox: camera
        })
    }

    engineHandle(e) {
        var engine = this.state.crrEngine;
        engine[e.target.name] = e.target.value;
        this.setState({
            crrEngine: engine,
        });
    }

    getByIdBoxStatus = (id) => {
        fetch(`${HOST}/api/box_status/get_by_id/${id}`, {
          method: "GET",
          headers: {
            Authorization: Store.getState().isLogin.access_token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            if (data.status === 10000) {
              // var camera = this.state.boxStatus;
              var dataOption;
              var typeDeviceOption = [];
              data.result.forEach((e) => {
                dataOption = {
                  box_id: e.box_id,
                  box_status: e.box_status,
                  created_time: e.created_time,
                  description: e.description,
                  id: e.id,
                  time: e.time,
                };
                typeDeviceOption.push(dataOption);
              });
              this.setState({
                boxStatus: typeDeviceOption,
              });
            }
          });
      };

      changeBoxId(value) {
        var camera = this.state.boxStatusAdd;
        camera["box_id"] = value;
        this.setState({
          boxStatusAdd: camera,
        });
      }
      handleChangeStatus(value) {
        var camera = this.state.boxStatusAdd;
        camera["status"] = value.value;
        this.setState({
          value: value,
          boxStatusAdd: camera,
        });
      }

    handleChangeEditStatus(value) {
        var camera = this.state.boxStatusEditAdd;
        camera["status"] = value.value;
        this.setState({
          boxStatusEditAdd: camera,
        });
    }

    handleChangeDate = (date) => {
        var camera = this.state.boxStatusAdd;
        camera["time"] = moment(date).format("YYYY-MM-DD HH:mm:ss");
        this.setState({
          date: date,
          boxStatusAdd: camera,
        });
    };

    handleChangeEditDate = (date) => {
        var camera = this.state.boxStatusEditAdd;
        camera["time"] = moment(date).format("YYYY-MM-DD HH:mm:ss");
        this.setState({
          boxStatusEditAdd: camera,
        });
    };

    BoxHandleStatusNote(e) {
        var camera = this.state.boxStatusAdd;
        camera["description"] = e.target.value;
        this.setState({ boxStatusAdd: camera });
      }
      BoxHandleEditStatusNote(e) {
        var camera = this.state.boxStatusEditAdd;
        camera["description"] = e.target.value;
        this.setState({ boxStatusEditAdd: camera });
      }

      editStatusById = () => {
        fetch(`${HOST}/api/box_status/insert_or_update`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Store.getState().isLogin.access_token,
          },
          body: JSON.stringify(this.state.boxStatusEditAdd),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === 10000) {
              console.log("data edit", data);
              swal("Sucess!", "Edit Sucess!", "success", {
                buttons: false,
                timer: 1500,
              });
              var boxStatus = this.state.boxStatus;
              this.setState({
                boxStatus: boxStatus,
                editStatus: false,
              });
              this.getByIdBoxStatus(this.state.crrBox.id);
            } else {
              swal("Error!", "Edit Error!", "error");
            }
          });
    };

    deleteStatusBox = (id) => {
        fetch(`${HOST}/api/box_status/delete_by_id/${id}`, {
          method: "GET",
          headers: {
            Authorization: Store.getState().isLogin.access_token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status == 10000) {
              swal("Sucess!", "Delete Sucess!", "success", {
                buttons: false,
                timer: 1500,
              });
              this.getByIdBoxStatus(this.state.crrBox.id);
            } else {
              swal("Error!", "Delete Engine Failed!", "error");
            }
          });
    };
    
    insertStatusById = (event) => {
        event.preventDefault();
        if (this.state.boxStatusAdd.status == null) {
          swal("Warning", "Please select status", "warning");
        } else if (this.state.boxStatusAdd.time == "") {
          swal("Warning", "Please Select time", "warning");
        } else {
          fetch(`${HOST}/api/box_status/insert_or_update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: Store.getState().isLogin.access_token,
            },
            body: JSON.stringify(this.state.boxStatusAdd),
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.status == 10000) {
                this.setState({
                  editStatus: false
                })
                swal("Success", "Add Status Success", "success");
                console.log("data", data);
                var boxStatus = this.state.boxStatus;
                boxStatus.unshift(data.result);
                this.setState({
                  boxStatus: boxStatus,
                  boxStatusAdd: { box_id: this.state.crrBox.id, description: "", time: moment().format("YYYY-MM-DD HH:mm:ss") },
                });
                this.getByIdBoxStatus(this.state.crrBox.id)
              } else {
                swal("Error!", "Sorry Add Status Fail. Please Try Again", "error");
              }
            });
        }
      };
    


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.getByIdBoxStatus(nextProps.cam.id); // setBoxId mỗi khi edit
            this.changeBoxId(nextProps.cam.id);
            this.setState({
              editStatus: false,
            boxStatusAdd: {
                ...this.state.boxStatusAdd,
                description: "",
                status: "",
                time: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
        });
            if (HOST_WS.includes("ws") && nextProps.cam.id !== undefined) {
                this.checkMQTT(nextProps.cam.id)
            }

            if (nextProps.cam.view_url_public == null) {
                nextProps.cam.view_url_public = ''
            }
            if (nextProps.cam.view_url_local == null) {
                nextProps.cam.view_url_local = ''
            }
            var dataOptionCompany;
            if (nextProps.cam.company != null) {
                dataOptionCompany = {
                    value: nextProps.cam.company.id,
                    label: nextProps.cam.company.name + nextProps.cam.company.code,
                }
            }


            var dataOption;
            var engineOption = [];
            nextProps.listengine.forEach(e => {
                dataOption = { value: e.id, label: e.name, level: e.level };
                engineOption.push(dataOption);
            });

            var dataModelOption;
            var engineModelOption = [];

            this.getEngine(nextProps.cam.id);
            var engine = this.state.crrEngine;
            engine['box_id'] = nextProps.cam.id;
            this.setState({
                value_show_engine: '',
                crrEngine: engine,
                display_none: true,
                listEngine: engineOption,
                value: dataOptionCompany,
                crrBox: nextProps.cam,
                idmodelEdit: nextProps.modeledit,
                listCompany: nextProps.com,
                listCompanySearch: nextProps.listeditsearch,
                valueModel: nextProps.modeledit,
            });

            this.getTypeDevice()
        }
    }

    //WARNING! To be deprecated in React v17. Use componentDidUpdate instead.
    componentWillUpdate(nextProps, nextState) {
        if (HOST_WS.includes("ws") && nextProps.show === false) {
            client.end();
        }
    }
    // On file select (from the pop up) 
    // onFileChange = event => {

    //     // Update the state 
    //     this.setState({ selectedFile: event.target.files[0] });

    // };

    // // On file upload (click the upload button) 
    // onFileUpload = (e) => {
    //     e.preventDefault()
    //     // Create an object of formData 
    //     const formData = new FormData();

    //     // Update the formData object 
    //     formData.append(
    //         "myFile",
    //         this.state.selectedFile,
    //         this.state.selectedFile.name
    //     );

    //     // Details of the uploaded file 
    //     console.log(this.state.selectedFile);

    //     // Request made to the backend api 
    //     // Send formData object 
    //     axios.post("api/uploadfile", formData);
    // };

    // // File content to be displayed after 
    // // file upload is complete 

    // handleFiles = (files) => {
    //     var file1 = files.base64;
    //     var scanFile = []
    //     for (let i = 0; i < file1.length; i++) {
    //         scanFile.push(file1[i])
    //         this.setState({
    //             scanFile: file1[i]
    //         })
    //     }
    //     console.log(scanFile);


    // }
    // fileData = () => {
    //     if (this.state.scanFile) {
    //         for (let i = 0; i <= this.state.scanFile.length; i++) {
    //             const element = this.state.scanFile[i];
    //             return (
    //                 <div>
    //                     <img src={element} height="120" width="120" />
    //                 </div>
    //             );
    //         }

    //     }
    // };
    render() {
        console.log(this.state.crrBox);

        let click_handle = (event) => {
            this.UpdateCamera(this.state.crrBox, event);
            if (this.state.crrBox.company_id == null) {
                swal("fail")
            };
        };
        if (this.state.crrEngine.active === 1) {
            this.state.isCheckedEngine = true
        }
        if (this.state.crrEngine.active === 0) {
            this.state.isCheckedEngine = false
        }

        if (this.state.crrBox.active === 1) {
            this.state.isChecked = true
        }
        if (this.state.crrBox.active === 0) {
            this.state.isChecked = false
        }
        if (this.state.crrBox.active === null) {
            this.state.isChecked = true
        }


        var display_none;
        if (this.state.display_none == true) {
            display_none = 'd-none'
        } else {
            display_none = ''
        }

        var display_delete;
        if (this.state.display_delete == true) {
            display_delete = 'd-none'
        } else {
            display_delete = ''
        }
        let pnEngine = this.state.crrEngine.engine_models.map((e, i) => {
            var main;
            if (e.is_main == 1) {
                main = { value: e.is_main, label: 'Is Main' }
            }
            if (e.is_main == 0) {
                main = { value: e.is_main, label: 'Not Is Main' }
            }

            var value_models;
            if (e.engine_model_id == "") {
                value_models = "";
            } else {
                value_models = { value: e.engine_model_id, label: e.name_engine_model }
            }
            return (
                <tr key={i}>
                    <td>
                        <Select
                            onChange={(value) => {
                                var crrEngine = this.state.crrEngine;
                                var error = true;
                                crrEngine.engine_models.forEach((item) => {
                                    if (item.name_engine_model.indexOf(value.label) !== -1) {
                                        error = false;
                                        swal("Warning!", "Model must exist!", "warning");
                                    }
                                })
                                if (error != false) {
                                    crrEngine.engine_models[i].engine_model_id = value.value;
                                    crrEngine.engine_models[i].name_engine_model = value.label;
                                }
                                this.setState({
                                    crrEngine: crrEngine,
                                });
                            }}
                            options={this.state.listEngineModel}
                            placeholder="Select Models"
                            value={value_models}
                        />
                    </td>
                    <td>
                        <input
                            onChange={(value) => {
                                var crrEngine = this.state.crrEngine;
                                crrEngine.engine_models[i].threshold = value.target.value;
                                this.setState({
                                    crrEngine: crrEngine
                                });
                            }}
                            value={e.threshold} type="number" min='0' max='1' className="form-control m-input" id="Threshold" name='threshold' placeholder="Threshold" />
                    </td>
                    <td>
                        <Select
                            onChange={(value) => {
                                var crrEngine = this.state.crrEngine;
                                crrEngine.engine_models[i].is_main = value.value;
                                this.setState({
                                    crrEngine: crrEngine
                                });
                            }}
                            options={this.state.listMain}
                            placeholder="Select Main"
                            value={main}
                        />
                    </td>
                    <td>
                        <button
                            onClick={(e) => {
                                var crrEngine = this.state.crrEngine;
                                if (crrEngine.engine_models.length > 1) {
                                    crrEngine.engine_models.splice(i, 1)
                                } else {
                                    swal("Warning!", "Model must exist!", "warning");
                                }
                                this.setState({
                                    crrEngine: crrEngine,
                                });
                            }}
                            className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"><b><i className="la la-remove"></i></b></button>
                    </td>
                </tr>
            )
        })

        return (

            <Modal
                {...this.props}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
            >
        {this.state.crrBox.id === undefined ? (
        <div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <form id="formUpdateBox">
            <div className="col-xl-12">
              <div className="m-widget14 row">
                <div className="form-group m-form__group col-xl-12">
                  <label htmlFor="type_of_box">ID Box</label>
                  <div>
                    <b>
                      {this.state.crrBox.id === undefined
                        ? ""
                        : this.state.crrBox.id}
                    </b>
                  </div>
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="Port_Local">Device</label>
                  <Select
                    options={this.state.listOptionTypeDevice}
                    onChange={(value) => this.handleChangeTypeDevice(value)}
                    value={this.state.listOptionTypeDevice.filter((option) => {
                      return this.state.crrBox.id_typedevice === option.value;
                    })}
                    placeholder="Select Type"
                  />
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="Port_Local">
                    Company <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={this.state.listCompanySearch}
                    value={this.state.listCompanySearch.filter((option) => {
                      return this.state.crrBox.company_id === option.value;
                    })}
                    onChange={(value) => this.handleChange(value)}
                  />
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="type_of_box">Type of Box</label>
                  <input
                    className="form-control m-input"
                    id="type_of_box"
                    name="type_of_box"
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="type_of_box"
                    value={
                      this.state.crrBox.type_of_box === null
                        ? ""
                        : this.state.crrBox.type_of_box
                    }
                  />
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="type_of_camera">Type of Camera</label>
                  <input
                    className="form-control m-input"
                    id="type_of_camera"
                    name="type_of_camera"
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="type_of_camera"
                    value={
                      this.state.crrBox.type_of_camera === null
                        ? ""
                        : this.state.crrBox.type_of_camera
                    }
                  />
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="Mac_Address">
                    Mac Address <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control m-input"
                    id="Mac_Address"
                    name="mac_address"
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="Mac Address"
                    value={
                      this.state.crrBox.mac_address === null
                        ? ""
                        : this.state.crrBox.mac_address
                    }
                    required
                  />
                </div>
                {/* <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="view_url_local">URL Local</label>
                                    <input className="form-control m-input" id="view_url_local" name='view_url_local' onChange={e => this.BoxHandle(e)} placeholder="URL Local" value={this.state.crrBox.view_url_local === null ? '' : this.state.crrBox.view_url_local} />
                                </div> */}
                {/* <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="Ip_Address_Local">Active</label>
                                    <div className="pt-2 pb-2">
                                        <Switch
                                            onChange={() => {
                                                var active;
                                                if (!this.state.isChecked == true) {
                                                    active = 1
                                                } else {
                                                    active = 0
                                                }
                                                var camera = this.state.crrBox;
                                                camera['active'] = active;
                                                this.setState({
                                                    crrBox: camera,
                                                    isChecked: !this.state.isChecked
                                                });
                                            }}
                                            checked={this.state.isChecked}
                                            handleDiameter={17}
                                            offColor="#969696"
                                            onColor="#00c5dc"
                                            offHandleColor="#ffffff"
                                            onHandleColor="#08f"
                                            height={25}
                                            width={55}
                                            className="react-switch"
                                            id="small-radius-switch"
                                        />
                                    </div>
                                </div> */}
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="serial">
                    Serial <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control m-input"
                    id="serial"
                    name="serial"
                    value={
                      this.state.crrBox.serial === null
                        ? ""
                        : this.state.crrBox.serial
                    }
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="Serial"
                    required
                  />
                </div>
                <div className="form-group m-form__group col-xl-6">
                  <label htmlFor="code">Code</label>
                  <input
                    className="form-control m-input"
                    id="code"
                    name="code"
                    value={
                      this.state.crrBox.code === null
                        ? ""
                        : this.state.crrBox.code
                    }
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="Code"
                  />
                </div>
                {/* <div className="form-group m-form__group col-xl-6">
                                    <label>Date In</label>
                                    <div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6"
                                            value={this.state.crrBox.date_in === null ? '' : moment(this.state.crrBox.date_in).format("YYYY-MM-DD")}
                                            onChange={this.handleChangeStartDateIn}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                </div> */}
                {/* <div className="form-group m-form__group col-xl-6">
                  <label>Date Out</label>
                  <div>
                    <DatePicker
                      className="form-control m-input m_datetimepicker_6"
                      value={
                        this.state.crrBox.date_out === null
                          ? ""
                          : moment(this.state.crrBox.date_out).format(
                              "YYYY-MM-DD"
                            )
                      }
                      onChange={this.handleChangeStartDateOut}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Date"
                    />
                  </div>
                </div> */}
                <div className="form-group m-form__group col-xl-12">
                  <label htmlFor="Description">Description</label>
                  <textarea
                    rows="4"
                    className="form-control m-input"
                    id="Description"
                    name="description"
                    onChange={(e) => this.BoxHandle(e)}
                    placeholder="Description"
                    value={
                      this.state.crrBox.description === null
                        ? ""
                        : this.state.crrBox.description
                    }
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="form-group m-form__group col-xl-12">
            {this.state.crrBox.id !== undefined ? (
              <PicturesWall
                box_id={this.state.crrBox.id}
                file={this.state.crrBox.box_evidences}
              />
            ) : (
              ""
            )}
          </div>
          <div className="text-center pb-4">
            <Button
              variant="accent"
              onClick={click_handle}
              disabled={this.state.loading}
            >
              <span id="save">Save Box</span>
              <PulseLoader
                css={{
                  display: "block",
                  margin: "0 auto",
                  borderColor: "red",
                }}
                sizeUnit={"px"}
                size={10}
                color={"#fff"}
                loading={this.state.loading}
              />
            </Button>
            {this.state.crrBox.id !== undefined ? (
              <>
                <Button
                  variant="success"
                  className="ml-2"
                  onClick={() => {
                    this.conectMQTT(this.state.crrBox.id);
                  }}
                >
                  <span id="complete">Update Engine</span>
                  <PulseLoader
                    css={{
                      display: "block",
                      margin: "0 auto",
                      borderColor: "red",
                    }}
                    sizeUnit={"px"}
                    size={10}
                    color={"#fff"}
                    loading={this.state.loadingComplete}
                  />
                </Button>
                <Button
                  variant="success"
                  className="ml-2"
                  onClick={() => {
                    this.conectMQTTModel(this.state.crrBox.id);
                  }}
                >
                  <span id="completeModel">Update Model</span>
                  <PulseLoader
                    css={{
                      display: "block",
                      margin: "0 auto",
                      borderColor: "red",
                    }}
                    sizeUnit={"px"}
                    size={10}
                    color={"#fff"}
                    loading={this.state.loadingCompleteModel}
                  />
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        {this.state.crrBox.id !== undefined ? (
          <Modal.Footer>
            <div className={"col-xl-12 "}>
              <div className="m-widget14 pt-0 row" id="count_engine">
                <div className="row col-xl-12">
                  <div className="col-xl-4 pr-0">
                    <Select
                      onChange={(value) => this.handleChangeShowEngine(value)}
                      options={this.state.dataOptionEngine}
                      value={this.state.value_show_engine}
                      placeholder="Select Box Engine"
                    />
                  </div>
                  <div className="col-xl-3 pl-1">
                    <button
                      onClick={() => {
                        this.AddEngine();
                      }}
                      style={{
                        height: "38px",
                        width: "38px",
                        marginLeft: "5px",
                      }}
                      className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only"
                      title="Add Engine"
                    >
                      <i className="la la-plus"></i>
                    </button>
                  </div>
                </div>
                <div
                  className={
                    "form-group m-form__group pt-4 col-xl-12 engine_box " +
                    display_none
                  }
                  id="engine_box"
                >
                  <div className="row">
                    <div className="col-xl-10">
                      <label htmlFor="Description">Engine</label>
                      <div className="row pb-3 pt-1">
                        <form
                          id="formAddEngine"
                          style={{ display: "contents" }}
                        >
                          <div className="col-xl-6">
                            <Select
                              onChange={(value) =>
                                this.handleChangeEngine(value)
                              }
                              options={this.state.listEngine}
                              placeholder="Select Engine"
                              value={this.state.valueShowEngine}
                            />
                          </div>
                        </form>
                        <div className="col-xl-6 pl-0">
                          <Select
                            onChange={(value) =>
                              this.handleChangeEngineVersion(value)
                            }
                            options={this.state.listEngineVersion}
                            placeholder="Select Version"
                            value={this.state.valueShowEngineVersion}
                            className="selectVersion"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2">
                      <label htmlFor="Ip_Address_Local">Active Engine</label>
                      <div style={{ paddingTop: "11px" }}>
                        <Switch
                          onChange={() => {
                            var active;
                            if (!this.state.isCheckedEngine == true) {
                              active = 1;
                            } else {
                              active = 0;
                            }
                            var engine = this.state.crrEngine;
                            engine["active"] = active;
                            this.setState({
                              crrEngine: engine,
                              isCheckedEngine: !this.state.isCheckedEngine,
                            });
                          }}
                          checked={this.state.isCheckedEngine}
                          handleDiameter={17}
                          offColor="#969696"
                          onColor="#00c5dc"
                          offHandleColor="#ffffff"
                          onHandleColor="#08f"
                          height={25}
                          width={55}
                          className="react-switch"
                          id="small-radius-switch"
                        />
                      </div>
                    </div>
                  </div>
                  <label htmlFor="Description">Alias</label>
                  <div className="row pb-3 pt-1">
                    <div className="col-xl-12">
                      <input
                        value={
                          this.state.crrEngine.alias === null
                            ? ""
                            : this.state.crrEngine.alias
                        }
                        className="form-control m-input"
                        id="alias"
                        name="alias"
                        onChange={(e) => this.engineHandle(e)}
                        placeholder="Alias"
                      />
                    </div>
                  </div>
                  <label htmlFor="Description">RTSP</label>
                  <div className="row pb-3 pt-1">
                    <div className="col-xl-12">
                      <input
                        value={
                          this.state.crrEngine.rtsp === null
                            ? ""
                            : this.state.crrEngine.rtsp
                        }
                        className="form-control m-input"
                        id="RTSP"
                        name="rtsp"
                        onChange={(e) => this.engineHandle(e)}
                        placeholder="RTSP"
                      />
                    </div>
                  </div>
                  {this.state.crrEngine.engine_name == "Reva" ? (
                    <div>
                      <label htmlFor="api_partner">Api Partner</label>
                      <div className="row pb-3 pt-1">
                        <div className="col-xl-12">
                          <input
                            value={
                              this.state.crrEngine.api_partner === null
                                ? ""
                                : this.state.crrEngine.api_partner
                            }
                            className="form-control m-input"
                            id="api_partner"
                            name="api_partner"
                            onChange={(e) => this.engineHandle(e)}
                            placeholder="Api Partner"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-nowrap" id="table_devices">
                    <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                      <thead>
                        <tr>
                          <th style={{ verticalAlign: "middle" }}>Models</th>
                          <th
                            style={{ verticalAlign: "middle", width: "200px" }}
                          >
                            Threshold
                          </th>
                          <th style={{ verticalAlign: "middle" }}>Main</th>
                          <th
                            style={{ verticalAlign: "middle", width: "80px" }}
                          >
                            Action
                            <button
                              onClick={() => {
                                if (this.state.listEngineModel.length == 1) {
                                  swal(
                                    "Warning!",
                                    "This engine can only create 1 model!",
                                    "warning"
                                  );
                                } else {
                                  this.appendModel();
                                }
                              }}
                              style={{
                                height: "25px",
                                width: "25px",
                                marginLeft: "5px",
                              }}
                              className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only"
                            >
                              <i className="la la-plus"></i>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="append_body">{pnEngine}</tbody>
                    </table>
                  </div>
                  <div className="text-center p-3">
                    <Button
                      variant="accent"
                      onClick={() => this.UpdateEngine(this.state.crrEngine)}
                      disabled={this.state.loadingEngine}
                    >
                      <span id="saveEngine">Save Engine</span>
                      <PulseLoader
                        css={{
                          display: "block",
                          margin: "0 auto",
                          borderColor: "red",
                        }}
                        sizeUnit={"px"}
                        size={10}
                        color={"#fff"}
                        loading={this.state.loadingEngine}
                      />
                    </Button>
                    <Button
                      className={"btn ml-2 btn-danger " + display_delete}
                      disabled={this.state.loadingDeleteEngine}
                      onClick={(v) => {
                        v.preventDefault();
                        swal({
                          title: "Are you sure?",
                          text:
                            "Are you sure that you want to delete Engine " +
                            this.state.crrEngine.box_engine_id,
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            this.DeleteEngine(this.state.crrEngine);
                          }
                        });
                      }}
                    >
                      <span id="deleteEngine">Delete Engine</span>
                      <PulseLoader
                        css={{
                          display: "block",
                          margin: "0 auto",
                          borderColor: "red",
                        }}
                        sizeUnit={"px"}
                        size={10}
                        color={"#fff"}
                        loading={this.state.loadingDeleteEngine}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Footer>
        ) : (
          ""
        )}
      </div>
            ):(

            <Tabs defaultActiveKey="home" id="controlled-tab-example" className="nav-modal">
                <Tab eventKey="home" title={this.state.crrBox.id == undefined ? "" : `Box ID: ${this.state.crrBox.id}`} className='nav-item'>
                <a className='la la-remove absolute' onClick={this.props.onHide}></a>
                
                <Modal.Body className="pb-0">

                    <form id="formUpdateBox">
                        <div className="col-xl-12">
                        
                            <div className="m-widget14 pt-0 row">
                                <div className="form-group m-form__group col-xl-12">
                                    <label htmlFor="type_of_box">ID Box</label>
                                    <div><b>{this.state.crrBox.id === undefined ? '' : this.state.crrBox.id}</b></div>
                                </div>
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="Port_Local">Device</label>
                                    <Select
                                        options={this.state.listOptionTypeDevice}
                                        onChange={value => this.handleChangeTypeDevice(value)}
                                        value={
                                            this.state.listOptionTypeDevice.filter((option) => {
                                                return this.state.crrBox.id_typedevice === option.value
                                            })
                                        }
                                        placeholder="Select Type"
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-6"  >
                                    <label htmlFor="Port_Local">Company <span className="text-danger">*</span></label>
                                    <Select
                                        options={this.state.listCompanySearch}
                                        value={
                                            this.state.listCompanySearch.filter((option) => {
                                                return this.state.crrBox.company_id === option.value
                                            })
                                        }
                                        onChange={value => this.handleChange(value)}
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="type_of_box">Type of Box</label>
                                    <input className="form-control m-input" id="type_of_box" name='type_of_box' onChange={e => this.BoxHandle(e)} placeholder="type_of_box" value={this.state.crrBox.type_of_box === null ? '' : this.state.crrBox.type_of_box} />
                                </div>
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="type_of_camera">Type of Camera</label>
                                    <input className="form-control m-input" id="type_of_camera" name='type_of_camera' onChange={e => this.BoxHandle(e)} placeholder="type_of_camera" value={this.state.crrBox.type_of_camera === null ? '' : this.state.crrBox.type_of_camera} />
                                </div>
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="Mac_Address">Mac Address <span className="text-danger">*</span></label>
                                    <input className="form-control m-input" id="Mac_Address" name='mac_address' onChange={e => this.BoxHandle(e)} placeholder="Mac Address" value={this.state.crrBox.mac_address === null ? '' : this.state.crrBox.mac_address} required/>
                                </div>
                                {/* <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="view_url_public">URL Public</label>
                                    <input className="form-control m-input" id="view_url_public" name='view_url_public' onChange={e => this.BoxHandle(e)} placeholder="URL Public" value={this.state.crrBox.view_url_public === null ? '' : this.state.crrBox.view_url_public} />
                                </div> */}
                                {/* <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="view_url_local">URL Local</label>
                                    <input className="form-control m-input" id="view_url_local" name='view_url_local' onChange={e => this.BoxHandle(e)} placeholder="URL Local" value={this.state.crrBox.view_url_local === null ? '' : this.state.crrBox.view_url_local} />
                                </div> */}
                                
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="serial">Serial <span className="text-danger">*</span></label>
                                    <input className="form-control m-input" id="serial" name='serial' value={this.state.crrBox.serial === null ? '' : this.state.crrBox.serial} onChange={e => this.BoxHandle(e)} placeholder="Serial" required/>
                                </div>
                                <div className="form-group m-form__group col-xl-6">
                                    <label htmlFor="code">Code</label>
                                    <input className="form-control m-input" id="code" name='code' value={this.state.crrBox.code === null ? '' : this.state.crrBox.code} onChange={e => this.BoxHandle(e)} placeholder="Code" />
                                </div>
                                <div className="form-group m-form__group col-xl-12">
                                    <label htmlFor="Description">Description</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" name='description' onChange={e => this.BoxHandle(e)} placeholder="Description" value={this.state.crrBox.description === null ? '' : this.state.crrBox.description} />
                                </div>

                            </div>
                        </div>
                    </form>
                    <div className="form-group m-form__group col-xl-12">
                        {
                            this.state.crrBox.id !== undefined
                                ?
                                <PicturesWall box_id={this.state.crrBox.id} file={this.state.crrBox.box_evidences}/>
                                : ""
                        }
                    </div>
                    <div className="text-center pb-4">
                        <Button variant="accent" onClick={click_handle} disabled={this.state.loading}>
                            <span id="save">Save Box</span>
                            <PulseLoader
                                css={{
                                    'display': 'block',
                                    'margin': '0 auto',
                                    'borderColor': 'red',
                                }}
                                sizeUnit={"px"}
                                size={10}
                                color={'#fff'}
                                loading={this.state.loading}
                            />
                        </Button>
                        {
                            this.state.crrBox.id !== undefined
                                ?
                                <>
                                    <Button variant="success" className="ml-2" onClick={() => { this.conectMQTT(this.state.crrBox.id) }}>
                                        <span id="complete">Update Engine</span>
                                        <PulseLoader
                                            css={{
                                                'display': 'block',
                                                'margin': '0 auto',
                                                'borderColor': 'red',
                                            }}
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#fff'}
                                            loading={this.state.loadingComplete}
                                        />
                                    </Button>
                                    <Button variant="success" className="ml-2" onClick={() => { this.conectMQTTModel(this.state.crrBox.id) }}>
                                        <span id="completeModel">Update Model</span>
                                        <PulseLoader
                                            css={{
                                                'display': 'block',
                                                'margin': '0 auto',
                                                'borderColor': 'red',
                                            }}
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#fff'}
                                            loading={this.state.loadingCompleteModel}
                                        />
                                    </Button>
                                </>
                                :
                                ""
                        }
                    </div>
                </Modal.Body>             
                {
                    this.state.crrBox.id !== undefined
                        ?
                        <Modal.Footer>
                            <div className={"col-xl-12 "} >
                                <div className="m-widget14 pt-0 row" id="count_engine">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4 pr-0">
                                            <Select
                                                onChange={value => this.handleChangeShowEngine(value)}
                                                options={this.state.dataOptionEngine}
                                                value={this.state.value_show_engine}
                                                placeholder="Select Box Engine"
                                            />
                                        </div>
                                        <div className="col-xl-3 pl-1">
                                            <button
                                                onClick={() => {
                                                    this.AddEngine()
                                                }}
                                                style={{ 'height': '38px', 'width': '38px', 'marginLeft': '5px' }} className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only" title="Add Engine">
                                                <i className="la la-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={"form-group m-form__group pt-4 col-xl-12 engine_box " + display_none} id="engine_box">
                                        <div className="row">
                                            <div className="col-xl-10">
                                                <label htmlFor="Description">Engine</label>
                                                <div className="row pb-3 pt-1">
                                                    <form id="formAddEngine" style={{ 'display': 'contents' }}>
                                                        <div className="col-xl-6">
                                                            <Select
                                                                onChange={value => this.handleChangeEngine(value)}
                                                                options={this.state.listEngine}
                                                                placeholder="Select Engine"
                                                                value={this.state.valueShowEngine}
                                                            />
                                                        </div>
                                                    </form>
                                                    <div className="col-xl-6 pl-0">
                                                        <Select
                                                            onChange={value => this.handleChangeEngineVersion(value)}
                                                            options={this.state.listEngineVersion}
                                                            placeholder="Select Version"
                                                            value={this.state.valueShowEngineVersion}
                                                            className="selectVersion"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2">
                                                <label htmlFor="Ip_Address_Local">Active Engine</label>
                                                <div style={{ 'paddingTop': '11px' }}>
                                                    <Switch
                                                        onChange={() => {
                                                            var active;
                                                            if (!this.state.isCheckedEngine == true) {
                                                                active = 1
                                                            } else {
                                                                active = 0
                                                            }
                                                            var engine = this.state.crrEngine;
                                                            engine['active'] = active;
                                                            this.setState({
                                                                crrEngine: engine,
                                                                isCheckedEngine: !this.state.isCheckedEngine
                                                            });
                                                        }}
                                                        checked={this.state.isCheckedEngine}
                                                        handleDiameter={17}
                                                        offColor="#969696"
                                                        onColor="#00c5dc"
                                                        offHandleColor="#ffffff"
                                                        onHandleColor="#08f"
                                                        height={25}
                                                        width={55}
                                                        className="react-switch"
                                                        id="small-radius-switch"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="Description">Alias</label>
                                        <div className="row pb-3 pt-1">
                                            <div className="col-xl-12">
                                                <input value={this.state.crrEngine.alias === null ? '' : this.state.crrEngine.alias} className="form-control m-input" id="alias" name='alias' onChange={e => this.engineHandle(e)} placeholder="Alias" />
                                            </div>
                                        </div>
                                        <label htmlFor="Description">RTSP</label>
                                        <div className="row pb-3 pt-1">
                                            <div className="col-xl-12">
                                                <input value={this.state.crrEngine.rtsp === null ? '' : this.state.crrEngine.rtsp} className="form-control m-input" id="RTSP" name='rtsp' onChange={e => this.engineHandle(e)} placeholder="RTSP" />
                                            </div>
                                        </div>
                                        {
                                            this.state.crrEngine.engine_name == 'Reva' ? (
                                                <div>
                                                    <label htmlFor="api_partner">Api Partner</label>
                                                    <div className="row pb-3 pt-1">
                                                        <div className="col-xl-12">
                                                            <input value={this.state.crrEngine.api_partner === null ? '' : this.state.crrEngine.api_partner} className="form-control m-input" id="api_partner" name='api_partner' onChange={e => this.engineHandle(e)} placeholder="Api Partner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }

                                        <div className="text-nowrap" id="table_devices">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Models</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '200px' }}>Threshold</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Main</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>Action
                                                    <button
                                                                onClick={() => {
                                                                    if (this.state.listEngineModel.length == 1) {
                                                                        swal("Warning!", "This engine can only create 1 model!", "warning");
                                                                    } else {
                                                                        this.appendModel()
                                                                    }
                                                                }}
                                                                style={{ 'height': '25px', 'width': '25px', 'marginLeft': '5px' }} className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only">
                                                                <i className="la la-plus"></i>
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="append_body">
                                                    {pnEngine}
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className="text-center p-3">
                                            <Button variant="accent" onClick={() => this.UpdateEngine(this.state.crrEngine)} disabled={this.state.loadingEngine}>
                                                <span id="saveEngine">Save Engine</span>
                                                <PulseLoader
                                                    css={{
                                                        'display': 'block',
                                                        'margin': '0 auto',
                                                        'borderColor': 'red',
                                                    }}
                                                    sizeUnit={"px"}
                                                    size={10}
                                                    color={'#fff'}
                                                    loading={this.state.loadingEngine}
                                                />
                                            </Button>
                                            <Button className={"btn ml-2 btn-danger " + display_delete} disabled={this.state.loadingDeleteEngine} onClick={v => {
                                                v.preventDefault();
                                                swal({
                                                    title: "Are you sure?",
                                                    text: "Are you sure that you want to delete Engine " + this.state.crrEngine.box_engine_id,
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                })
                                                    .then(willDelete => {
                                                        if (willDelete) {
                                                            this.DeleteEngine(this.state.crrEngine);
                                                        }
                                                    });

                                            }}>
                                                <span id="deleteEngine">Delete Engine</span>
                                                <PulseLoader
                                                    css={{
                                                        'display': 'block',
                                                        'margin': '0 auto',
                                                        'borderColor': 'red',
                                                    }}
                                                    sizeUnit={"px"}
                                                    size={10}
                                                    color={'#fff'}
                                                    loading={this.state.loadingDeleteEngine}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                        :
                        ""
                }
                </Tab>
                <Tab eventKey="status" title="Status" className='nav-item'>
                <a className='la la-remove absolute' onClick={this.props.onHide}></a>
                <form id="formUpdateBox">
                    <div className="col-xl-12">
                        <div className="m-widget14 pt-0 row">
                        <div className="form-group m-form__group col-xl-12">
                                </div>
                                <div className="form-group m-form__group col-xl-4">
                                  <label>Date</label>
                                  <div className="group-input-icon">
                                    <DatePicker
                                      showTimeInput
                                      className="form-control m-input m_datetimepicker_6"
                                      selected={this.state.boxStatusAdd.time === null ? "" : this.state.boxStatusAdd.time === "" ? "" : new Date(this.state.boxStatusAdd.time)}
                                      onChange={this.handleChangeDate}
                                      // customTimeInput={}
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      placeholderText="Select date"
                                      maxDate={new Date()}
                                    />
                                    <div className="icon-input">
                                      <a className="flaticon-event-calendar-symbol"></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group m-form__group col-xl-3">
                                  <label htmlFor="Port_Local">
                                    Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={this.state.statusArray}
                                    value={this.state.statusArray.filter(
                                      (option) => {
                                        return (
                                          this.state.boxStatusAdd.status ===
                                          option.value
                                        );
                                      }
                                    )}
                                    onChange={(value) =>
                                      this.handleChangeStatus(value)
                                    }
                                  />
                                </div>
                                <div className="form-group m-form__group col-xl-5">
                                  <label>Note</label>
                                  <div className="group-input-icon">
                                    <input
                                      className="form-control m-input"
                                      id="note"
                                      name="note"
                                      value={
                                        this.state.boxStatusAdd.description ===
                                        null
                                          ? ""
                                          : this.state.boxStatusAdd.description
                                      }
                                      onChange={(e) =>
                                        this.BoxHandleStatusNote(e)
                                      }
                                      placeholder="Note"
                                      autoComplete=""
                                    />
                                  </div>
                                </div>
                                <div className="icon-input col-xl-1">
                                  <button
                                    onClick={this.insertStatusById}
                                    class="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air"
                                  >
                                    <span>Add Status</span>
                                  </button>
                                </div>
                        </div>
                        {this.state.crrBox.id == null ? null : (
                  <div className="table-responsive modal-status text-nowrap">
                    <table class="table min-width-table">
                      <thead>
                        <tr>
                          <th className="form-group m-form__group col-xl-3">
                            Date
                          </th>
                          <th className="form-group m-form__group col-xl-3">
                            Status
                          </th>
                          <th className="form-group m-form__group col-xl-4">
                            Note
                          </th>
                          <th className="form-group m-form__group col-xl-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.boxStatus.length > 0
                          ? this.state.boxStatus.map((item, index) => {
                              return (
                                <tr>
                                  <td>
                                    {this.state.editStatus &&
                                    this.state.indexEdit === index ? (
                                      <DatePicker
                                        showTimeInput
                                        className="form-control m-input m_datetimepicker_4"
                                        selected={moment(this.state.boxStatusEditAdd.time).format("YYYY-MM-DD HH:mm:ss") === null ? "" : moment(this.state.boxStatusEditAdd.time).format("YYYY-MM-DD HH:mm:ss") === "" ? "" : new Date(moment(this.state.boxStatusEditAdd.time).format("YYYY-MM-DD HH:mm:ss"))}
                                        onChange={this.handleChangeEditDate}
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        // maxDate={new Date()}
                                      />
                                    ) : (
                                      <div>{moment(item.time).format("YYYY-MM-DD HH:mm:ss")}</div>
                                    )}
                                  </td>
                                  <td>
                                    {this.state.editStatus &&
                                    this.state.indexEdit === index ? (
                                      <Select
                                        options={this.state.statusArray}
                                        value={this.state.statusArray.filter(
                                          (option) => {
                                            return (
                                              this.state.boxStatusEditAdd
                                                .status === option.value
                                            );
                                          }
                                        )}
                                        onChange={(value) =>
                                          this.handleChangeEditStatus(value)
                                        }
                                      />
                                    ) : (
                                      <div>
                                        {this.state.statusArray.map(
                                          (option) => {
                                            if (
                                              option.value == item.box_status
                                            ) {
                                              return <div className="item-status-list-box"><span style={{backgroundColor: option.backgroundColor, color:"white", textAlign: "center", paddingTop: 3, paddingBottom:3, paddingLeft: 10, paddingRight: 10, fontSize: 14, fontWeight: 400  }}>{option.label}</span></div>;
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {this.state.editStatus &&
                                    this.state.indexEdit === index ? (
                                      <textarea
                                        className="textarea"
                                        value={
                                          this.state.boxStatusEditAdd
                                            .description
                                        }
                                        onChange={(e) =>
                                          this.BoxHandleEditStatusNote(e)
                                        }
                                      />
                                    ) : (
                                      <div>{item.description}</div>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      // display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {this.state.editStatus &&
                                    this.state.indexEdit === index ? (
                                      <button
                                        type="button"
                                        className="m-portlet__nav-link btn m-btn m-btn--hover-success m-btn--icon m-btn--icon-only m-btn--pill"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.editStatusById();
                                        }}
                                        title="Save"
                                        data-placement="top"
                                      >
                                        <a className="la la-save"></a>
                                      </button>
                                    ) : (
                                      <button
                                        class="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                        data-tip="true"
                                        data-for="Edit"
                                        currentitem="false"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState({
                                            boxStatusEditAdd: {
                                              box_id: this.state.crrBox.id,
                                              id: item.id,
                                              status: item.box_status,
                                              description: item.description,
                                              time: moment(item.time).format("YYYY-MM-DD HH:mm:ss"),
                                            },
                                            editStatus: true,
                                            indexEdit: index,
                                          });
                                        }}
                                      >
                                        <a className="la la-edit"></a>
                                      </button>
                                    )}

                                    {/* <div
                                    class="__react_component_tooltip place-top type-dark"
                                    id="Edit"
                                    data-id="tooltip"
                                    style="left: 1135px; top: 109px;"
                                  >
                                    <span>Edit</span>
                                  </div> */}
                                  {this.state.editStatus == true && this.state.indexEdit === index ? (
                                    <button
                                      class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                      data-tip="true"
                                      data-for="Delete"
                                      currentitem="false"
                                      onClick={(event) => {
                                        event.preventDefault();                             
                                        this.setState({
                                          editStatus: false
                                        })                                      
                                      }}
                                    >
                                      <i class="la la-remove"></i>
                                    </button>
                                  ): (
                                    <button
                                      class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                      data-tip="true"
                                      data-for="Delete"
                                      currentitem="false"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        swal({
                                          title: "Are you sure?",
                                          text: "Are you sure that you want to delete Status",
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            this.deleteStatusBox(item.id);
                                          }
                                        });
                                      }}
                                    >
                                      <i class="la la-trash"></i>
                                    </button>
                                  )}
                                    
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                )}
                    </div>
                </form>

                </Tab>
            </Tabs>
            )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalUpdateBox.propTypes = {
    listengine: PropTypes.array.isRequired,
    listenginemodel: PropTypes.array.isRequired,
    com: PropTypes.array.isRequired,
    listeditsearch: PropTypes.array.isRequired,
    listeditvalue: PropTypes.object,
    modeledit: PropTypes.object,
    cam: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}
export default React.memo(ModalUpdateBox);
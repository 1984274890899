import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../../config';
import swal from 'sweetalert';
import Store from '../../../store';
import $ from 'jquery';
class ModalTypeDevice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeDevice: {},
            listtypeDevice: [],
            show: this.props.show
        }
    }

    UpdateTypeDevice = async (type, event) => {
        //Insert
        type.name = type.name.trim()
        var form = $("#formUpdateTypeDevice")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (type.id == undefined) {
                let data = await fetch(`${HOST}/api/type_device/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(type)
                }).then((response) => {
                    return response.json()
                });
                console.log(data)
                if (data.status === 10000) {
                    swal("Sucess!", "Add typeDevice Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                }
                else {
                    swal("Error!", "Add typeDevice Failed!", "error");
                }
                return data.status;

            } else {
                let data = await fetch(`${HOST}/api/type_device/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(type)
                }).then((response) => {
                    return (
                        response.json()
                    )
                });
                if (data.status === 10000) {
                    swal("Success!", "Edit typeDevice Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                }
                else {
                    swal("Error!", "Edit typeDevice Failed!", "error");
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            console.log(nextProps);
            this.setState({ typeDevice: nextProps.type });

        }
    }
    
    typeDeviceHandle(e) {
        var typeDeviceHan = this.state.typeDevice;
        typeDeviceHan[e.target.name] = e.target.value;
        this.setState({ typeDevice: typeDeviceHan });
    }

    render() {
        let click_handle = (event) => {
            this.UpdateTypeDevice(this.state.typeDevice, event);
        }
            
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateTypeDevice">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="typeDeviceName">Name <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="typeDeviceName" name='name' onChange={e => this.typeDeviceHandle(e)}
                                        placeholder="Name" value={this.state.typeDevice == null ? '' : this.state.typeDevice.name} required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="typeDeviceDescription">Description</label>
                                    <textarea rows="4" className="form-control m-input" id="typeDeviceDescription" name='description' onChange={e => this.typeDeviceHandle(e)}
                                        placeholder="Description" value={this.state.typeDevice == null ? '' : this.state.typeDevice.description} />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalTypeDevice.propTypes = {
    type: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    //nextProps: PropTypes.array,
}

export default ModalTypeDevice;
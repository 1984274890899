import React, { Component } from "react";
import { HOST,HOST_BENCHMARK, HOST_WS } from '../../config';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from '@emotion/core';
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import "../../css/mobiscroll.react.min.css";
import Select from "react-select";
import {MultiSelect} from "./MultiSelect";


// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

var mqtt = require('mqtt')
var client = HOST_WS.includes("ws") && mqtt.connect(HOST_WS)
Highcharts.setOptions({
    colors: ['#008ffb', '#00e396', '#feb019', '#ff4560']
});
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

var fileDownload = require('js-file-download');

// var ts2 = 1484418600000;
// var dates = [];
// var spikes = [5, -5, 3, -3, 8, -8]
// for (var i = 0; i < 120; i++) {
//     ts2 = ts2 + 86400000;
//     var innerArr = [ts2, dataSeries[1][i].value];
//     dates.push(innerArr)
// }


class Benchmark extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOptionsChecktimeServer: {
                chart: {
                    height: 350,
                    zoomType: 'x',

                },
                title: {
                    text: 'Chart show time processing server',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        // format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        if (this.point.z != undefined) {
                            return (
                                `<div>`
                                + `<div class="popover-header row ml-0 mr-0" style="width: 300px">`
                                + `<div class="col-6 p-0">` + moment(this.point.z.time_recieved_in_service, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss") + `</div>`
                                + `<div class="col-6 text-right p-0"> ID Camera: <b>` + this.point.z.id_camera + `</b></div>`
                                + `</div>`
                                + `<div class="popover-body">`
                                + `<div>`
                                + `<div> Company: <b>` + this.point.z.compnay['name'] + `</b></div>`
                                + `<div> User Agent: ` + this.point.z.header['User-Agent'] + `</div>`
                                + `<div> Host: ` + this.point.z.header.Host + `</div>`
                                + `<div> IP: ` + this.point.z.header['X-Real-Ip'] + `</div>`
                                + `</div>`
                                + `<div style="border-bottom: 1px dashed #575962;height: 0;margin: 10px 0;"></div>`
                                + `<div>`
                                + `<div> Time Liveness: ` + this.point.z.time_process.time_check_liveness + `</div>`
                                + `<div> Time Opendoor: ` + this.point.z.time_process.time_check_opendoor + `</div>`
                                + `<div> Time Emotion: ` + this.point.z.time_process.time_get_emotion + `</div>`
                                + `<div> Time Feature: ` + this.point.z.time_process.time_get_feature + `</div>`
                                + `<div> Time Threshold: ` + this.point.z.time_process.time_get_threshold + `</div>`
                                + `<div> Time Knn: ` + this.point.z.time_process.time_knn + `</div>`
                                + `<div> Total Processing Time: <b>` + this.point.z.time_process.time_total + `</b></div>`
                                + `</div>`
                                + `</div>`
                                + `</div>`
                            )
                        }

                    },
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0,
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            chartOptionsChecktimeClientToServer: {
                chart: {
                    height: 350,
                    zoomType: 'x',

                },
                title: {
                    text: 'Chart show time client sent to server',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        // format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        if (this.point.z != undefined) {
                            return (
                                `<div>`
                                + `<div class="popover-header row ml-0 mr-0" style="width: 300px">`
                                + `<div class="col-6 p-0">` + moment(this.point.z.time_recieved_in_service, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss") + `</div>`
                                + `<div class="col-6 text-right p-0"> ID Camera: <b>` + this.point.z.id_camera + `</b></div>`
                                + `</div>`
                                + `<div class="popover-body">`
                                + `<div>`
                                + `<div> Company: <b>` + this.point.z.compnay['name'] + `</b></div>`
                                + `<div> User Agent: ` + this.point.z.header['User-Agent'] + `</div>`
                                + `<div> Host: ` + this.point.z.header.Host + `</div>`
                                + `<div> IP: ` + this.point.z.header['X-Real-Ip'] + `</div>`
                                + `</div>`
                                + `<div style="border-bottom: 1px dashed #575962;height: 0;margin: 10px 0;"></div>`
                                + `<div>`
                                + `<div> Time Sent From Client: ` + this.point.z.time_sent_from_client + `</div>`
                                + `<div> Time Recieved In Server: ` + moment(this.point.z.time_recieved_in_service, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss") + `</div>`
                                + `<div> Total Processing Time: <b>` + this.y + `s</b></div>`
                                + `</div>`
                                + `</div>`
                                + `</div>`
                            )
                        }

                    },
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0,
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            chartOptionsChecktimeProcessSaveFile: {
                chart: {
                    height: 350,
                    zoomType: 'x',

                },
                title: {
                    text: 'Chart show time client processing save data',
                    style: {
                        fontSize: '15px'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        // format: '{value:%H:%M}',
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    min: 0,
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        if (this.point.z != undefined) {
                            return (
                                `<div>`
                                + `<div class="popover-header row ml-0 mr-0" style="width: 300px">`
                                + `<div class="col-6 p-0">` + moment(this.point.z.time_recieved_in_updateface, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss") + `</div>`
                                + `<div class="col-6 text-right p-0"> ID Camera: <b>` + this.point.z.id_camera + `</b></div>`
                                + `</div>`
                                + `<div class="popover-body">`
                                + `<div>`
                                + `<div> Company: <b>` + this.point.z.compnay['name'] + `</b></div>`
                                + `</div>`
                                + `<div style="border-bottom: 1px dashed #575962;height: 0;margin: 10px 0;"></div>`
                                + `<div>`
                                + `<div> Time Sent From Client: ` + this.point.z.time_sent_from_client + `</div>`
                                + `<div> Time Recieved In Update Face: ` + moment(this.point.z.time_recieved_in_updateface, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss") + `</div>`
                                + `<div> Time Storage Crop: <b>` + this.point.z.time_process.time_storage_crop + `</b></div>`
                                + `<div> Time Storage Delete: <b>` + this.point.z.time_process.time_storage_delete + `</b></div>`
                                + `<div> Time Find Staff : <b>` + this.point.z.time_process.time_find_staff + `</b></div>`
                                + `<div> Total Processing Time: <b>` + this.point.z.time_process.total_time + `</b></div>`
                                + `</div>`
                                + `</div>`
                                + `</div>`
                            )
                        }

                    },
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 0,
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },

                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: [0]
                }],
                navigation: {
                    buttonOptions: {
                        enabled: true
                    }
                }
            },
            dataBenchmark: [],
            listCamera: [],
            listCameraAll: [],
            listCompany: [],
            crrCamera: [],
            crrCameraAll: [],
            id_camera: '',
            api: '',
            crrCompany: "all",
            crrDate: new Date(),
            crrDateEnd: new Date(),
            optionsCompany: [],
            optionsSelectedCompany: {value: -1, label: "All Company"},
            selectedCamera: [],
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.getCompany()
    }

    getCompany = () => {
        fetch(`${HOST}/api/box/getAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: -1,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                console.log(data.data)
                var listCamera = []
                var crrCamera = []
                for (let index = 0; index < data.data.length; index++) {
                    for (let i = 0; i < data.data[index].box_engines.length; i++) {
                        listCamera.push(data.data[index].box_engines[i])
                        crrCamera.push(data.data[index].box_engines[i].id + '')
                    }
                }
                var dataOption = []
                var optionsCompany = [{ value : -1 , label : "All Company"}]
                data.data.forEach(e => {
                    if (e.company !== null) {
                        dataOption = { value : e.company.id, label : e.company.name }
                        optionsCompany.push(dataOption)
                       
                    }
                    
                })  
                var uniqueCompany = Array.from(new Set(optionsCompany.map(a => a.value)))
                    .map(value => {
                        return optionsCompany.find(a => a.value === value)
                    })
        
                this.setState({
                    optionsCompany: uniqueCompany,
                    listCompany: data.data,
                    listCamera: listCamera,
                    listCameraAll: listCamera,
                    crrCamera: crrCamera,
                    crrCameraAll: crrCamera,
                }, () => {
                    this.getTest()
                    this.getTest2()
                })
            }
        })
    }

    getTest = () => {
        fetch(`${HOST_BENCHMARK}/api/get_data`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(
                {
                    "page": "1",
                    "offset": "300",
                    "id_camera": this.state.crrCamera.length > 0 ? this.state.crrCamera : "",
                    "api": this.state.api == '' ? '' : [this.state.api + ''],
                    "start_date": moment(this.state.crrDate).format('YYYY-MM-DD 00:00:00'),
                    "end_date": moment(this.state.crrDateEnd).format('YYYY-MM-DD 23:59:59'),
                }
            )
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataTest = data.data.filter(function (value, index, arr) {
                    return value.id_camera != "191";
                });
                var dataChart = [];
                var dataChartClientToServer = [];
                var {listCompany} = this.state
                dataTest.map((e, i) => {
                    var dataDays = e.time_recieved_in_service;
                    var idCamera = e.id_camera;
                    for (let index = 0; index < listCompany.length; index++) {
                        for (let i = 0; i < listCompany[index].box_engines.length; i++) {
                            if (listCompany[index].box_engines[i].id == idCamera) {
                                e.compnay = listCompany[index].company
                            }
                        }
                    }

                    var today = new Date(moment(dataDays, 'YYYY-MM-DD hh:mm:ss').add(7, 'h').format("YYYY-MM-DD HH:mm:ss"));
                    var dd = today.getDate();
                    var MM = today.getMonth() + 1;
                    var yyyy = today.getFullYear();
                    var hh = today.getHours();
                    var mm = today.getMinutes();
                    var ss = today.getSeconds();

                    if (ss < 10) {
                        ss = '0' + ss
                    }

                    if (mm < 10) {
                        mm = '0' + mm
                    }

                    if (hh < 10) {
                        hh = '0' + hh
                    }

                    if (dd < 10) {
                        dd = '0' + dd
                    }

                    if (MM < 10) {
                        MM = '0' + MM
                    }
                    var data_Chart = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: e.time_process.time_total, z: e }
                    dataChart.push(data_Chart);

                    //time_sent_from_client
                    var dataDays2 = e.time_sent_from_client;
                    var today2 = new Date(moment(dataDays2, 'YYYY-MM-DD hh:mm:ss').format("YYYY-MM-DD HH:mm:ss"));
                    var dd2 = today2.getDate();
                    var MM2 = today2.getMonth() + 1;
                    var yyyy2 = today2.getFullYear();
                    var hh2 = today2.getHours();
                    var mm2 = today2.getMinutes();
                    var ss2 = today2.getSeconds();

                    if (ss2 < 10) {
                        ss2 = '0' + ss2
                    }

                    if (mm2 < 10) {
                        mm2 = '0' + mm2
                    }

                    if (hh2 < 10) {
                        hh2 = '0' + hh2
                    }

                    if (dd2 < 10) {
                        dd2 = '0' + dd2
                    }

                    if (MM2 < 10) {
                        MM2 = '0' + MM2
                    }

                    var timeClientToServer = (Date.UTC(yyyy, MM - 1, dd, hh, mm, ss) - Date.UTC(yyyy2, MM2 - 1, dd2, hh2, mm2, ss2)) / 1000

                    var dataChart2 = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: timeClientToServer, z: e }

                    dataChartClientToServer.push(dataChart2);
                    //time_recieved_in_service

                });
                this.setState({
                    // series: [{
                    //     data: dataChart
                    // }],
                    chartOptionsChecktimeServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: dataChart
                        }],
                    },
                    chartOptionsChecktimeClientToServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: dataChartClientToServer
                        }],
                    },
                    dataBenchmark: dataTest
                }, () => {
                    if (HOST_WS.includes("ws")) {
                    client = mqtt.connect(HOST_WS)
                    client.on('connect', function () {
                        console.log('mqtt broker connected!')
                        client.subscribe(`v1/micoservice/benchmark`, function (err) {
                            if (!err) {
                                console.log('Benchmark subscribed');
                            }
                        })
                    })
                    let that = this;
                    client.on('message', function (topic, message) {
                        var paramssh = JSON.parse(message)
                        var checkIdCamera = that.state.crrCamera.includes(paramssh.id_camera);
                        if (checkIdCamera == true) {
                            //pushData
                            if (paramssh.id_camera != '191') {
                                var today = new Date(moment(paramssh.time_recieved_in_service, 'YYYY-MM-DD hh:mm:ss').add(7, 'h').format("YYYY-MM-DD HH:mm:ss"));
                                var dd = today.getDate();
                                var MM = today.getMonth() + 1;
                                var yyyy = today.getFullYear();
                                var hh = today.getHours();
                                var mm = today.getMinutes();
                                var ss = today.getSeconds();
                                var ms = today.getMilliseconds();

                                if (ss < 10) {
                                    ss = '0' + ss
                                }

                                if (ms < 10) {
                                    ms = '0' + ms
                                }

                                if (mm < 10) {
                                    mm = '0' + mm
                                }

                                if (hh < 10) {
                                    hh = '0' + hh
                                }

                                if (dd < 10) {
                                    dd = '0' + dd
                                }

                                if (MM < 10) {
                                    MM = '0' + MM
                                }

                                //time_sent_from_client
                                var today2 = new Date(moment(paramssh.time_sent_from_client, 'YYYY-MM-DD hh:mm:ss').format("YYYY-MM-DD HH:mm:ss"));
                                var dd2 = today2.getDate();
                                var MM2 = today2.getMonth() + 1;
                                var yyyy2 = today2.getFullYear();
                                var hh2 = today2.getHours();
                                var mm2 = today2.getMinutes();
                                var ss2 = today2.getSeconds();

                                if (ss2 < 10) {
                                    ss2 = '0' + ss2
                                }

                                if (mm2 < 10) {
                                    mm2 = '0' + mm2
                                }

                                if (hh2 < 10) {
                                    hh2 = '0' + hh2
                                }

                                if (dd2 < 10) {
                                    dd2 = '0' + dd2
                                }

                                if (MM2 < 10) {
                                    MM2 = '0' + MM2
                                }


                                //dataChart
                                var data_chart = that.state.chartOptionsChecktimeServer.series[0].data;
                                var data_benchmark_chart = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: paramssh.time_process.time_total, z: paramssh }
                                data_chart.push(data_benchmark_chart);
                                data_chart.shift();

                                //dataChartClientToServer
                                var data_chart2 = that.state.chartOptionsChecktimeClientToServer.series[0].data;

                                var timeClientToServer = (Date.UTC(yyyy, MM - 1, dd, hh, mm, ss) - Date.UTC(yyyy2, MM2 - 1, dd2, hh2, mm2, ss2)) / 1000

                                var data_clienttoserver = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: timeClientToServer, z: paramssh }
                                data_chart2.push(data_clienttoserver);
                                data_chart2.shift();

                                //dataTable
                                var data_table = that.state.dataBenchmark;
                                paramssh.time_created = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').subtract(7, "h").format("YYYY-MM-DD HH:mm:ss")
                                data_table.unshift(paramssh);
                                data_table.pop();

                                that.setState({
                                    // series: [{
                                    //     data: data
                                    // }],
                                    chartOptionsChecktimeServer: {
                                        series: [{
                                            type: 'area',
                                            name: 'Total time',
                                            data: data_chart
                                        }],
                                    },

                                    chartOptionsChecktimeClientToServer: {
                                        series: [{
                                            type: 'area',
                                            name: 'Total time',
                                            data: data_chart2
                                        }],
                                    },
                                })
                            }
                        }
                        // that.intervals()
                    })
                    }
                })
            }
        })
    }

    getTest2 = () => {
        fetch(`${HOST_BENCHMARK}/api/get_process_face`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify(
                {
                    "page": "1",
                    "offset": "300",
                    "id_camera": this.state.crrCamera.length > 0 ? this.state.crrCamera : "",
                    "start_date": moment(this.state.crrDate).format('YYYY-MM-DD 00:00:00'),
                    "end_date": moment(this.state.crrDateEnd).format('YYYY-MM-DD 23:59:59'),
                }
            )
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataTest = data.data.filter(function (value, index, arr) {
                    return value.id_camera != "191";
                });
                var dataChart = [];
                var dataChartRecievedInUpdateFace = [];
                var {listCompany} = this.state
                dataTest.map((e, i) => {
                    var dataDays = e.time_recieved_in_updateface;
                    var idCamera = e.id_camera;
                    for (let index = 0; index < listCompany.length; index++) {
                        for (let i = 0; i < listCompany[index].box_engines.length; i++) {
                            if (listCompany[index].box_engines[i].id == idCamera) {
                                e.compnay = listCompany[index].company
                            }
                        }
                    }
                    var today = new Date(moment(dataDays, 'YYYY-MM-DD hh:mm:ss').add(7, 'h').format("YYYY-MM-DD HH:mm:ss"));
                    var dd = today.getDate();
                    var MM = today.getMonth() + 1;
                    var yyyy = today.getFullYear();
                    var hh = today.getHours();
                    var mm = today.getMinutes();
                    var ss = today.getSeconds();

                    if (ss < 10) {
                        ss = '0' + ss
                    }

                    if (mm < 10) {
                        mm = '0' + mm
                    }

                    if (hh < 10) {
                        hh = '0' + hh
                    }

                    if (dd < 10) {
                        dd = '0' + dd
                    }

                    if (MM < 10) {
                        MM = '0' + MM
                    }
                    var data_Chart = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: e.time_process.total_time, z: e }
                    dataChart.push(data_Chart);
                  
                });
                this.setState({
                    chartOptionsChecktimeProcessSaveFile: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: dataChart   
                        }],
                    },
                }, () => {
                    if (HOST_WS.includes("ws")) {
                    client = mqtt.connect(HOST_WS)
                    client.on('connect', function () {
                        console.log('mqtt broker connected!')
                        client.subscribe(`v1/micoservice/benchmark`, function (err) {
                            if (!err) {
                                console.log('Benchmark subscribed');
                            }
                        })
                    })
                    let that = this;
                    client.on('message', function (topic, message) {
                        var paramssh = JSON.parse(message)
                        var checkIdCamera = that.state.crrCamera.includes(paramssh.id_camera);
                        if (checkIdCamera == true) {
                            //pushData
                            if (paramssh.id_camera != '191') {
                                var today = new Date(moment(paramssh.time_recieved_in_updateface, 'YYYY-MM-DD hh:mm:ss').add(7, 'h').format("YYYY-MM-DD HH:mm:ss"));
                                var dd = today.getDate();
                                var MM = today.getMonth() + 1;
                                var yyyy = today.getFullYear();
                                var hh = today.getHours();
                                var mm = today.getMinutes();
                                var ss = today.getSeconds();
                                var ms = today.getMilliseconds();

                                if (ss < 10) {
                                    ss = '0' + ss
                                }

                                if (ms < 10) {
                                    ms = '0' + ms
                                }

                                if (mm < 10) {
                                    mm = '0' + mm
                                }

                                if (hh < 10) {
                                    hh = '0' + hh
                                }

                                if (dd < 10) {
                                    dd = '0' + dd
                                }

                                if (MM < 10) {
                                    MM = '0' + MM
                                }

                                //dataChartChecktimeProcessSaveFile
                                var data_Chart = that.state.chartOptionsChecktimeProcessSaveFile.series[0].data;
                                var data_time_recieved_in_updateface = { x: Date.UTC(yyyy, MM - 1, dd, hh, mm, ss), y: paramssh.time_process.total_time, z: paramssh }
                                data_Chart.push(data_time_recieved_in_updateface);
                                data_Chart.shift();

                                that.setState({
                                    chartOptionsChecktimeProcessSaveFile: {
                                        series: [{
                                            type: 'area',
                                            name: 'Total time',
                                            data: data_Chart
                                        }],
                                    },
                                })
                            }
                        }
                        // that.intervals()
                    })
                    }
                })
            }
        })
    }
    
    

    changeHandleCompany = (event, inst) => {
        this.setState({
            optionsSelectedCompany : event,
            selectedCamera: []
        });
        var crrCameraAll = this.state.crrCameraAll
        var listCameraAll = this.state.listCameraAll
        if (event.value == -1) {
            this.setState({
                crrCompany: event.value,
                crrCamera: crrCameraAll,
                listCamera: listCameraAll,
                chartOptionsChecktimeServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeClientToServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeProcessSaveFile: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
            }, () => {
                if (HOST_WS.includes("ws")) {
                    client.end();
                    console.log('mqtt broker disconnected!')
                }
                this.getTest()
                this.getTest2()
            })
        } else {
            var listCompany = this.state.listCompany;
            const companyFilter = listCompany.filter(e => {
                return e.company_id === event.value
            })
            var dataBox = companyFilter[0].box_engines
            var crrCamera = [];
            if (dataBox.length > 0) {
                for (let index = 0; index < dataBox.length; index++) {
                    crrCamera.push(dataBox[index].id + '')
                }

                this.setState({
                    crrCompany: event.value,
                    crrCamera: crrCamera,
                    listCamera: dataBox,
                    chartOptionsChecktimeServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                    chartOptionsChecktimeClientToServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                    chartOptionsChecktimeProcessSaveFile: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                }, () => {
                    if (HOST_WS.includes("ws")) {
                        client.end();
                        console.log('mqtt broker disconnected!')
                    }
                    this.getTest()
                    this.getTest2()
                })
            } else {
                this.setState({
                    crrCompany: event.value,
                    crrCamera: crrCamera,
                    listCamera: dataBox,
                    chartOptionsChecktimeServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                    chartOptionsChecktimeClientToServer: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                    chartOptionsChecktimeProcessSaveFile: {
                        series: [{
                            type: 'area',
                            name: 'Total time',
                            data: []
                        }],
                    },
                }, () => {
                    if (HOST_WS.includes("ws")) {
                        client.end();
                    }
                    console.log('mqtt broker disconnected!')
                })
            }
        }
    }

    changeHandleCamera = (event, inst) => {
        this.setState({
            selectedCamera: event
        });
        var crrCamera = []
        event.forEach((e) => {
            crrCamera.push(e.value)
        })
        if (event.length > 0) {
            this.setState({
                crrCamera: crrCamera,
                chartOptionsChecktimeServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeClientToServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeProcessSaveFile: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
            }, () => {
                if (HOST_WS.includes("ws")) {
                    client.end();
                    console.log('mqtt broker disconnected!')
                }
                this.getTest()
                this.getTest2()
            })
        } else {
            this.setState({
                crrCamera: crrCamera,
                chartOptionsChecktimeServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeClientToServer: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
                chartOptionsChecktimeProcessSaveFile: {
                    series: [{
                        type: 'area',
                        name: 'Total time',
                        data: []
                    }],
                },
            }, () => {
                if (HOST_WS.includes("ws")) { 
                    client.end();
                    console.log('mqtt broker disconnected!')
                }
            })
        }
    }

    handleChangeStartDate = crrDate => {
        this.setState({
            crrDate: crrDate,
            chartOptionsChecktimeServer: {
                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: []
                }],
            },
            chartOptionsChecktimeClientToServer: {
                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: []
                }],
            },
        }, () => {
            if (HOST_WS.includes("ws")) { 
                client.end();
                console.log('mqtt broker disconnected!')
            }
            this.getTest()
        });
    }
    handleChangeEndDate = crrDate => {
        this.setState({
            crrDateEnd: crrDate,
            chartOptionsChecktimeServer: {
                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: []
                }],
            },
            chartOptionsChecktimeClientToServer: {
                series: [{
                    type: 'area',
                    name: 'Total time',
                    data: []
                }],
            },
        }, () => {
            if (HOST_WS.includes("ws")) { 
                client.end();
                console.log('mqtt broker disconnected!')
            }
            this.getTest()
        });
    }

    componentWillUnmount() {
        if (HOST_WS.includes("ws")) { 
            client.end();
            console.log('mqtt broker disconnected!')
        }
    }


    componentDidMount() {
        $('text.highcharts-credits').html('');
    }

    render() {
        console.log(this.state.listCompany)

        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        const { chartOptionsChecktimeServer, chartOptionsChecktimeClientToServer , chartOptionsChecktimeProcessSaveFile } = this.state;
        let dataBenchmark = this.state.dataBenchmark.map((value, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.header['User-Agent']}</td>
                    <td>{value.header['Host']}</td>
                    <td>{value.header['X-Real-Ip']}</td>
                    <td>{value.time_sent_from_client}</td>
                    <td>{moment(value.time_recieved_in_service, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment(value.time_created, 'YYYY-MM-DD HH:mm:ss').add(7, "h").format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>
                        {value.time_process.time_total}
                    </td>
                    <td>{value.id_camera}</td>
                </tr>
            )
        })

        let optionCamera = this.state.listCamera.map((value, index) => {
            return (
                {value : `${value.id}`, label :value.box_id + '_' + value.id}
            )
        })


        return (

            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="fa flaticon-line-graph" />
                                        </span>
                                    </span>
                                    Benchmark
                                </h3>
                            </div>
                        </div>
                        <div className="row p-3 col-xl-11">
                            <div className="col-xl-2 pl-0 mb-3 select_date_benchmark">
                                <DatePicker
                                    className="form-control m-input m_datetimepicker_6"
                                    selected={this.state.crrDate}
                                    onChange={this.handleChangeStartDate}
                                    dateFormat="dd-MM-yyyy"
                                    withPortal
                                    placeholderText="Select Date"
                                />
                            </div>
                            <div className="col-xl-2 pl-0 mb-3 select_date_benchmark">
                                <DatePicker
                                    className="form-control m-input m_datetimepicker_6"
                                    selected={this.state.crrDateEnd}
                                    onChange={this.handleChangeEndDate}
                                    dateFormat="dd-MM-yyyy"
                                    withPortal
                                    placeholderText="Select Date"
                                />
                            </div>
                            <div className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select Company'}
                                        value={this.state.optionsSelectedCompany}
                                        onChange={this.changeHandleCompany}
                                        options={this.state.optionsCompany}
                                    />
                            </div>
                            <div className="col-xl-4 pl-0 mb-3">
                                <MultiSelect
                                    options={optionCamera} 
                                    value={this.state.selectedCamera} 
                                    onChange={this.changeHandleCamera}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12 p-4">
                                <div className="m-widget1 pb-0">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptionsChecktimeClientToServer}

                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 p-4">
                                <div className="m-widget1">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptionsChecktimeServer}

                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 p-4">
                                <div className="m-widget1">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptionsChecktimeProcessSaveFile}

                                    />
                                </div>
                            </div>
                            {/* <div className="col-xl-12">
                                <div className="m-widget1 p-2">
                                    <div id="chart"> */}
                            {/* <div className="toolbar">
                                            <button onClick={() => this.updateData('one_month')} id="one_month" className={(this.state.selection === 'one_month' ? 'active' : '')}>1M</button>
                                            <button onClick={() => this.updateData('six_months')} id="six_months" className={(this.state.selection === 'six_months' ? 'active' : '')}>6M</button>
                                            <button onClick={() => this.updateData('one_year')} id="one_year" className={(this.state.selection === 'one_year' ? 'active' : '')}>1Y</button>
                                            <button onClick={() => this.updateData('ytd')} id="ytd" className={(this.state.selection === 'ytd' ? 'active' : '')}>YTD</button>
                                            <button onClick={() => this.updateData('all')} id="all" className={(this.state.selection === 'all' ? 'active' : '')}>ALL</button>
                                        </div> */}
                            {/* <ReactApexCharts options={this.state.options} series={this.state.series} type="area" height="350" /> */}
                            {/* </div>
                                </div>
                            </div> */}
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive text-nowrap scroolbar" id="scroolbar" style={{ 'height': '600px' }}>
                                        <table className="table table-bordered table-hover table-checkable mb-0 dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>User Agent</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Host</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>IP</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Time Sent From Client</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Time Recieved In Service</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Time Created</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Total Processing Time</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>ID Camera</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataBenchmark}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Benchmark;

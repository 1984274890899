import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Store from '../../store';
import { withRouter } from 'react-router';

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin
            })
        });
    }

    render() {
        var dislable;
        if (this.state.isLogin === false || this.props.location.pathname === "/login") {
            dislable = 'd-none';
        }
        var active = '';
        if (this.props.location.pathname === "/") {
            active = 'm-menu__item--active'
        }
        return (
            <div id="m_aside_left" className={"m-grid__item	m-aside-left  m-aside-left--skin-dark " + dislable}>
                {/* BEGIN: Aside Menu */}
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark " m-menu-vertical={1} m-menu-scrollable={1} m-menu-dropdown-timeout={500} style={{ position: 'relative' }}>
                    <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/dashboard" className={"m-menu__link " + active} activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-line-graph" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Dashboard</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        {process.env.REACT_APP_HIDE_REPORT == "0" && 
                            <li className="m-menu__item" aria-haspopup="true">
                                <NavLink to="/reportstatus" className={"m-menu__link "} activeClassName="m-menu__item--active">
                                    <i className="m-menu__link-icon fa fa-chart-bar" />
                                    <span className="m-menu__link-title">
                                        <span className="m-menu__link-wrap">
                                            <span className="m-menu__link-text">Report Status</span>
                                        </span>
                                    </span>
                                </NavLink>
                            </li>
                        }
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                            <i className="m-menu__link-icon fa flaticon-pie-chart" />
                                <span className="m-menu__link-text">Mornitoring</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                        
                        {process.env.REACT_APP_HOST_MONITOR == ''
                            ? null
                            :
                            <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/device" className={"m-menu__link "} activeClassName="m-menu__item--active">
                            <i className="m-menu__link-bullet m-menu__link-bullet--dot"/>
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Box Monitoring</span>
                                    </span>
                                </span>
                            </NavLink>
                            </li>
                            }
                            {process.env.REACT_APP_HOST_SERVER == ''
                            ? null
                            :
                            <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/server" className={"m-menu__link "} activeClassName="m-menu__item--active">
                            <i className="m-menu__link-bullet m-menu__link-bullet--dot"/>
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Server Monitoring</span>
                                    </span>
                                </span>
                            </NavLink>
                            </li>
                        }
                        </ul>
                        </div>
                        </li>
                        {
                            process.env.REACT_APP_HOST_BENCHMARK == ""
                                ? null
                                :
                                <>
                                    <li className="m-menu__item" aria-haspopup="true">
                                        <NavLink to="/benchmark" className={"m-menu__link "} activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-icon fa flaticon-line-graph" />
                                            <span className="m-menu__link-title">
                                                <span className="m-menu__link-wrap">
                                                    <span className="m-menu__link-text">Benchmark</span>
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                </>
                        }
                        
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/listbox" className={"m-menu__link "} activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-symbol" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Boxes</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/company" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-map" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Companies</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/engine" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon flaticon-share" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Engines</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/version" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-object-ungroup" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Engine Versions</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/modelengine" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-puzzle-piece" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Engine Models</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/listuser" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-users" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Users</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/qrcode" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-qrcode" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Qr Code</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-user-settings" />
                                <span className="m-menu__link-text">Settings</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/typedevice" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Type Device</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/modules" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Modules</span>
                                        </NavLink>
                                    </li>
                                    
                                    {/* <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/license" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">License</span>
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* END: Aside Menu */}
            </div>
        );
    }
}

export default withRouter(MenuBar);
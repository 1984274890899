import moment, { now } from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import swal from "sweetalert";
import { HOST } from "../../../config";
import store from "../../../store";
import "react-datepicker/dist/react-datepicker.css";
class ModalAddStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crrBox: { active: 0 },
      boxStatusAdd: {
        time: "",
        status: "",
        description: "",
      },
      boxStatus: [],
      indexEdit: -1,
      statusArray: [
        { value: 1, label: "In Stock" },
        { value: 2, label: "Sold"},
        { value: 3, label: "POC" },
        { value: 4, label: "Rented"},
        { value: 5, label: "Under Warranty" },
        { value: 6, label: "Out of order" },
        { value: 7, label: "Fake" },
      ],
      showModal: null,
    };
  }

 

  changeBoxId(value) {
    var camera = this.state.boxStatusAdd;
    camera["box_id"] = value;
    this.setState({
      boxStatusAdd: camera,
    });
  }

  handleChangeDate = (date) => {
    var camera = this.state.boxStatusAdd;
    camera["time"] = moment(date).format("YYYY-MM-DD HH:mm:ss");
    this.setState({
      date: date,
      boxStatusAdd: camera,
    });
  };

  handleChangeStatus(value) {
    var camera = this.state.boxStatusAdd;
    camera["status"] = value.value;
    this.setState({
      value: value,
      boxStatusAdd: camera,
    });
  }

  BoxHandleStatusNote(e) {
    var camera = this.state.boxStatusAdd;
    camera["description"] = e.target.value;
    this.setState({ boxStatusAdd: camera });
  }

  insertStatusById = (event) => {
    event.preventDefault();
    if (this.state.boxStatusAdd.status == null) {
      swal("Warning", "Please select status", "warning");
    } else if (this.state.boxStatusAdd.time == "") {
      swal("Warning", "Please Select time", "warning");
    } else {
      fetch(`${HOST}/api/box_status/insert_or_update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: store.getState().isLogin.access_token,
        },
        body: JSON.stringify(this.state.boxStatusAdd),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status == 10000) {
            swal("Success", "Add Status Success", "success");
            console.log("data", data);
            var boxStatus = this.state.boxStatus;
            boxStatus.push(data.result);
            this.setState({
              boxStatus: boxStatus,
              boxStatusAdd: { box_id: this.state.crrBox.id, description: "", time: moment().format("YYYY-MM-DD HH:mm:ss") },
            });
          } else {
            swal("Error!", "Update Status Fail. Please Try Again", "error");
          }
        }).then(
          this.props.onHide
        );
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true) {
      this.changeBoxId(nextProps.cam.id);
      console.log(new Date().setHours(20,20,20));
      this.setState({
        boxStatusAdd: {
          ...this.state.boxStatusAdd,
          description: "",
          status: "",
          time: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      this.setState({ crrBox: nextProps.cam, showModal: nextProps.show });
    }
  }

  render() {
    console.log("boolen", this.state.showModal);
    console.log("add border", this.state.boxStatusAdd);
    return (
      <Modal
        {...this.props}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <label className="title-header">Update Status</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="m-widget14 pt-0 row">
              <div className="form-group m-form__group col-xl-12">
                <label htmlFor="type_of_box">ID Box: &nbsp;</label>
                <span>
                  {this.state.crrBox.id === undefined
                    ? ""
                    : this.state.crrBox.id}
                </span>
              </div>
              <div className="form-group m-form__group col-xl-6">
                <label>Date</label>
                <div className="group-input-icon">
                  <DatePicker
                    showTimeInput
                    className="form-control m-input m_datetimepicker_6"
                    // selected={this.state.boxStatusAdd.time}
                    selected = {this.state.boxStatusAdd.time === null ? "" : this.state.boxStatusAdd.time === "" ? "" : new Date(this.state.boxStatusAdd.time)}
                    onChange={this.handleChangeDate}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    placeholderText="Select date"
                    maxDate={new Date()}
                    // maxTime={new Date().setHours(new Date().setMinutes(new Date(), 4), 5)}
                  />
                  <div className="icon-input">
                    <a className="flaticon-event-calendar-symbol"></a>
                  </div>
                </div>
              </div>
              <div className="form-group m-form__group col-xl-6">
                <label htmlFor="Port_Local">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={this.state.statusArray}
                  value={this.state.statusArray.filter((option) => {
                    return this.state.boxStatusAdd.status === option.value;
                  })}
                  onChange={(value) => this.handleChangeStatus(value)}
                />
              </div>
              <div className="form-group m-form__group col-xl-12">
                <label>Note</label>
                <div className="group-input-icon">
                  <input
                    className="form-control m-input"
                    id="note"
                    name="note"
                    value={
                      this.state.boxStatusAdd.description === null
                        ? ""
                        : this.state.boxStatusAdd.description
                    }
                    onChange={(e) => this.BoxHandleStatusNote(e)}
                    placeholder="Note"
                    autoComplete=""
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.insertStatusById} className="btn btn-accent m-btn m-btn--icon">
            Save
          </button>
          <Button variant="secondary" closeButton onClick={this.props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default React.memo(ModalAddStatus);
import React, { Component } from "react";
import { HOST } from '../../config';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import ModalUpdateBox from '../modal/box/ModalUpdateBox';
import swal from 'sweetalert';
import Select from "react-select";
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import ModalShowEngine from "../modal/box/ModalShowEngine";
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import Switch from "react-switch";
import ModalAddStatus from "../modal/box/ModalAddStatus";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class BoxList extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            loading: true,
            listCompany: [],
            listBox: [],
            listCompanySeacrh: [],
            listCompanySeacrhModal: [],
            modalAddStatus: false,
            typeStatusOption: { value: -1, label: "All Status" },
            listStatus: [
                { value: -1, label: "All status" },
                { value: 1, label: "In Stock", backgroundColor: "orange" },
                { value: 2, label: "Sold", backgroundColor: "#40bd67" },
                { value: 3, label: "POC", backgroundColor: "#2cc1d8" },
                { value: 4, label: "Rented", backgroundColor: "#e878b4" },
                { value: 5, label: "Under Warranty", backgroundColor: "red" },
                { value: 6, label: "Out of order", backgroundColor: "#800080" },
                { value: 7, label: "Fake", backgroundColor: "#808080" },
            ],
            listSearch: [
                { value: "1", label: "Mac" },
                { value: "2", label: "BoxID" },
                { value: "3", label: "EngineID" },
                { value: "4", label: "Serial" },
            ],
            listEngine: [],
            crrEngine: {},
            listEngineModel: [],
            crrBoxList: null,
            crrCamera: null,
            crrIdxCom: -1,
            crrIdxCam: -1,
            modalShow: false,
            modalAddShow: false,
            modalAddBoxShow: false,
            modalUpdateBoxShow: false,
            modalViewEngine: false,
            isChecked: false,
            selectedOption: { value: -1, label: "All Company", code: null },
            typeDeviceOption: { value: -1, label: "All Type Device", },
            modelid: 0,
            //paganation
            activePage: 1,
            valueModel: {},
            listDevice: [],
            AllDevice: [],
            crrDevice: [],
            loadNone: false,
            tempValue: '',
            searchBox: [],
            offset: 0,
            isLogin: Store.getState().isLogin.isLogin,
            showFirst: 0,
            showLast: 0,
            totalLength: 0,
            valueSearch: { value: "1", label: "Mac" },
            file: [],
            listTypeDeviceSearch: [],
            countData: 0,
            modalReportStatus: false,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
        this.getBoxAll();
        this.getCompany();
        this.getAllEngine();
        this.getAllEngineModel();
        this.getTypeDevice();

    }

    componentDidMount() {
        this._isMounted = true;
        this.getBoxAll();
        this.getCompany();
    }

    getCompany = () => {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: -1,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
        
            if (data.status === 10000) {

                var dataOption;
                var companyOption = [{ value: -1, label: "All Company" }, { value: null, label: "Unassigned" }];
                var companyOptionModal = [];

                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.name + "  (" + e.code + ")", code: e.code, active: e.is_deleted };
                    if (e.code == null) {
                        dataOption = { value: e.id, label: e.name };
                    }
                    companyOption.push(dataOption);
                    companyOptionModal.push(dataOption);
                });


                this.setState({
                    listCompany: [...data.data],
                    listCompanySeacrh: companyOption,
                    listCompanySeacrhModal: companyOptionModal,
                });
            }
        })
    }

    colourStyles = {
        option: (styles, { data, isSelected, isFocused }) => {
            return {
                ...styles,
                color: data.active === 1 ? "red" : null,
                backgroundColor: (isSelected && data.active === 1) ? "#cecece" : (isSelected && data.active !== 1) ? "#2684ff" : isFocused ? "#deebff" : null,
            };
        },
    };

    getTypeDevice = () => {
        fetch(`${HOST}/api/type_device/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var dataOption;
                var typeDeviceOptions = [{ value: -1, label: "All Type Device" }];

                data.data.forEach(e => {
                    dataOption = { value: e.id, label: e.name };
                    typeDeviceOptions.push(dataOption);
                });
                this.setState({
                    listTypeDeviceSearch: typeDeviceOptions
                });
            }
        })
    }

    getBoxAll = () => {
        fetch(`${HOST}/api/box/getAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                item_per_page: this.itemsPerPage,
                index: this.state.activePage,
                data_search: this.state.tempValue,
                id_company: this.state.selectedOption.value,
                id_type_device: this.state.typeDeviceOption.value,
                type_search: +this.state.valueSearch.value,
                id_box_status: this.state.typeStatusOption.value,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    AllDevice: [...data.data],
                    listBox: [...data.data],
                    loading: false,
                    file: data,
                    countData: data.count
                }, () => {
                    this.FilterDevice(this.state.activePage)
                });

            }
        })
    }

    getBoxListByIdCompany = (e) => {
        fetch(`${HOST}/api/box/getByCompany/${e.value}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var searchBox = []
                if (this.state.tempValue == '') {
                    if (this.state.typeDeviceOption.value === -1) {
                        searchBox = data.data
                    } else {
                        var data_filter = data.data.filter(e => e.type_device.id === this.state.typeDeviceOption.value)
                        searchBox = data_filter
                    }
                } else {
                    if (this.state.valueSearch.value == 1) {

                        data.data.forEach((item) => {
                            var mac_address = ""
                            if (item.mac_address != null) {
                                mac_address = item.mac_address
                            }

                            if (mac_address.indexOf(this.state.tempValue) !== -1) {
                                searchBox.push(item);
                            }
                        })

                    } else if (this.state.valueSearch.value == 2) {
                        data.data.forEach((item) => {
                            var box_id = item.id + ''
                            if (box_id.indexOf(this.state.tempValue) !== -1) {
                                searchBox.push(item);
                            }
                        })
                    } else {
                        data.data.forEach((item) => {
                            var data1 = item.box_engines.filter((value) => {
                                return value.id == this.state.tempValue
                            })
                            if (data1.length > 0) {
                                searchBox.push(item);
                            }
                        })
                    }
                }
                this.setState({
                    listBox: searchBox,
                    loading: false
                });
                this.FilterDevice(this.state.activePage)
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }


    FilterDevice = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset,
            showFirst: offset + 1,
            showLast: this.state.listBox.length + offset,
            totalLength: this.state.countData
        });
    }

    DeleteCamera = async (idbox, i) => {
        let data = await fetch(`${HOST}/api/box/delete`, {
            method: 'POST',
            headers: {
                'Authorization': Store.getState().isLogin.access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'id': idbox.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            this._isMounted = true;
            this.getBoxAll()
            // if (this.state.selectedOption.value != -1) {
            //     this.getBoxListByIdCompany(this.state.selectedOption);
            // } else {
            //     this.getBoxAll()
            // }
            swal("Success!", "Delete Box Success!", "success", {
                buttons: false,
                timer: 1500,
            });
        }
        else {
            swal("Error!", "Delete Box Failed!", "error");
        }

    }

    modalClose = () => {
        this.setState({
            modalShow: false,
            modalAddShow: false,
            modalUpdateBoxShow: false,
            modalAddStatus: false,
            modalViewEngine: false
        });
        this._isMounted = true;
        this.getBoxAll()
        // if (this.state.selectedOption.value != -1) {
        //     this.getBoxListByIdCompany(this.state.selectedOption);
        // } else {
        //     this.getBoxAll();
        // }
        this.getCompany();
    }

    modalCloseShowEngine = () => {
        this.setState({
            modalViewEngine: false
        });
        this._isMounted = true;
    }

    reset = (e) => {
        this.setState({
            tempValue: '',
            selectedOption: { value: -1, label: "All Company" },
            typeDeviceOption: { value: -1, label: "All Type Device" },
            typeStatusOption: { value: -1, label: "All Status" },
            activePage: 1,
            crrDevice: [],
            listBox: [],
            loading: true
        }, () => {
            $('#inputSearch').val('');
            this.getBoxAll();
        });
    }

    //HandChange Select
    handleChangeCompany = (selectedOption) => {
        console.log(selectedOption)
        this.setState({
            selectedOption,
        });
    }

    handleChangeTypeDevice = (value) => {
        this.setState({
            typeDeviceOption: value,
        });
    }

    handleChangeSearch = (value) => {
        this.setState({
            valueSearch: value,
        })
    }
    //End

    //Input Change
    isChangeInputSearch = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }
    //End

    //Button Search
    btnSearch = () => {
        this.setState({
            activePage: 1,
            listBox: [],
            crrDevice: [],
            loading: true
        }, () => {
            this.getBoxAll()
            // if (this.state.selectedOption.value != -1) {
            //     this.getBoxListByIdCompany(this.state.selectedOption)
            // } else {
            //     this.getBoxAll()
            // }
        });
    }

    //End

    handlePageChange = (pageNumber) => {
        $('#show_child_row').find($('.tr_child_show')).remove();
        $('#show_child_row').find($('.show_row_child')).removeClass('show_row_child');
        $('#show_child_row').find($('tr')).addClass('hiden_row_child');
        this.setState({
            activePage: pageNumber
        }, () => {
            this.getBoxAll()
            this.FilterDevice(pageNumber);
        })
    }


    getAllEngine() {
        fetch(`${HOST}/api/engine/getByLevel1`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listEngine: data.data,
                });
            }
        })
    }

    getAllEngineModel() {
        fetch(`${HOST}/api/engineModel/getAll`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                console.log(data.data);
                this.setState({
                    listEngineModel: data.data,
                });
            }
        })
    }

    handleChecked = async (e, i) => {
        var datacheck = {
            box_engines: e.box_engines,
            box_evidences: e.box_evidences,
            code: e.code,
            company: e.company,
            company_id: e.company_id,
            date_in: e.date_in,
            status: e.status,
            date_out: e.date_out,
            description: e.description,
            id: e.id,
            id_typedevice: e.type_device ? e.type_device.id : null,
            mac_address: e.mac_address,
            serial: e.serial,
            type_of_box: e.type_of_box,
            type_of_camera: e.type_of_camera,
            view_url_local: e.view_url_local,
            view_url_public: e.view_url_public,
        };
        if (e.active === 0) {
            datacheck.active = 1;
            let data = await fetch(`${HOST}/api/box/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(datacheck)
            }).then((response) => {
                return response.json()
            });

            if (data.status === 10000) {
                this.getBoxAll();
                swal("Sucess!", "Active Box Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            }
            else {
                swal("Error!", "Active Box Failed!", "error");
            }
        } else {
            datacheck.active = 0;
            let data = await fetch(`${HOST}/api/box/update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify(datacheck)
            }).then((response) => {
                return response.json()
            });
            if (data.status === 10000) {
                this.getBoxAll();
                swal("Sucess!", "Inactive Box Success!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            }
            else {
                swal("Error!", "Inactive Box Failed!", "error");
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeStatus = (value) => {
        this.setState({
            typeStatusOption: value,
        });
    };

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        const { selectedOption } = this.state;

        var searchBox = [];
        this.state.listBox.forEach((item) => {
            var mac_address = ""
            if (item.mac_address != null) {
                mac_address = item.mac_address
            }
            if (mac_address.indexOf(this.state.searchBox) !== -1) {
                searchBox.push(item);
            }
        })
        let bulletedBoxListById = searchBox.map((f, i) => {
            if (f.active === 1) {
                this.state.isChecked = true
            }
            if (f.active === 0) {
                this.state.isChecked = false
            }
            var engines = [];
            for (let index = 0; index < f.box_engines.length; index++) {
                engines.push(<div style={{ 'padding': '4px' }} key={index}>{f.box_engines[index].engine.name + ':' + `${f.box_engines[index].engine_version ? f.box_engines[index].engine_version.version : ''}`}</div>);
            }
            var companyName = ''
            if (f.company != null) {
                companyName = f.company.name
            }

            let celebrateDay = new Date(f.time);
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td><Link to={"/detail/" + f.id}
                        onClick={() => {
                            localStorage.setItem("time", celebrateDay);
                            localStorage.setItem("ip_private", '');
                            localStorage.setItem("ip_public", '');
                        }}
                    >{f.id}</Link></td>
                    <td>{f.serial}</td>
                    <td>{f.mac_address}</td>
                    <td style={{ 'cursor': 'pointer', 'style': 'blue' }}
                        onClick={() => {

                            var a = this.state.listBox;
                            var dataEngine = {
                                box_engines: a[i].box_engines,
                                id: a[i].id,
                            };
                            this.setState({
                                modalViewEngine: true,
                                crrEngine: dataEngine,
                            });
                        }}
                    >
                        {engines}
                    </td>
                    {/* <td>{f.view_url_local}</td> */}
                    {/* <td>{f.view_url_public}</td> */}
                    <td>{f.type_of_box}</td>
                    <td>{f.type_of_camera}</td>
                    <td style={{ 'whiteSpace': 'normal' }}>{f.description}</td>
                    <td>{companyName}</td>
                    <td style={{ height: "100%" }}>
                        {/* <Switch
                            onChange={() => { this.handleChecked(f, i) }}
                            checked={this.state.isChecked}
                            handleDiameter={17}
                            offColor="#969696"
                            onColor="#00c5dc"
                            offHandleColor="#ffffff"
                            onHandleColor="#08f"
                            height={25}
                            width={55}
                            className="react-switch"
                            id="small-radius-switch"
                        /> */}
                        {this.state.listStatus.map((item) => {
                            if (item.value === f.status)
                                return <div className="item-status-list-box"><span style={{ backgroundColor: item.backgroundColor, color: "white", textAlign: "center", paddingTop: 3, paddingBottom: 3, paddingLeft: 10, paddingRight: 10, fontSize: 14, fontWeight: 400 }}>{item.label}</span></div>;
                        })}
                    </td>
                    <td>
                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" title="Edit" onClick={() => {

                            var a = this.state.listBox;
                            var cam = {
                                type_of_box: a[i].type_of_box,
                                id_typedevice: a[i].type_device.id,
                                code: a[i].code,
                                serial: a[i].serial,
                                date_in: a[i].date_in,
                                date_out: a[i].date_out,
                                description: a[i].description,
                                status: a[i].status,
                                mac_address: a[i].mac_address,
                                view_url_public: a[i].view_url_public,
                                view_url_local: a[i].view_url_local,
                                company_id: a[i].company_id,
                                company: a[i].company,
                                box_engines: a[i].box_engines,
                                active: 1,
                                id: a[i].id,
                                type_of_camera: a[i].type_of_camera,
                                box_evidences: a[i].box_evidences
                            };
                            this.setState({
                                modalUpdateBoxShow: true,
                                crrCamera: cam,
                            });
                        }}><b><i className="la la-edit"></i></b></button>

                        <button
                            className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                            title="Update Status"
                            onClick={() => {
                                var a = this.state.listBox;
                                var cam = {
                                    type_of_box: a[i].type_of_box,
                                    id_typedevice: a[i].type_device.id,
                                    code: a[i].code,
                                    serial: a[i].serial,
                                    date_in: a[i].date_in,
                                    date_out: a[i].date_out,
                                    description: a[i].description,
                                    status: a[i].status,
                                    mac_address: a[i].mac_address,
                                    view_url_public: a[i].view_url_public,
                                    view_url_local: a[i].view_url_local,
                                    company_id: a[i].company_id,
                                    company: a[i].company,
                                    box_engines: a[i].box_engines,
                                    active: 1,
                                    id: a[i].id,
                                    type_of_camera: a[i].type_of_camera,
                                    box_evidences: a[i].box_evidences,
                                };
                                this.setState({
                                    modalAddStatus: true,
                                    crrCamera: cam,
                                });
                            }}
                        >
                            <b>
                                <i className="flaticon-add"></i>
                            </b>
                        </button>

                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete" onClick={v => {
                            v.preventDefault();
                            swal({
                                title: "Are you sure?",
                                text: "Are you sure that you want to delete Box " + f.id,
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                            })
                                .then(willDelete => {
                                    if (willDelete) {
                                        this.DeleteCamera(f, i);
                                    }
                                });

                        }}><b><i className="la la-trash"></i></b></button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <LoadingOverlay
                        active={this.state.isActive}
                        spinner={<FadeLoader />}
                        text='Loading...'
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#fff0',
                                color: '#525252'
                            }),
                        }}
                    >
                        <div className="m-portlet" >
                            <div className="m-portlet__head">
                                <div className="m-portlet__head-caption" >
                                    <div className="m-portlet__head-title" id = "caption">
                                        <h3 className="m-portlet__head-text">
                                            <span className="m-nav__link-icon text-danger mr-2">
                                                <span className="m-nav__link-icon-wrapper">
                                                    <i className="fa flaticon-symbol" />
                                                </span>
                                            </span>
                                            Boxes
                                        </h3>
                                    </div>
                                    <div id="add" className="m-portlet__head-tools">
                                        <ul className="m-portlet__nav">
                                            <li className="m-portlet__nav-item">
                                                <button onClick={(e) => {
                                                    var cam = {
                                                        type_of_box: null,
                                                        description: null,
                                                        id_typedevice: null,
                                                        code: null,
                                                        serial: null,
                                                        date_in: null,
                                                        date_out: null,
                                                        view_url_public: null,
                                                        view_url_local: null,
                                                        company_id: null,
                                                        company: null,
                                                        box_engines: null,
                                                        active: 1,
                                                        type_of_camera: null,
                                                    };
                                                    this.setState({
                                                        crrCamera: cam,
                                                        modalUpdateBoxShow: true,
                                                    });
                                                }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                                    <span>
                                                        <i className="la la-plus" />
                                                        <span>Add</span>
                                                    </span>
                                                </button>
                                            </li>
                                        </ul>
                                        <ModalUpdateBox
                                            listenginemodel={this.state.listEngineModel}
                                            listengine={this.state.listEngine}
                                            com={this.state.listCompany}
                                            cam={this.state.crrCamera}
                                            modeledit={this.state.valueModel}
                                            listeditsearch={this.state.listCompanySeacrhModal}
                                            listeditvalue={this.state.selectedOption}
                                            show={this.state.modalUpdateBoxShow}
                                            onHide={this.modalClose}
                                        />
                                        <ModalAddStatus
                                            cam={this.state.crrCamera}
                                            show={this.state.modalAddStatus}
                                            onHide={this.modalClose}
                                        />
                                        <ModalShowEngine
                                            dataengine={this.state.crrEngine}
                                            show={this.state.modalViewEngine}
                                            onHide={this.modalCloseShowEngine}
                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="row p-3 col-xl-10" id="menuu">
                                <div id="menu2" className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select type device'}
                                        value={this.state.typeDeviceOption}
                                        onChange={this.handleChangeTypeDevice}
                                        options={this.state.listTypeDeviceSearch}
                                    />
                                </div>

                                <div id="menu2" className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select Company'}
                                        value={selectedOption}
                                        onChange={this.handleChangeCompany}
                                        options={this.state.listCompanySeacrh}
                                        styles={this.colourStyles}
                                    />
                                </div>
                                <div id="menu2" className="col-xl-2 pl-0 mb-3">
                                    <Select
                                        placeholder={"Select Status"}
                                        value={this.state.typeStatusOption}
                                        onChange={this.handleChangeStatus}
                                        options={this.state.listStatus}
                                        styles={this.colourStyles}
                                    />
                                </div>
                                <div id="menu2" className="col-xl-1 pl-0 mb-3">
                                    <Select
                                        placeholder={'Select Search'}
                                        value={this.state.valueSearch}
                                        onChange={this.handleChangeSearch}
                                        options={this.state.listSearch}
                                    />
                                </div>

                                <span id="input1" className="col-lg-2 m--margin-bottom-10-tablet-and-mobile pl-0">
                                    <input type="text" onKeyUp={(event) => this.isChangeInputSearch(event)} id="inputSearch" className="form-control m-input" placeholder={
                                        this.state.valueSearch.value == 1
                                            ?
                                            'Mac Address'
                                            :
                                            this.state.valueSearch.value == 2
                                                ?
                                                'ID Box'
                                                :
                                                this.state.valueSearch.value == 3
                                                    ?
                                                    'ID Box Engine'
                                                    :
                                                    'Serial'
                                    } data-col-index={0} />
                                </span>
                                <span id = "search">
                                    <button
                                        onClick={() => {
                                            this.btnSearch(this.state.tempValue)
                                        }}
                                        className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                        <span>
                                            <i className="la la-search" />
                                            <span>Search</span>
                                        </span>
                                    </button>
                                </span>
                                <span id="reset" className="col-lg-1">
                                    <button
                                        onClick={() => {
                                            this.reset()
                                        }}
                                        className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                        <span>
                                            <i className="la la-remove" />
                                            <span>Reset</span>
                                        </span>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    {/*begin:: Widgets/Stats2-1 */}
                                    <div className="m-widget1">
                                        <div className="table-responsive text-nowrap mb-3">
                                            <table className="table table-bordered table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle' }}>STT</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Box ID</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Serial</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Mac Address</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Engines</th>
                                                        {/* <th style={{ 'verticalAlign': 'middle' }}>URL Local</th> */}
                                                        {/* <th style={{ 'verticalAlign': 'middle' }}>URL Public</th> */}
                                                        <th style={{ 'verticalAlign': 'middle' }}>Type of Box</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Type of Camera</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'minWidth': '250px' }}>Description (Vị Trí)</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Company</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Status</th>
                                                        <th style={{ 'verticalAlign': 'middle' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedBoxListById}
                                                </tbody>
                                            </table>
                                            <div className="text-center mt-3">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                        <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                        </span>
                                        <Pagination
                                            prevPageText='Previous'
                                            nextPageText='Next'
                                            firstPageText='First'
                                            lastPageText='Last'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        )
    }
}

export default BoxList;

import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Login from '../Login';
import MenuBar from '../components/layouts/MenuBar';
import Store from '../store';
import Company from '../components/action/Company';
import Device from '../components/action/Device';
import ListBox from '../components/action/ListBox';
import DetailDevice from '../components/action/DetailDevice';
import User from '../components/action/User';
import Engine from '../components/action/Engine';
import Version from '../components/action/Version';
import ModelEngine from '../components/action/ModelEngine';
import Benchmark from '../components/action/Benchmark';
import { withRouter } from 'react-router';
import Modules from '../components/action/Modules';
import TypeDevice from '../components/action/TypeDevice';
import License from '../components/action/License';
import Qrcode from '../components/action/Qrcode';
import Dashboard from '../components/action/Dashboard'
import { logout } from '../actions/isLogin';
import { HOST } from '../config'
import ReportStatus from '../components/action/ReportStatus';
import Server from '../components/action/Server';
import DetailServer from '../components/action/DetailServer';
class ReactRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin
            })
        });
    }

    getUser = () => {
            
            fetch(`${HOST}/api/user/getByToken`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                }
                
            }).then((response) => {
                
                if (response.status == 401) {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                }
                console.log(response)
                return response.json();
                
            })

    }

    componentDidUpdate(prevProps, prevState) {
        //TODO 
        // if (prevState.isLogin === this.state.isLogin) {
        //     this.getUser()
        // }
    }

    render() {
        var dislable;
        if (this.state.isLogin === false || this.props.location.pathname === "/login") {
            dislable = 'y pdtop-none pl-0';
        } else {
            dislable = '';
        }
        return (
            <div className={"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body" + dislable}>
                <button className="m-aside-left-close  m-aside-left-close--skin-dark " id="m_aside_left_close_btn">
                    <i className="la la-close" />
                </button>
                <MenuBar />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/" exact component={Dashboard} />  
                {
                    process.env.REACT_APP_HOST_MONITOR == ''
                    ? null
                    :
                    <>
                        <Route path="/device" component={Device} />
                    </>
                }
                <Route path="/detail/:id" component={DetailDevice} />
                {
                    process.env.REACT_APP_HOST_SERVER == ''
                    ? null
                    :
                    <>
                        <Route path="/server" component={Server} />
                    </>
                }
                <Route path="/infor/:server_id" component={DetailServer} />
                {
                    process.env.REACT_APP_HOST_BENCHMARK == ''
                    ? null
                    :
                    <>  
                        <Route path="/benchmark" component={Benchmark} />
                    </>
                }
                <Route path="/listbox" component={ListBox} />
                <Route path="/company" component={Company} />
                <Route path="/listuser" component={User} />
                <Route path="/engine" component={Engine} />
                <Route path="/version" component={Version} />
                <Route path="/modelengine" component={ModelEngine} />
                <Route path="/login" component={Login} />
                <Route path="/modules" component={Modules} />
                <Route path="/typedevice" component={TypeDevice} />
                <Route path="/license" component={License} />
                <Route path="/qrcode" component={Qrcode} />
                {process.env.REACT_APP_HIDE_REPORT == "0" &&
                    <Route path="/reportstatus" component={ReportStatus} />
                }
            </div>
        );
    }
}
export default withRouter(ReactRouter);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import { PulseLoader } from 'react-spinners';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';
import Select from "react-select";
import Files from 'react-files';

class ModalEngineVersion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listEngine: [],
            listEngineVersion: [],
            dataEngineVersion: {
                engine_id: "",
                version: "",
                path: "",

            },
            valueEngine: {},
            name_file: "",
            size: '',
            loading: false
        }
    }

    UpdateEngineVersion = async (engineVersion, event) => {
        //Insert

        var form = $("#formAddEngine")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            $('#save').addClass('d-none')
            this.setState({
                loading: true,
            });
            if (engineVersion.id == undefined) {
                let formData = new FormData();
                formData.append('file', engineVersion.path);
                formData.append('version', engineVersion.version);
                formData.append('engine_id', engineVersion.engine_id);
                let data = await fetch(`${HOST}/api/engineVersion/insert`, {
                    method: 'POST',
                    headers: {
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: formData
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    this.setState({
                        loading: false,
                    })
                    swal("Sucess!", "Add Engine Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return (
                        this.props.onHide()
                    );
                }
                else {
                    this.setState({
                        loading: false,
                    })
                    swal("Error!", "Add Engine Failed!", "error");
                }
            } else {
                let formData = new FormData();
                formData.append('id', engineVersion.id);
                formData.append('file', engineVersion.path);
                formData.append('version', engineVersion.version);
                formData.append('engine_id', engineVersion.engine_id);
                let data = await fetch(`${HOST}/api/engineVersion/update`, {
                    method: 'POST',
                    headers: {
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: formData
                }).then((response) => {
                    return (
                        response.json()
                    )
                });
                if (data.status === 10000) {
                    this.setState({
                        loading: false,
                    })
                    swal("Sucess!", "Edit Engine Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    return this.props.onHide();
                }
                else {
                    this.setState({
                        loading: false,
                    })
                    swal("Error!", "Edit Engine Failed!", "error");
                }
            }

        }
    }


    engineHandle(e) {
        var engineHand = this.state.dataEngineVersion;
        engineHand[e.target.name] = e.target.value;
        this.setState({ dataEngineVersion: engineHand });
    }

    handleChangeEngine(value) {
        var engine = this.state.dataEngineVersion;
        engine['engine_id'] = value.value;
        this.setState({
            valueEngine: { value: value, label: value.label },
            dataEngineVersion: engine
        })
    }



    componentWillReceiveProps(nextProps) {
        var dataOption;
        var engineOption = [];
        nextProps.listengine.forEach(e => {
            dataOption = { value: e.id, label: e.name, level: e.level };
            engineOption.push(dataOption);
        });
        var valueEng
        if (nextProps.crrengineversion.engine != undefined) {
            valueEng = { value: nextProps.crrengineversion.engine.id, label: nextProps.crrengineversion.engine.name }
        }

        if (nextProps.show === true) {
            this.setState({
                loading: false,
                valueEngine: valueEng,
                name_file: "",
                size: '',
                dataEngineVersion: nextProps.crrengineversion,
                listEngine: engineOption,
            });
        }
    }

    onFilesChange = (files) => {
        if (files.length > 0) {
            var engine = this.state.dataEngineVersion;
            engine['path'] = files[0];
            this.setState({
                dataEngineVersion: engine,
                name_file: files[0].name,
                size: '(' + files[0].size + ' bytes)'
            });
        }
    }

    onFilesError = (error, file) => {
        swal("Error!", error.message, "error", {
            buttons: false,
            timer: 3000,
        });
    }


    render() {
        let click_handle = (event) => {
            this.UpdateEngineVersion(this.state.dataEngineVersion, event);
        }
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddEngine">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className={"form-group m-form__group"}>
                                    <label htmlFor="Engine">Engine <span class="text-danger">* </span></label>
                                    <Select
                                        onChange={value => this.handleChangeEngine(value)}
                                        options={this.state.listEngine}
                                        placeholder="Select Engine"
                                        value={this.state.valueEngine}
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Version">Version <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="Version" name='version' value={this.state.dataEngineVersion.version === '' ? '' : this.state.dataEngineVersion.version} onChange={e => this.engineHandle(e)} placeholder="Version" required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Path">Path <span class="text-danger">* </span></label>
                                    <Files
                                        className='files-dropzone btn btn-accent'
                                        onChange={this.onFilesChange}
                                        onError={this.onFilesError}
                                        accepts={['application/gzip', 'application/x-gzip', 'application/x-gunzip', 'application/gzipped', 'application/gzip-compressed', 'application/x-compressed', 'application/x-compress', 'gzip/document', 'application/octet-stream']}
                                        multiple={false}
                                        clickable
                                    >
                                        Drop files here or click to upload
                                    </Files>
                                    <div className="pt-2">{this.state.name_file} {this.state.size}</div>
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="btn_save" onClick={click_handle} className={this.state.loading === true ? 'm-loader--light m-loader--right m-loader' : ''} disabled={this.state.loading}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalEngineVersion.propTypes = {
    crrengineversion: PropTypes.object,
    listengine: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalEngineVersion;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';
import Select from "react-select";

class ModalModelEngine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listEngine: [],
            listEngineVersion: [],
            dataEngineModel: {
                engine_id: "",
                name: "",
                description: "",
                
            },
            valueEngine: {},
            disabled: false, 
        }
    }

    UpdateEngineModel = async (engineModel,event) => {
        //Insert
        var form = $("#formAddEngineModel")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            this.setState({ disabled: true})
            if (engineModel.id == undefined) {
                let data = await fetch(`${HOST}/api/engineModel/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(engineModel)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Add Model Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                     return this.props.onHide();
                    
                }
                else {
                    this.setState({
                        disabled: false
                    })
                    swal("Error!", "Add Model Failed!", "error");
                    
                }
            } else {
                let data = await fetch(`${HOST}/api/engineModel/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(engineModel)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit Model Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                    return this.props.onHide();
                }
                else {
                    swal("Error!", "Edit Model Failed!", "error");
                    this.setState({
                        disabled: false
                    })
                }
            }
            
        }
    }


    engineHandle(e) {
        var engineHand = this.state.dataEngineModel;
        engineHand[e.target.name] = e.target.value;
        this.setState({ dataEngineModel: engineHand });
    }

    handleChangeEngine(value) {
        var engine = this.state.dataEngineModel;
        engine['engine_id'] = value.value;
        this.setState({
            valueEngine: {value: value , label: value.label},
            dataEngineModel: engine
        })
    }

    

    componentWillReceiveProps(nextProps) {
        var dataOption;
        var engineOption = [];
        nextProps.listengine.forEach(e => {
            dataOption = { value: e.id, label: e.name, level: e.level };
            engineOption.push(dataOption);
        });
        var valueEngModel
        if (nextProps.crrenginemodel.engine != undefined) {
            valueEngModel = {value: nextProps.crrenginemodel.engine.id , label: nextProps.crrenginemodel.engine.name}
        }
        
        if (nextProps.show === true) {
            this.setState({
                valueEngine: valueEngModel,
                dataEngineModel: nextProps.crrenginemodel,
                listEngine: engineOption,
            });
        }
    }

    render() {
        let click_handle = (event) => {
            this.UpdateEngineModel(this.state.dataEngineModel,event);
        }
        
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddEngineModel">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className={"form-group m-form__group"}>
                                    <label htmlFor="Engine">Engine <span class="text-danger">* </span></label>
                                    <Select
                                        onChange={value => this.handleChangeEngine(value)}
                                        options={this.state.listEngine}
                                        placeholder="Select Engine"
                                        value={this.state.valueEngine}
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="name">Name <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="name" name='name' value={this.state.dataEngineModel.name === '' ? '' : this.state.dataEngineModel.name} onChange={e => this.engineHandle(e)} placeholder="Name" required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="api_url">API_Url</label>
                                    <input className="form-control m-input" id="api_url" name='api_url' value={this.state.dataEngineModel.api_url === '' ? '' : this.state.dataEngineModel.api_url} onChange={e => this.engineHandle(e)} placeholder="API_Url"/>
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="api2_url">API2_Url</label>
                                    <input className="form-control m-input" id="api2_url" name='api2_url' value={this.state.dataEngineModel.api2_url === '' ? '' : this.state.dataEngineModel.api2_url} onChange={e => this.engineHandle(e)} placeholder="API2_Url"/>
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">Description</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" value={this.state.dataEngineModel.description === '' ? '' : this.state.dataEngineModel.description} name='description' onChange={e => this.engineHandle(e)} placeholder="Description" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle} className={this.state.disabled === true ? 'm-loader--light m-loader--right m-loader' : ''}  disabled={this.state.disabled}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalModelEngine.propTypes = {
    crrenginemodel: PropTypes.object,
    listengine: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalModelEngine;
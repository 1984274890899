import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';
import Select from "react-select";

function padDigits(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}

class ModalEngine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listEngine: [],
            opEngine: [],
            dataEngine: {
                name: "",
                level: null,
                description: ""
            },
            disabled: false
            

        }
    }

    UpdateEngine = async (engine, event) => {
        //Insert
        
        if (engine.level == null) {
            var listenginepar = []
            for (let index = 0; index < this.state.listEngine.length; index++) {
                if (this.state.listEngine[index].level.length == 4) {
                    listenginepar.push(this.state.listEngine[index])
                }
            }
            var datalength = listenginepar.length + 1;
            engine['level'] = padDigits(datalength, 4);
        }

        var form = $("#formAddEngine")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            this.setState({ disabled: true})
            if (engine.id == undefined) {
                let data = await fetch(`${HOST}/api/engine/insert`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(engine)
                }).then((response) => {
                    return response.json()
                });

                if (data.status === 10000) {
                    swal("Sucess!", "Add Engine Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                    return this.props.onHide();
                }
                else {
                    this.setState({
                        disabled: false
                    })
                    swal("Error!", "Add Engine Failed!", "error");
                    
                }
            } else {
                let data = await fetch(`${HOST}/api/engine/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(engine)
                }).then((response) => {
                    return response.json()
                });
                if (data.status === 10000) {
                    swal("Sucess!", "Edit Engine Success!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.setState({
                        disabled: false
                    })
                    return this.props.onHide();
                }
                else {
                    swal("Error!", "Edit Engine Failed!", "error");
                    this.setState({
                        disabled: false
                    })
                }
            }

        }
    }


    engineHandle(e) {
        var engineHand = this.state.dataEngine;
        engineHand[e.target.name] = e.target.value;
        this.setState({ dataEngine: engineHand });
    }

    handleChangeEngine(value) {
        var enginelv = this.state.dataEngine;
        var engine = [];
        this.state.listEngine.forEach((item) => {
            if (item.level.indexOf(value.level.slice(0, 4)) == 0) {
                engine.push(item);
            }
        })
        var lengthEngineChild = engine.length;
        if (value.level.length == 4) {
            enginelv['level'] = value.level + padDigits(lengthEngineChild, 4)
        } else {
            enginelv['level'] = value.level.slice(0, 4) + padDigits(lengthEngineChild, 4)
        }
        
        this.setState({
            dataEngine: enginelv
        })
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {

            var dataOption;
            var engineOption = [];
            nextProps.listengine.forEach(e => {
                dataOption = { value: e.id, label: e.name, level: e.level };
                engineOption.push(dataOption);
            });

            this.setState({
                dataEngine: nextProps.crrengine,
                opEngine: engineOption,
                listEngine: nextProps.listengine,
            });
        }
    }

   

    render() {
        let click_handle = (event) => {
            this.UpdateEngine(this.state.dataEngine, event);
        }

        var display;
        if (this.state.dataEngine.show == true) {
            display = 'd-none';
        } else {
            display = '';
        }
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddEngine">
                        <div className="col-xl-12">
                            <div className="m-widget14">

                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">Name <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="Name" name='name' value={this.state.dataEngine.name === '' ? '' : this.state.dataEngine.name} onChange={e => this.engineHandle(e)} placeholder="Name" required />
                                </div>
                                <div className={"form-group m-form__group " + display}>
                                    <label htmlFor="Name">Engine <span class="text-danger">* </span></label>
                                    <Select
                                        onChange={value => this.handleChangeEngine(value)}
                                        options={this.state.opEngine}
                                        placeholder="Select Engine"
                                    />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Description">Description</label>
                                    <textarea rows="4" className="form-control m-input" id="Description" name='description' value={this.state.dataEngine.description === '' ? '' : this.state.dataEngine.description}  onChange={e => this.engineHandle(e)} placeholder="Description" />
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle} className={this.state.disabled === true ? 'm-loader--light m-loader--right m-loader' : ''} disabled={this.state.disabled}>Save</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalEngine.propTypes = {
    crrengine: PropTypes.object,
    listengine: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalEngine;
import React, { Component, PureComponent } from 'react';
import { HOST } from '../../config';
import Store from '../../store';
import moment from 'moment'
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/core';



class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataDashBoard: null,
            company: [],
            isLogin: Store.getState().isLogin.isLogin,
            
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
    }



    getData = () => {
        fetch(`${HOST}/api/server/dashboard`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            }
        }).then((res) => {
            return res.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    dataDashBoard: data.data
                })
            }
            else{
                this.setState({
                    dataDashBoard:null
                })
            }
            
        })
    }


    getAllCompany = () => {
        fetch(`${HOST}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                is_deleted: 0,
                item_per_page: 0,
                index: 0,
                data_search: "",
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({
                company: data.data
            })

        })
    }

    componentDidMount() {
        this.getData();
        this.getAllCompany()
    }





    render() {

        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        console.log(this.state.dataDashBoard)
        var expiredDay = (this.state.dataDashBoard !== null && this.state.dataDashBoard.expired_day)
        var onlyDate = moment(expiredDay)
        var companyOutDate = (this.state.dataDashBoard !== null && this.state.dataDashBoard.companies_out_of_date.length)
 

        return (
            <div className="m-subheader" style={{ width: '100%' }} >
                <div className="m-portlet m-portlet--creative m-portlet--first m-portlet--bordered-semi">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h2 className="m-portlet__head-label m-portlet__head-label--danger">
                                    <span>Licenses</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <ul>
                            <li>Expired Day: <span className="font-bold">{onlyDate.format("YYYY-MM-DD")}</span> </li>
                            <li>Limited Users: <span className="font-bold">{this.state.dataDashBoard !== null && this.state.dataDashBoard.limit_staff}</span></li>
                            <li>Limited Boxes: <span className="font-bold">{this.state.dataDashBoard !== null && this.state.dataDashBoard.limit_devices}</span> </li>
                            {/* {dataFeatures} */}
                        </ul>
                    </div>
                </div>
                <div className="m-portlet m-portlet--creative m-portlet--first m-portlet--bordered-semi">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h2 className="m-portlet__head-label m-portlet__head-label--info">
                                    <span>Status</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <ul>
                            <li>Users: <span className="font-bold">{this.state.dataDashBoard !== null && this.state.dataDashBoard.current_staffs} / {this.state.dataDashBoard !== null && this.state.dataDashBoard.limit_staff}</span> </li>
                            <li>Boxes: <span className="font-bold">{this.state.dataDashBoard !== null && this.state.dataDashBoard.current_devices} / {this.state.dataDashBoard !== null && this.state.dataDashBoard.limit_devices}</span> </li>
                            <li>Using Companies: <span className="font-bold">{this.state.company.length - companyOutDate} / {this.state.dataDashBoard !== null && this.state.dataDashBoard.current_companies}</span> </li>
                        </ul>
                    </div>
                </div>


            </div >


        );
    }
}

export default Dashboard;

import React, { Component } from 'react';
import Store from '../../store';
import { withRouter } from 'react-router';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin
            })
        });
    }
    render() {
        var dislable;
        if (this.state.isLogin === false || this.props.location.pathname === "/login") {
            dislable = 'd-none';
        }
        return (
            <footer className={"m-grid__item m-footer " + dislable}>
                <div className="m-container m-container--fluid m-container--full-height m-page__container">
                    <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                            <span className="m-footer__copyright">
                                2019 © BeetAI
                            </span>
                        </div>
                        <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                            <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                                <li className="m-nav__item">
                                    <a href="/" className="m-nav__link">
                                        <span className="m-nav__link-text">About</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a href="/" className="m-nav__link">
                                        <span className="m-nav__link-text">Privacy</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a href="/" className="m-nav__link">
                                        <span className="m-nav__link-text">T&amp;C</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a href="/" className="m-nav__link">
                                        <span className="m-nav__link-text">Purchase</span>
                                    </a>
                                </li>
                                <li className="m-nav__item m-nav__item">
                                    <a href="/" className="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left">
                                        <i className="m-nav__link-icon flaticon-info m--icon-font-size-lg3" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer);
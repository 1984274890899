import React, { Component } from "react";
import { HOST } from '../../config';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import ModalEngineVersion from "../modal/ModalEngineVersion";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var fileDownload = require('js-file-download');

class Version extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listEngineVersion: [],
            crrListEngineVersion: [],
            listEngine: [],
            crrEngine: [],
            crrEngineVersion: {
                engine_id: "",
                version: "",
                path: "",
            },
            activePage: 1,
            loading: true,
            offset: 0,
            showFirst: 0,
            showLast: 0,
            modalEngineVersion: false,
            loading: true,
            searchEngine: '',
            AllEngineVersion: [],
            tempValue: '',
            AllListEngineVersion: [],
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
        this.getEngineVersion();
        this.getEngine();
    }

    getEngine = () => {
        fetch(`${HOST}/api/engine/getAll`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.data.length; index++) {
                    data.data[index]._name = data.data[index].name;
                    if (data.data[index].level.length > 4) {
                        data.data[index].name = '---- ' + data.data[index].name;
                    }
                }
                this.setState({
                    listEngine: data.data,
                });
            }
        })
    }

    getEngineVersion = () => {
        fetch(`${HOST}/api/engineVersion/getAll`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listEngineVersion: data.data,
                    AllListEngineVersion: [...data.data],
                    loading: false
                });
                this.FilterEngine(this.state.activePage);
            }
        })
    }

    FilterEngine = (activePage) => {
        var searchEngine = [];
        this.state.AllListEngineVersion.forEach((item) => {
            if (item.engine.name.toLowerCase().indexOf(this.state.searchEngine.toLowerCase()) !== -1) {
                searchEngine.push(item);
            }
        })
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrListEngineVersion = searchEngine.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrListEngineVersion,
            offset,
            showFirst: offset + 1,
            showLast: crrListEngineVersion.length + offset,
            totalLength: searchEngine.length
        });
    }

    DeleteVersionEngine = async (version, i) => {
        let data = await fetch(`${HOST}/api/engineVersion/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': version.id
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            swal("Deleted!", "You have successfully deleted!", "success", {
                buttons: false,
                timer: 1500,
            });
            this.getEngineVersion();
        }
        else {
            swal("Error!", "Delete Failed!", "error");
        }

    }


    // DownloadVersionEngine = async (version, i) => {
    //     let data = await fetch(`${HOST}/api/engineVersion/download/${version.path}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': Store.getState().isLogin.access_token,
    //         }
    //     }).then(function (resp) {
    //         return resp.blob();
    //     }).then(function (blob) {
    //         fileDownload(blob, version.path);
    //     });

    // }



    btnsearchEngine = (dataSearch) => {
        var searchEngine = [];
        this.state.AllListEngineVersion.forEach((item) => {
            if (item.engine.name.toLowerCase().indexOf(dataSearch.toLowerCase()) !== -1) {
                searchEngine.push(item);
            }
        })
        this.setState({
            searchEngine: dataSearch,
            activePage: 1
        },() =>{
            this.state.listEngineVersion = searchEngine;
            this.FilterEngine(1);
        });
    }

    reset = (e) => {
        this.setState({
            searchEngine: '',
            activePage: 1
        });
        $('#inputSearch').val('');
        this.state.listEngine = this.state.AllListEngineVersion;
        this.getEngine();
    }
    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnsearchEngine(event.target.value.trim())
        }
        this.setState({
            tempValue: event.target.value.trim()
        });
    }

    modalClose = () => {
        this.setState({
            modalEngineVersion: false,
            crrEngineVersion: {
                engine_id: "",
                version: "",
                path: "",
            },
        });
        this.getEngineVersion();
    }
    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterEngine(pageNumber);
    }
    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedEngineVersion = this.state.crrListEngineVersion.map((e, i) => {
            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{e.id}</td>
                    <td>{e.engine.name}</td>
                    <td>{e.version}</td>
                    <td>{e.path}</td>
                    <td>
                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill" title="download"
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to download " + e.engine.name + '-' + e.version,
                                    icon: "warning",
                                    button: {
                                        text: "Download!",
                                        className: "text-center",
                                        closeModal: false,
                                    },
                                })
                                    .then(name => {
                                        if (!name) throw null;

                                        return fetch(`${HOST}/api/engineVersion/download/${e.path}`, {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': Store.getState().isLogin.access_token,
                                            }
                                        })
                                    })
                                    .then(results => {
                                        return results.blob();
                                    })
                                    .then(blob => {

                                        if (!blob) {
                                            return swal("No movie was found!");
                                        }
                                        fileDownload(blob, e.path);
                                        swal.close();
                                    })
                                    .catch(err => {
                                        if (err) {
                                            swal("Oh noes!", "The AJAX request failed!", "error");
                                        }
                                    });
                            }}
                        ><i className="la la-download"></i></button>

                        <button onClick={(e) => {
                            var data = this.state.crrListEngineVersion
                            var dataEngine = {
                                id: data[i].id,
                                engine_id: data[i].engine.id,
                                engine: data[i].engine,
                                version: data[i].version,
                                path: data[i].path,
                            };
                            this.setState({
                                crrEngineVersion: dataEngine,
                                modalEngineVersion: true,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                            <i className="la la-edit" />
                        </button>

                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete " + e.engine.name + '-' + e.version,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteVersionEngine(e, i);
                                        }
                                    });

                            }}
                        ><i className="la la-trash"></i></button>
                    </td>
                </tr>
            )
        });

        return (

            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                  <div className="m-portlet" >
                    <div className="m-portlet__head" >
                        <div className="m-portlet__head-caption" id = "caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="la la-object-ungroup" />
                                        </span>
                                    </span>
                                    Engine Versions
                                </h3>
                            </div>
                        </div>
                        <div id = "add1" className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {

                                        this.setState({
                                            modalEngineVersion: true,

                                        });


                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>

                            <ModalEngineVersion
                                crrengineversion={this.state.crrEngineVersion}
                                listengine={this.state.listEngine}
                                show={this.state.modalEngineVersion}
                                onHide={this.modalClose}
                            />
                        </div>
                      </div>
                        <div id = "menu" className="row p-3 col-xl-10">
                            <span id = "input3" className="col-lg-2 m--margin-bottom-10-tablet-and-mobile">
                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Name of Engine Version" data-col-index={0} />
                            </span>
                            <span id = "search">
                                <button
                                    onClick={(dataSearch) => {
                                        this.btnsearchEngine(this.state.tempValue)
                                    }}
                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                    <span>
                                        <i className="la la-search" />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </span>
                            <span id = "reset2" className="col-lg-3">
                                <button
                                    onClick={() => {
                                        this.reset()
                                    }}
                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                    <span>
                                        <i className="la la-remove" />
                                        <span>Reset</span>
                                    </span>
                                </button>
                            </span>
                        </div>
                        
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>ID</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Engine</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Version</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Path</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedEngineVersion}
                                            </tbody>
                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                        <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                            </span>
                                        <Pagination
                                            prevPageText='Previous'
                                            nextPageText='Next'
                                            firstPageText='First'
                                            lastPageText='Last'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Version;

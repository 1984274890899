import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../../config';
import swal from 'sweetalert';
import Store from '../../../store';
import $ from 'jquery';
class ModalShowEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listEngine: null,
            detailSmallEngine: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                listEngine: nextProps.dataengine.box_engines,
            });
        }
        if (nextProps.dataengine.box_engines != null) {
            nextProps.dataengine.box_engines.map((e) => {
                this.handleChangeShowEngine(e.id);
            })
        }
    }

    handleChangeShowEngine = (id) => {
        fetch(`${HOST}/api/boxEngine/getById/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var listModel = data.data.box_engine_models.map((childBoxEngineModels, i) => {
                    var main;
                    if (childBoxEngineModels.is_main == 1) {
                        main = 'Is Main'
                    }
                    if (childBoxEngineModels.is_main == 0) {
                        main = 'Not Is Main'
                    }

                    let apiUrlSplit;
                    if (childBoxEngineModels.model.api_url != null &&
                        childBoxEngineModels.model.api_url != undefined &&
                        childBoxEngineModels.model.api_url != "") {
                        
                        let apiUrlList = childBoxEngineModels.model.api_url.substring(0, childBoxEngineModels.model.api_url.length + 1).split('\;');
                        apiUrlSplit = apiUrlList.map((childApiUrl, index) => {
                            
                            return (
                                <div style = {{'cursor': 'context-menu'}} key={"apiurl_" + index} id={"apiurl_" + index} onClick={() => { { this.coppyText("#apiurl_" + index) } }}>
                                    {childApiUrl}
                                    <span><i style={{ 'color': '#17a2b8','margin': '2px' }} className="fa fa-copy"></i></span>
                                </div>
                            )
                        })
                    };

                    let api2UrlSplit;
                    if (childBoxEngineModels.model.api2_url != null &&
                        childBoxEngineModels.model.api2_url != undefined &&
                        childBoxEngineModels.model.api2_url != "") {
                        
                        let api2UrlList = childBoxEngineModels.model.api2_url.substring(0, childBoxEngineModels.model.api_url.length + 1).split('\;');
                        api2UrlSplit = api2UrlList.map((childApi2Url, index) => {
                            
                            return (
                                <div style = {{'cursor': 'context-menu'}} key={"api2url_" + index} id={"api2url_" + index} onClick={() => { { this.coppyText("#api2url_" + index) } }}>
                                    {childApi2Url}
                                    <span><i style={{ 'color': '#17a2b8','margin': '2px' }} className="fa fa-copy"></i></span>
                                </div>
                            )
                        })
                    };

                    return (
                        <tr key={"listModel" + i}>
                            <td>{childBoxEngineModels.model.name}</td>
                            <td>{childBoxEngineModels.threshold}</td>
                            <td>{apiUrlSplit}</td>
                            <td>{api2UrlSplit}</td>
                            <td>{main}</td>
                        </tr>
                    );
                });

                var dataModels =
                    <tr key={'tr_child_show' + id} id={"tr_child_show" + id} className="tr_child_show">
                        <td colSpan="12">
                            <div className="col-xl-12">
                                <table style={{ 'width': '100%' }} className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                    <thead>
                                        <tr>
                                            <th>Models</th>
                                            <th>Threshold</th>
                                            <th>api_url</th>
                                            <th>api2_url</th>
                                            <th>Main</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listModel}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>;

                this.setState({ detailSmallEngine: dataModels });
            }
        })
    }

    coppyText = (element) => {
        if ($(element).text() != '') {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($(element).text()).select();
            document.execCommand("copy");
            $temp.remove();
            swal("Copy Success!", $(element).text(), "success", {
                buttons: false,
                timer: 1500,
            });
        }
    }


    render() {
        var crrEngine;
        if (this.state.listEngine != null) {
            crrEngine = this.state.listEngine.map((e, i) => {
                console.log(crrEngine)
                var active;
                if (e.active == 1) {
                    active = <span className="m-badge m-badge--success m-badge--wide">Active</span>
                } else {
                    active = <span className="m-badge m-badge--secondary m-badge--wide">Inactive</span>
                }

                let rtspSplit;
                if (e.rtsp != null && e.rtsp != undefined) {
                 let rtspList = e.rtsp.split('\;'); 
                 rtspSplit = rtspList.map((childRtsp, index) => {
                     if (childRtsp.trim() == "" || childRtsp.trim().length == 0) {
                       return "";
                     } else {
                       return (
                       <div key={"rtsp_" + index} id={"rtsp_" + index} onClick={() => {{ this.coppyText("#rtsp_" + index) }}}>{childRtsp}<i style={{ 'color': '#17a2b8','margin': '5px' }} className="fa fa-copy"></i>
                       </div>
                       )
                     }}
                     )}
                return ([
                    <tr key={i} id={"tr_child" + e.id} className="hiden_row_child">
                        <td>{e.id}</td>
                        <td>{e.alias}</td>
                        <td style={{ 'cursor': 'pointer' }}>{e.engine.name}</td>
                        <td>{e.engine_version.version}</td>
                        <td id={'coppy' + e.id}>{rtspSplit}</td>
                        <td>{active}</td>
                    </tr>,
                    this.state.detailSmallEngine
                ])
            })
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Detail Engine
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-nowrap" id="table_devices">
                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" style={{ 'whiteSpace': 'normal' }}>
                            <thead>
                                <tr>
                                    <th style={{ 'verticalAlign': 'middle' }}>ID</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>Alias</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>Engine</th>

                                    <th style={{ 'verticalAlign': 'middle', 'width': '80px' }}>Version</th>
                                    <th style={{ 'verticalAlign': 'middle', 'wordWrap': 'break-word' }}>RTSP</th>
                                    <th style={{ 'verticalAlign': 'middle' }}>Active</th>
                                </tr>
                            </thead>
                            <tbody id="show_child_row">
                                {crrEngine}
                            </tbody>

                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


ModalShowEngine.propTypes = {
    dataengine: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalShowEngine;
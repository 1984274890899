import React, { Component } from "react";
import { HOST } from '../../config';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import ModalModules from "../modal/ModalModules";
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Modules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listModules: [],
            crrlistModules: [],
            crrModulesModal: {
                "id_typedevice": null,
                "code": "",
                "version": "",
                "name_typedevice": "",
            },
            activePage: 1,
            offset: 0,
            showFirst: 0,
            showLast: 0,
            ModalModules: false,
            loading: true,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
            })
        });
        this.itemsPerPage = 10;
    }

    componentDidMount() {
        this.getModules()
    }

    getModules = () => {
        fetch(`${HOST}/api/module/get_all`, {
            method: 'GET',
            headers: {
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                this.setState({
                    listModules: data.data,
                    loading: false
                }, () => {
                    this.FilterModules(this.state.activePage);
                });
            }
        })
    }

    FilterModules = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrlistModules = this.state.listModules.slice(offset, offset + this.itemsPerPage);
        this.setState({
            crrlistModules,
            offset,
            showFirst: offset + 1,
            showLast: crrlistModules.length + offset,
            totalLength: this.state.listModules.length
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        })
        this.FilterModules(pageNumber);
    }

    DeleteModules = async (data, i) => {
        await fetch(`${HOST}/api/module/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                'id': data.id
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                swal("Deleted!", "You have successfully deleted!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.getModules();
            }
            else {
                swal("Error!", "Delete Failed!", "error");
            }
        });


    }

    modalClose = () => {
        this.setState({
            ModalModules: false,
        });
        this.getModules();
    }

    render() {
        if (this.state.isLogin == false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedEngineVersion = this.state.crrlistModules.map((e, i) => {
            var typeDevice = ""
            if (e.type_device !== null) {
                typeDevice = e.type_device.name
            }
            return (
                <tr key={(i + 1)}>
                    <td>{i + this.state.offset + 1}</td>
                    <td>{typeDevice}</td>
                    <td>{e.code}</td>
                    <td>{e.version}</td>
                    <td>
                        <button onClick={(e) => {
                            var data = this.state.crrlistModules
                            var dataModules = {
                                id: data[i].id,
                                id_typedevice: data[i].id_typedevice,
                                code: data[i].code,
                                version: data[i].version,
                                name_typedevice: typeDevice,
                            };
                            this.setState({
                                crrModulesModal: dataModules,
                                ModalModules: true,
                            });


                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="m-tooltip" data-placement="bottom" data-skin="dark" data-original-title="Edit">
                            <i className="la la-edit" />
                        </button>

                        {/* <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="delete"
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Are you sure that you want to delete " + e.code,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteModules(e, i);
                                        }
                                    });

                            }}
                        ><i className="la la-trash"></i></button> */}
                    </td>
                </tr>
            )
        });

        return (

            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                <div className="m-portlet m-portlet--full-height m-portlet--fit">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    <span className="m-nav__link-icon text-danger mr-2">
                                        <span className="m-nav__link-icon-wrapper">
                                            <i className="la la-object-ungroup" />
                                        </span>
                                    </span>
                                    Modules
                                </h3>
                            </div>
                        </div>
                        <div className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                <li className="m-portlet__nav-item">
                                    <button onClick={(e) => {
                                        this.setState({
                                            ModalModules: true,
                                            crrModulesModal: {
                                                "id_typedevice": null,
                                                "code": "",
                                                "version": "",
                                                "name_typedevice": "",
                                            }
                                        });
                                    }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                        <span>
                                            <i className="la la-plus" />
                                            <span>Add</span>
                                        </span>
                                    </button>
                                </li>
                            </ul>

                            <ModalModules
                                crrmodules={this.state.crrModulesModal}
                                show={this.state.ModalModules}
                                onHide={this.modalClose}
                            />
                        </div>
                    </div>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="col-xl-12">
                                <div className="m-widget1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                            <thead>
                                                <tr>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>STT</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Type Device</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Code</th>
                                                    <th style={{ 'verticalAlign': 'middle' }}>Version</th>
                                                    <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bulletedEngineVersion}
                                            </tbody>
                                        </table>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                        <span>
                                            Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                            </span>
                                        <Pagination
                                            prevPageText='Previous'
                                            nextPageText='Next'
                                            firstPageText='First'
                                            lastPageText='Last'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Modules;

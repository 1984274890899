import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { HOST } from '../../config';
import swal from 'sweetalert';
import Store from '../../store';
import $ from 'jquery';

class ModalChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirm_password: false,
            crrChangePassword: { user_id: "", new_password: "", confirm_password: "" },
            showNewPass: false,
            showConfirmPass: false,
            isValid: true,
        }
    }

    btnSubmit = (event, dataPW) => {
        var form = $("#form_changepassword")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            if (this.state.crrChangePassword.confirm_password === this.state.crrChangePassword.new_password) {
                fetch(`${HOST}/api/user/admin_update_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Store.getState().isLogin.access_token
                    },
                    body: JSON.stringify(dataPW)
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (data.status === 10000) {
                        swal("Sucess!", "Change Password Sucess!", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                        return this.props.onHide();
                    } else if (data.status === 10001) {
                        swal("Warning!", "Current Password Incorrect!", "warning");
                    } else if(data.status === 10004) {
                        swal("Warning", "Password At least 8 characters, must contain at least one lower-case letter, one upper-case letter", "warning");
                        return;
                    } 
                    else {
                        swal("Error!", "Change Password Failed!", "error");
                    }
                });
            } else {
                swal("Warning!", "Your password and confirmation password do not match", "warning");
            }
        }
    }


    ChangePassword = (event) => {
        var crrChangePassword = this.state.crrChangePassword;
        crrChangePassword[event.target.name] = event.target.value.trim();
        let regPassword =  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}/
        
        if (event.target.name === "new_password") {
            if (regPassword.test(event.target.value.trim())) {
                this.setState({
                    isValid: true,
                });
            } else {
                this.setState({
                    isValid: false,
                });
            }
        }

        this.setState({ crrChangePassword: crrChangePassword });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var crrChangePassword = { user_id: "", new_password: "", confirm_password: "" };
            crrChangePassword.user_id = nextProps.iduser
            this.setState({
                crrChangePassword: crrChangePassword,
            });
        }
    }

    render() {
        let click_handle = (event) => {
            this.btnSubmit(event, this.state.crrChangePassword);
        }
        console.log(this.state.isValid)

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="form_changepassword" className="col-md-12 m-form">
                        <div className="m-portlet__body">
                            <div className="form-group m-form__group">
                                <label>New Password</label>
                                <div className="m-input-icon m-input-icon--left">
                                    <input type={
                                        this.state.showNewPass === false 
                                        ?
                                        'password'
                                        :
                                        'text'
                                    } pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}" className="form-control m-input" minLength="6" id="new_password" name='new_password' onChange={e => this.ChangePassword(e)} placeholder="New Password" required />
                                    <span className="m-input-icon__icon m-input-icon__icon--left">
                                        <span>
                                            <i className="la la-unlock" />
                                        </span>
                                    </span>
                                    <span id="new_password_icon_show" className="m-input-icon__icon m-input-icon__icon--right hover_pointer"
                                        onClick={
                                            () => { 
                                                this.setState({
                                                    showNewPass: !this.state.showNewPass
                                                })
                                            }
                                        }
                                    >
                                        <span>
                                            {
                                                this.state.showNewPass === false
                                                ?
                                                <i className="la la-eye" />
                                                :
                                                <i className="la la-eye-slash" />

                                            }
                                            
                                            
                                        </span>
                                    </span>
                                
                                </div>
                                <span className="m-form__help"><span className="text-danger">*</span> Password At least 8 characters, must contain at least one lower-case letter, one upper-case letter</span>
                                {
                                    this.state.isValid && this.state.crrChangePassword.new_password.length >= 1
                                        ?
                                        <span className="form-control-feedback text-success "> Success, Valid Password.</span>
                                        :
                                        this.state.crrChangePassword.new_password.length >= 1
                                            ?
                                            <span className="form-control-feedback text-danger "> Warning! Invalid Password.</span>
                                            :
                                            ''
                                }


                            </div>
                            <div className="form-group m-form__group">
                                <label>Confirm Password</label>
                                <div className="m-input-icon m-input-icon--left">
                                    <input type={
                                        this.state.showConfirmPass === false
                                        ?
                                        'password'
                                        :
                                        'text'
                                    } pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}" className="form-control m-input" minLength="6" id="confirm_password" name='confirm_password' onChange={e => this.ChangePassword(e)} placeholder="Confirm Password" required />
                                    <span className="m-input-icon__icon m-input-icon__icon--left">
                                        <span>
                                            <i className="la la-unlock" />
                                        </span>
                                    </span>
                                    <span id="confirm_password_icon_show" className="m-input-icon__icon m-input-icon__icon--right hover_pointer"
                                        onClick={
                                            () => {
                                                this.setState({showConfirmPass: !this.state.showConfirmPass})
                                            }
                                        }
                                    >
                                        <span>
                                            {
                                                this.state.showConfirmPass === false
                                                ?
                                                <i className="la la-eye" />
                                                :
                                                <i className="la la-eye-slash" />

                                            }
                                        </span>
                                    </span>
                                   
                                </div>
                                {
                                this.state.crrChangePassword.confirm_password.length >= 1 ? this.state.isValid ? 
                                 (this.state.crrChangePassword.confirm_password !== this.state.crrChangePassword.new_password )
                                ?
                                <div className="form-control-feedback text-danger">Your password and confirmation password do not match!</div> 
                                :
                                <div className="form-control-feedback text-success  ">Success! You can change the password.</div> :  <div className="form-control-feedback text-danger  ">New password invalid.</div> : ""
                                }
                                


                                
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-accent m-loader--light m-loader--right" id="button_submit" onClick={click_handle}>Save</button>
                    <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalChangePassword.propTypes = {
    iduser: PropTypes.number,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalChangePassword;